import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment  } from '../../../environments/environment'
import { reject } from 'q';
import { SettingsService } from '../settings/settings.service';



@Injectable()
export class RestApiProvider {

  private apiUrl = environment.api_ip;

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.settings.cargarAjustes();
  }

    login(username: string, password: string) {
      let data: any = {
        username: username, 
        password: password
      }
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'api-token-auth-cas/', data, {headers: { 'Content-Type': 'application/json' }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          let errores:string[] = [];
          for (let key in err.error) {
            let value = err.error[key][0];
            errores.push(value)
        }
        reject(errores);
        });
      });
    }


    enviar_notificacion(payload: any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'saam/notificaciones', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_url(url:string){
      return new Promise((resolve, reject) => {
        this.http.get(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    check_smtp(){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/has-smtp', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    check_profile(profile_id: number) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'has-users/', {profile_id}, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_mcs() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/mcs', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_usermc() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/usermc', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    create_usermc(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/usermc', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    delete_usermc(id:string){
      return new Promise((resolve, reject) => {
        this.http.delete(this.apiUrl + 'mc/usermc?pk='+id,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    create_mc(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/mcs', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    
    cargar_marcas(){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/marcas', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_catalogo_marca(catalogo:string){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/marca-catalogo/'+ catalogo, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_catalogo_modelo(catalogo:string, marca:string, submarca:string){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/modelo-catalogo/'+ catalogo+'/'+marca+'/'+submarca, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_catalogo_version(catalogo:string, marca:string, submarca:string, modelo:number){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/version-catalogo/'+ catalogo+'/'+marca+'/'+submarca+'/'+modelo, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_catalogo_submarca(catalogo:string, marca:string){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/submarca-catalogo/'+ catalogo+'/'+marca, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    cargar_submarcas(marca:string){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/submarcas/'+marca, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    guardar_auto(payload: any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/guardar-auto', payload,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    guardar_template(payload: any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'templates-masive-emails/', payload,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    obtener_templates(){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'templates-masive-emails',{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_template(id:number){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'templates-masive-emails/'+ id ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    eliminar_template(url:string){
      return new Promise((resolve, reject) => {
        this.http.delete(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    patch_template(url:string, params:any){
      return new Promise((resolve, reject) => {
        this.http.patch(url, params ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    auto_guardado(payload: any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/auto-guardado', payload,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    cargar_modelos(marca:string, submarca:string){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/modelos/'+marca+'/'+ submarca, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    cargar_versiones(marca:string, submarca:string, modelo:number){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/versiones/'+marca+'/'+ submarca+'/'+modelo.toString(), {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    
    guardar_catalogo_homologacion(payload:any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/guardar-catalogo-homologacion', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_mc_info(mcName: string) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/mcs/' + mcName, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_mc_credentials(asegId: number, name: string) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/credentials/' + asegId + '/' + name, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_mc_packages() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/packages', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_coverages_package(packageId: number, asegId: number, schemaName: string) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/coverages/' + packageId + '/' +  asegId + '/' +  schemaName, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    save_credentials(asegId: number, name: string, payload: any) {
      return new Promise((resolve, reject) => {
        this.http.put(this.apiUrl + 'mc/credentials/' + asegId + '/' + name, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    save_general_info(mcName: string, payload: any) {
      return new Promise((resolve, reject) => {
        this.http.put(this.apiUrl + 'mc/mcs/' + mcName, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    save_coverages_package(packageId: number, asegId: number, schemaName: string, payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'mc/coverages/' + packageId + '/' +  asegId + '/' +  schemaName, payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_mc_services() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'mc/services', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_aseguradoras() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'aseguradoras-saam', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    get_aseguradorasperfil() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'aseguradoras-saam-perfil', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    get_perfiles_usuario_restringido() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/perfiles-usuario-restringido', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_perfil_usuario_restringido(param:Number){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/perfil-usuario-restringido/'+param, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_contratantes_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/contratantes', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_grupos_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/grupos', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_celulas_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/celulas', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_sucursales_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/sucursales', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_referenciadores_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/referenciadores', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    } 
    
    get_agrupaciones_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/agrupaciones', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    } 
    
    get_agentes_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/agentes', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_subramos_saam() {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'saam/subramos', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    guardar_perfil_usuario_restringido(payload){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'saam/perfil-usuario-restringido-saam', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    actualizar_perfil_usuario_restringido(payload:any){
      return new Promise((resolve, reject) => {
        this.http.patch(this.apiUrl + 'saam/perfil-usuario-restringido-saam', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    polizas_listado_saam(payload){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'saam/poliza-listado-saam', {'numero_poliza':payload}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    

    get_ramos(aseguradora_id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'ramos-saam/' + aseguradora_id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    activateUser(code: string) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'activate-user/' + code, {
          headers: {
            'Content-Type': 'application/json',
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    forgotPassword(email: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'send-new-password/', email, {
          headers: {
            'Content-Type': 'application/json'
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    resetPassword(password: string, code: string) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'restart-password-user/', {
          password,
          bs64: code
        } , {
          headers: {
            'Content-Type': 'application/json'
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_subramos(aseguradora_id: number, ramo_id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'subramos-saam/' + aseguradora_id + '/' + ramo_id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_claves(aseguradora_id: number, ramo_id: number, subramo_id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'claves-saam/' + aseguradora_id + '/' + ramo_id + '/' + subramo_id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_comisiones(_id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'comisiones-saam/' + _id, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    

    get_claves_general(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'claves-general-saam/', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    crear_clave(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'clave-saam', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    guardar_comision(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'comision-saam', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_organizaciones(page?: number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'organizations/';
        if (page){
          url = this.apiUrl + 'organizations/?page='+page;
        }
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    get_organizaciones_active(page?:number,active?:any) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'organizations/';
        if (page){
          url = this.apiUrl + 'organizations/?page='+page;
          if(active !=null || active !=''){
            url = url+ '&active='+active;
          }
        }else if(active !=null || active !=''){
          url = this.apiUrl + 'organizations/?page='+page+'&active='+active;
        }
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    get_organizaciones_full(){
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'organizations-full/', {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    getDemoRequest(id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'demo-request/' + id , {
          headers: {
            'Content-Type': 'application/json',
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    isOrgDemo(id: number) {
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'is-org-demo/' + id , {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    obtener_aplicaciones() {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'application/', {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });  
    }

    obtener_aplicaciones_org(id:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'organization-application/'+id, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });  
    }

    obtener_perfiles(tipo:string) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'profiles/', {
          params:{
            type_profile:tipo
          },
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });  
    }

    obtener_perfil(id:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'profiles/' + id, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_usuarios(page?:number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'users/';
        if (page){
          url = this.apiUrl + 'users/?page='+page;
        }
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_usuarios_inactivos(page?:number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'users-inactive/';
        if (page){
          url = this.apiUrl + 'users-inactive/?page='+page;
        }
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_usuario_by_name(name:string) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'search-user', {
          params: {'name': name},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_organizacion_by_urlname(urlname:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'individual-organization/' +urlname, {
          headers: {
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getUsersByOrg(org:any,page : number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'users-by-org/';
        if (page){
          url = this.apiUrl + 'users-by-org/?page='+page;
        }
        this.http.get(url, {
          params: {'orgname': org},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          console.log('--data',data)
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getDetailsOrgs(org:any,page : number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'organization-data/';
        this.http.get(url, {
          params: {'activa': org},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getControlOrgs(org:any,page : number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'control-organizations/';
        this.http.get(url, {
          params: {'activa': org},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    generateReport(org:any) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'report-users-by-org/?org=' + org.toString(), {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    generateReportOrgData(org:any,cadena:any) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'report-orgs-data/', {
          params: {'activa': org.toString(),'cadena':''},
          // ?activa=' + org.toString(), {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    reporte_perfiles() {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'reporte-perfiles', {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    reporte_perfiles_restringido() {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'reporte-perfiles-restringidos', {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    get_organizacion(id:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'organizations/' +id, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_organizacion_info(id:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'organization-info/' +id, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    get_usuario(id:number) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'users/' +id, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    

    existe_organizacion(urlname) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'exist-organization/' +urlname, {
          headers: { 
            'Content-Type': 'application/json', 
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    existe_schema_name(urlname) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'mc/exist-schema-name/' +urlname, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    exist_profile_name(profile_name, profile: any) {
      let profile_id = (profile && profile.id) ? profile.id : 0;
      
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'exist-profile-name', {
          'profile_name': profile_name, 
          'profile_id':profile_id
        }, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    send_email_with_data(file:File, email:string, message: any) {
      return new Promise((resolve,reject) => {
        let _file: File = file;
        let formData:FormData = new FormData();
        formData.append('filename', _file, _file.name);
        formData.append('email', email);
        formData.append('message', message);
        let headers = {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token,
          'Content-Disposition': 'attachment; filename=' + _file.name.toString(),
        };
        /** In Angular 5, including the header Content-Type can invalidate your request */
        this.http.post(this.apiUrl+ 'send-massives/', formData, {'headers':headers})
        .subscribe(
          data => resolve(data),
          error => reject(error)
        )
      });
    }
    

    existe_username(username) {
      return new Promise((resolve,reject) => {
        username = username.toLowerCase()
        this.http.get(this.apiUrl + 'exist-username/' + username, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    existe_perfil_restringido(username) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'saam/exist-perfil-restringido', {'nombre':username}, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    send_massives(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'send-massives/', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    send_massives_with_files(payload: any) {
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'send-massives-wo-files/', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    existe_email(email: string, user_id: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'exist-email', {email, user_id}, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    crear_organizacion(org: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'organizations/',org, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    crear_organizacion_demo(org: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'create-org-demo/',org, {
          headers: { 
            'Content-Type': 'application/json', 
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    crear_usuario(user: any) {
      return new Promise((resolve,reject) => {
        user.username = user.username.toLowerCase()
        this.http.post(this.apiUrl + 'users/', user, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    crear_perfil(profile: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'profiles/',profile, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    actualizar_organizacion(url: any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.patch(url, data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    uploadFile(id, payload,orgname){
      return new Promise((resolve,reject) => {
        let token = this.settings.ajustes.token
        this.http.post(this.apiUrl + 'organization/'+ id + '/archivos/',payload, {
        headers: { 
          'Authorization': 'Token ' + token,
          'mimeType': 'multipart/form-data'
        }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    eliminar_org_file(url:string){
      return new Promise((resolve, reject) => {
        this.http.delete(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    parchar_url(url: any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.patch(url, data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    parchar_url_media(url: any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.patch(url, data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    actualizar_usuario(url: any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.patch(url, data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    obtener_smtp_config() {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'smtp/',  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    obtener_smtp_organizacional_config(org: any) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'smtp-organizational/?org='+org,  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    update_smtp_config(data: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'smtp/', data, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    update_smtp_organizacional_config(org:any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'smtp-organizational/?org='+org,data,  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    getUsersApp(data:any){
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'users-app-created/?org=' + data.orgname + '&identifier=' + data.identifier + '&since=' + data.since + '&until=' + data.until +  '&page=' + data.page, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    getUsersAppByOrg(org:any,page : number) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'users-app-created/';
        if (page){
          url = this.apiUrl + 'users-app-created/?page='+page;
        }
        this.http.get(url, {
          params: {'orgname': org},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    
    generateReportApp(data:any){
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'report-users-app/', data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    saveUserApp(form:any){
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'create_user_app_cas/', form,  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    
    updateUserApp(form:any){
      return new Promise((resolve,reject) => {
        this.http.patch(this.apiUrl + 'create_user_app_cas/', form,  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    
    saveUserAppExcel(files:any){
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'cas-create-users-app/', files,  {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    // control-recibos

    get_organizacion_by_name_rason(cadena:any) {
      return new Promise((resolve,reject) => {
        this.http.get(this.apiUrl + 'control-organization-buscador/',{  
          params: {'cadena': cadena},
          headers: {
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    crear_recibos_organizacion(rec: any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'recibos/', rec, {
          headers: { 
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    recibo_patch(url:string, params:any){
      return new Promise((resolve, reject) => {
        this.http.patch(url, params ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    cargar_pagos(file: any) {
      const formData = new FormData();
      formData.append('archivo', file);  
      formData.append('accion', 'validar');  
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'recibos_layout/', formData, {
          headers: {
            Authorization: 'Token ' + this.settings.ajustes.token
          }
        }).subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
      });
    }
    aplicar_pagos(file: any) {
      const formData = new FormData();
      formData.append('archivo', file);  
      formData.append('accion', 'aplicar');  
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'recibos_layout/', formData, {
          headers: {
            Authorization: 'Token ' + this.settings.ajustes.token
          }
        }).subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
      });
    }
    
    getDetailsReceipts() {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-todos/';
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }

    getPaginationListReceipts(urlo:any,page : string) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + urlo;
        this.http.get(url, {
          params: {'page':page},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getStadisticsReceipts() {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-stadistic/';
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getReceiptsAvencer() {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-avencer/';
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getReceiptsPagados() {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-pagados/';
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getReceiptsVencidos() {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-vencidos/';
        this.http.get(url, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getReceiptsOrg(id:any) {
      return new Promise((resolve,reject) => {
        let url = this.apiUrl + 'recibos-org/';
        this.http.get(url, {  
          params: {'orgname': id},
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          // reject(err);
        });
      });
    }

    eliminar_org_recibosfiles(url:string){
      return new Promise((resolve, reject) => {
        this.http.delete(url, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    update_receipts_subsecuentes(data:any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'recibos-upd-subsecuentes/', data ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    
    getRecibosEstadistica(data:any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'filters-recibosDash/', data ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getPaginationFilter(data:any){
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'filters-recibosDash/', data ,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    generateReportDashReceipts(data:any) {
      return new Promise((resolve,reject) => {
        this.http.post(this.apiUrl + 'report-receiptsdash/',data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          },
          responseType: 'blob'
        })
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    getOrgsToRenovateReceipts(data:any){
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'recibos-a-renovar/',{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    delete_receipt_org(id:string){
      return new Promise((resolve, reject) => {
        this.http.delete(this.apiUrl + 'recibos/'+id,{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }


    uploadFileReceipt(id, payload,orgname){
      return new Promise((resolve,reject) => {
        let token = this.settings.ajustes.token
        this.http.post(this.apiUrl + 'recibos/'+ id + '/archivos/',payload, {
        headers: { 
          'Authorization': 'Token ' + token,
          'mimeType': 'multipart/form-data'
        }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    saveImages(payload:any,file:any){      
      const formData = new FormData();
      formData.append('imagen', file);  
      formData.append('description', payload['description']);  
      formData.append('visible', payload['visible']);  
      return new Promise((resolve, reject) => {
        this.http.post(this.apiUrl + 'carousel-general/', payload, {
          headers: {
            Authorization: 'Token ' + this.settings.ajustes.token
          }
        }).subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
      });
    }
    getCarousel(){ 
      return new Promise((resolve, reject) => {
        this.http.get(this.apiUrl + 'carousel-general/',{
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
      
    }
    updateImages(url: any, data: any) {
      return new Promise((resolve,reject) => {
        this.http.patch(url, data, {
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: 'Token ' + this.settings.ajustes.token
          }})
        .subscribe(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
      });
    }
    // -------------------
}
