import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import * as XLSX from 'xlsx';
type AOA = any[][];

@Component({
  selector: 'app-homologacion',
  templateUrl: './homologacion.component.html',
  styleUrls: ['./homologacion.component.css']
})
export class HomologacionComponent implements OnInit {
  data:any;
  aux_array:any;
  marcas:any = [];
  submarcas:any = [];
  modelos:any = [];
  versiones:any = [];
  form: FormGroup;
  catalogos:any = [];
  constructor(
    public dialog: MatDialog,
    private api_rest : RestApiProvider,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) { 
    this.form = new FormGroup({
      marca: new FormControl(null),
      submarca: new FormControl(null),
      modelo: new FormControl(null),
      version: new FormControl(null)
    })
  }

  ngOnInit() {
  }

  groupByKey(array, key) {
    return array
      .reduce((hash, obj) => {
        if(obj[key] === undefined) return hash; 
        return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
      }, {})
 }
  
  onFileChange(evt: any){
    this.aux_array = [];
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      let headers = this.data[0];
      headers = headers.map(h => h.toLowerCase().split(' ').join('_').split('-').join('_').split('/').join('_'))
     
      this.data.splice(0,1);
      
      
      
      const dialogRef = this.dialog.open(LoadDataSourceDialogComponent, {
         disableClose: true,
         data: {
           data : headers 
         }
       });

       dialogRef.afterClosed().subscribe(result => {   
                
         result = result['headers'];
         let items = result.filter(item => item['checked'] == true)
         let indices = []
         
         items.forEach(item =>{
             indices.push(headers.indexOf(item['name'])); 
          });

          this.data = this.data.map(asegurado => {
           let obj = {};
           for(let indice of indices){
             if (!this.aux_array.includes(headers[indice])){
               this.aux_array.push(headers[indice]);
             }
  
            obj[headers[indice]] = asegurado[indice];
            }
           return obj;
          });  
          this.spinner.show();
          this.api_rest.guardar_catalogo_homologacion(this.data).then(response=>{
            console.log(response);
            this.spinner.hide();
            this.toaster.success('Catálogo cargado exitosamente');
          }, error => {
            this.spinner.hide();
          })
          .catch(()=> {
            this.spinner.hide();
          });
          
          // this.marcas =  Object.values(this.data.reduce((c, v) => {
          //   c[v.marca_aba] = c[v.marca_aba] || {marca_aba: v.marca_aba,submarca_aba: new Set()};
          //   c[v.marca_aba].submarca_aba.add(v.submarca_aba);
          //   return c;
          // }, {})).map(o => {
          //   o['submarca_aba'] = [...o['submarca_aba']];
          //   return o;
          // });
       });
    };
    reader.readAsBinaryString(target.files[0]);
 }

 changeMarca(){
   this.api_rest.cargar_submarcas(this.form.get('marca').value).then(response=> {
    this.submarcas = JSON.parse(response.toString());
   });
  this.modelos = [];
  this.versiones = [];
 }

 changeSubMarca(catalogo:string, marca:string, submarca:string, index:number){
    this.api_rest.get_catalogo_modelo(catalogo, marca, submarca).then(response => {
      this.catalogos[index]['modelos'] = JSON.parse(response.toString());
      // this.catalogos[index]['modelo'] = '';
      this.catalogos[index]['versiones'] = [];
      // this.catalogos[index]['version'] = '';
      
    });
  }


  changeSubMarcaMain(){
    this.api_rest.cargar_modelos(this.form.get('marca').value, this.form.get('submarca').value).then(response => {
      this.modelos = JSON.parse(response.toString());
      this.form.get('modelo').setValue(null);
      this.versiones = [];
      this.form.get('version').setValue(null);
    });
  }

  obtener_auto_guardado(){
    this.catalogos.forEach((catalogo,index) => {
      let payload = {
        base: this.form.value,
        catalogo: catalogo['name']
      }
      this.api_rest.auto_guardado(payload).then(response => {
        this.catalogos[index]['catalogo_guardado'] = JSON.parse(response.toString());
        try{
          this.catalogos[index]['marca'] = this.catalogos[index]['catalogo_guardado']['marca'];
          this.changeMarcaCatalog(this.catalogos[index]['catalogo_guardado']['name'],this.catalogos[index]['marca'], index)
          this.catalogos[index]['submarca'] = this.catalogos[index]['catalogo_guardado']['submarca'];
          this.changeSubMarca(this.catalogos[index]['catalogo_guardado']['name'],this.catalogos[index]['marca'], this.catalogos[index]['submarca'], index)
          this.catalogos[index]['modelo'] = this.catalogos[index]['catalogo_guardado']['modelo'];
          this.changeModelo(this.catalogos[index]['catalogo_guardado']['name'],this.catalogos[index]['marca'], this.catalogos[index]['submarca'], this.catalogos[index]['modelo'], index)
          this.catalogos[index]['version'] = this.catalogos[index]['catalogo_guardado']['version'];
        }
        catch(err){}
      }, error => {
        this.catalogos[index]['marca'] = '';
        this.catalogos[index]['submarca'] = '';
        this.catalogos[index]['modelo'] = '';
        this.catalogos[index]['version'] = '';
        this.catalogos[index]['submarcas'] = [];
        this.catalogos[index]['modelos'] = [];
        this.catalogos[index]['versiones'] = [];
      })
      .catch(err => {
        this.catalogos[index]['marca'] = '';
        this.catalogos[index]['submarca'] = '';
        this.catalogos[index]['modelo'] = '';
        this.catalogos[index]['version'] = '';
        this.catalogos[index]['submarcas'] = [];
        this.catalogos[index]['modelos'] = [];
        this.catalogos[index]['versiones'] = [];
      } );
    });
  }

  save(){
    let new_catalogos = this.catalogos.map(catalogo => {
      return {
        marca : catalogo.marca,
        modelo : catalogo.modelo,
        name : catalogo.name,
        submarca : catalogo.submarca,
        version : catalogo.version
      }
    });
    let payload = {
      base: this.form.value,
      autos: new_catalogos
    }
    this.api_rest.guardar_auto(payload).then(response => {
      this.toaster.success('Auto guardado');
    });
  }

  changeModeloMain(){
    this.api_rest.cargar_versiones(this.form.get('marca').value, this.form.get('submarca').value, this.form.get('modelo').value).then(response => {
      this.versiones = JSON.parse(response.toString());
      this.form.get('version').setValue(null);
      
    });
  }

  

  changeModelo(catalogo:string, marca:string, submarca:string, modelo:number, index:number){
    if (catalogo == 'mapfre'){
      submarca = '0';
    }
    this.api_rest.get_catalogo_version(catalogo, marca, submarca, modelo).then(response => {
      console.log(JSON.parse(response.toString()));
      this.catalogos[index]['versiones'] = JSON.parse(response.toString());
      // this.catalogos[index]['version'] = '';
    });
  }

  loadCatalogs(){
    this.api_rest.cargar_marcas().then(response => {
      this.marcas = JSON.parse(response.toString());
    });
    const dialogRef = this.dialog.open(LoadCatalogsDialogComponent, {
      disableClose: true,
      data: {}
    });


    dialogRef.afterClosed().subscribe(result => {
      this.catalogos = [];
      for (let key in result) {
        let value = result[key];
        let obj = {}
        obj[key] = value
        obj['name'] = key;
        obj['version'] = '';
        obj['marcas'] = [];
        obj['submarcas'] = [];
        obj['modelos'] = [];
        obj['versiones'] = [];
        obj['marca'] = '';
        obj['submarca'] = '';
        obj['modelo'] = '';
        obj['version'] = '';
        obj['logo'] = '../assets/images/aseguradoras/' +key.toString() + '.png'; 
        if (value) {this.catalogos.push(obj)}
      }
      this.catalogos.forEach((catalogo, index) => {
        this.api_rest.get_catalogo_marca(catalogo['name']).then(response => {
          this.catalogos[index]['marcas'] = JSON.parse(response.toString());
        });
      });
    });
  }

  changeMarcaCatalog(catalogo:string, marca:string, index:number){
    this.api_rest.get_catalogo_submarca(catalogo, marca).then(response => {
      if (catalogo == 'mapfre'){
        this.catalogos[index]['modelos'] = JSON.parse(response.toString());
      } else {
        this.catalogos[index]['submarcas'] = JSON.parse(response.toString());
        // this.catalogos[index]['submarca'] = '';
        this.catalogos[index]['modelos'] = [];
        this.catalogos[index]['versiones'] = [];
        // this.catalogos[index]['modelo'] = '';
        // this.catalogos[index]['version'] = '';
      }
    });
  }


}

export interface DialogData {
  data:any;
}



@Component({
  selector: 'loadDataSource',
  templateUrl: 'select_massive_dialog_data.html',
})
export class LoadDataSourceDialogComponent {
  form:FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<LoadDataSourceDialogComponent>,
    ) {
      this.form = new FormGroup({});
      let auxArray = new FormArray([]);
      this.data.data.forEach(header => {
        
        let flag = true;
        let control = new FormGroup({
          name: new FormControl(header),
          checked: new FormControl(flag)
        });
        auxArray.push(control);
      });
      this.form.addControl('headers',auxArray);
    }


    cancel(){
      this.dialogRef.close();
    }

    save(){
      this.dialogRef.close(this.form.value);
    }

    
  }


  @Component({
    selector: 'loadDataCatalogs',
    templateUrl: 'load_catalogs_dialog_data.html',
  })
  export class LoadCatalogsDialogComponent {
    form:FormGroup;
    constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      public dialogRef: MatDialogRef<LoadCatalogsDialogComponent>,
      ) {
        this.form = new FormGroup({
          qualitas : new FormControl(true),
          afirme : new FormControl(true),
          aig : new FormControl(true),
          axa : new FormControl(true),
          gnp : new FormControl(true),
          ana : new FormControl(true),
          hdi : new FormControl(true),
          ps : new FormControl(true),
          banorte : new FormControl(true),
          atlas : new FormControl(true),
          potosi : new FormControl(true),
          mapfre : new FormControl(true),
          zurich : new FormControl(true)
        });
      }

      reverseCheck(){
        Object.values(this.form.controls).forEach(control => {
          if (control.value){
            control.setValue(false);
          } else {
            control.setValue(true);
          }
        });
      }
  
  
      cancel(){
        this.dialogRef.close();
      }
  
      save(){
        this.dialogRef.close(this.form.value);
      }
  
      
    }
  