import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { SettingsService } from 'src/app/services/service.index';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ToastRef, ToastrService } from 'ngx-toastr';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import Swal from 'sweetalert2';

const rolex = {
  Admin: 0,
  SubAdmin: 1,
  Agente: 2,
  Vendedor: 3,
  Usuario: 4 
};

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.css']
})
export class UsersDetailComponent implements OnInit {
  title = 'Crear';
  form: FormGroup;
  user: any = null;
  perfilRestringido = '';
  // tslint:disable-next-line: variable-name
  user_url: string;
  // tslint:disable-next-line: variable-name
  user_info: any = {};
  roles: any = [];
  profiles: any = [];
  usuario_restringido_name:string = '';
  tipos_usuario:any[] = [{
    id:0,
    desc:"Sin acceso"
  },{
    id:1,
    desc:"Operativo"
  },{
    id:2,
    desc:"Consulta"
  },{
    id:3,
    desc:"Superusuario"
  },{
    id:4,
    desc:"Consulta Restringida"
  }
  ]
  constructor(
    // tslint:disable-next-line: variable-name
    private _router: Router,
    // tslint:disable-next-line: variable-name
    private _validators: ValidadoresService,
    // tslint:disable-next-line: variable-name
    private rest_api: RestApiProvider,
    // tslint:disable-next-line: variable-name
    private _route: ActivatedRoute,
    // tslint:disable-next-line: variable-name
    public _settings: SettingsService,
    public dialog: MatDialog,
    // tslint:disable-next-line: variable-name
    private _toaster: ToastrService,
  ) {
    this.form = new FormGroup({
      first_name: new FormControl(null, Validators.required),
      last_name: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      password_confirm: new FormControl(null),
      username: new FormControl(
          null,
          [
              Validators.required,
              Validators.minLength(5),
              this._validators.noWhitespaceValidator
          ],
          this._validators.existeUsername.bind(this)
        ),
      email: new FormControl(
          null,
          [
              Validators.required,
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
          ],
          this._validators.existeEmail.bind(this)
        ),
      rol: new FormControl(null, [this._validators.noSelectedOptionIncluded0]),
      profile: new FormControl(-1, [this._validators.noSelectedOption]),
      is_staff: new FormControl(0),
      is_superuser: new FormControl(0),
      is_active : new FormControl(false),
      userinfo : new FormGroup({
        rfc: new FormControl(null, Validators.required),
        birthdate: new FormControl(null, Validators.required),
        phone: new FormControl(null, [Validators.required]),
        //phone: new FormControl(null, [Validators.required, this._validators.noDiezDigitosPhone]),
        gender: new FormControl(0, Validators.required),
        role: new FormControl(0),
      })
    }, {
      validators: this._validators.passwordsIguales('password', 'password_confirm').bind(this.form)
    });

    if (this._settings.amISuperInOrg()){
      (this.form.get('userinfo') as FormGroup).addControl('another_tasks',new FormControl(false));
      (this.form.get('userinfo') as FormGroup).addControl('manage_profile',new FormControl(0));
    }

    this._settings.cargarAjustes();
    console.log(this._settings)
    if (this._settings.amISuperOutOrg()){
      this.form.get('is_superuser').setValue(true);
    }

    if (!this._settings.ajustes.org  || !this._settings.ajustes.org.id) {
      // console.log('disabled');
      
      this.form.get('userinfo').disable();
      this.form.get('profile').disable();
    }
    this._route.params.subscribe( params => {
      if (params['id'] !== 'nuevo') {
        this.user = params['id'];
        this.title = 'Editar';
        this.rest_api.get_usuario(this.user).then(response => {
          this.perfilRestringido = response['perfilRestringido'];
          

          this.rest_api.get_perfiles_usuario_restringido().then(response => {
            (response as Array<any>).forEach(rp => {
              console.log(this.perfilRestringido);
              
              if (rp['id'] == this.perfilRestringido){
                this.usuario_restringido_name = '( ' + rp['nombre'] + ' )';
              }
            });
          });


          this.user_url = response['url'];
          this.form.get('username').clearAsyncValidators();
          this.form.get('username').clearValidators();
          this.form.get('password').clearValidators();
          this.form.get('username').disable();
          this.form.get('password').updateValueAndValidity();
          this.form.get('username').updateValueAndValidity();

          if (response && response['userinfo']) {
            this.user_info = response['userinfo'];
            response['role'] = rolex[this.user_info['role']];
            (this.form.get('userinfo') as FormGroup).reset(this.user_info);
            // if (this.user_info['manage_profile'] != 2){
            if (this._settings.ajustes.org  && this._settings.ajustes.org.id) {
              this.rest_api.obtener_perfiles(this.user_info['manage_profile']).then(response => {
                this.profiles = response;
              });
            }
            // }
            // this.changeTipo()
          } else {
            delete response['userinfo'];
          }
          this.form.reset(response);
          this.form.get('rol').setValue(this.user_info['role']);
          try {
            this.form.get('profile').setValue(this.user_info.user_profiles[0].id);
          } catch (err) {
            this.form.get('profile').setValue(0);
          }
          /*if (response['is_superuser']) { this.form.get('rol').setValue('is_superuser'); }
          if (response['is_staff']) { this.form.get('rol').setValue('is_staff'); }*/
          // tslint:disable-next-line: max-line-length
          if (this._settings.amIUser() && !this._settings.amIRoleAdmin() && !this._settings.amIRoleSubAdmin() && response['id'] !== this._settings.getId()) {
            this.form.disable();
          }
          if (this._settings.amIUser() && !this._settings.amIRoleAdmin() && !this._settings.amIRoleSubAdmin()) {
            this.form.get('profile').disable();
          }
          console.log(this.form.value);
          
        });
      } else {
        if (this._settings.ajustes.org  && this._settings.ajustes.org.id) {
          this.rest_api.obtener_perfiles('0').then(response => {
            this.profiles = response;
          });
        }
      }
    });

    this.roles = [
      {id: '2', desc : 'Agente'},
      {id: '3', desc : 'Vendedor'},
      {id: '4', desc : 'Usuario'},
    ];
    if (this._settings.amIStaff || this._settings.amISuperOutOrg || this._settings.amIRoleSubAdmin || this._settings.amIRoleAdmin) {
      this.roles.push({id: '1', desc : 'SubAdmin'});
    }
    if (this._settings.amISuperOutOrg || this._settings.amIRoleAdmin) {
      this.roles.push({id: '0', desc : 'Admin' });
    }
    console.log(this.roles);
    
  }

  changeTipo(){
    if (this._settings.ajustes.org  && this._settings.ajustes.org.id && (this.form.get('userinfo') as FormGroup).get('manage_profile')) {
      this.rest_api.obtener_perfiles((this.form.get('userinfo') as FormGroup).get('manage_profile').value).then(response => {
        this.profiles = response;
      });
    }
  }

  ngOnInit() {
    // if (this._settings.ajustes.org  && this._settings.ajustes.org.id) {
    //   this.rest_api.obtener_perfiles((this.form.get('userinfo') as FormGroup).get('manage_profile').value).then(response => {
    //     this.profiles = response;
    //   });
    // }
  }
  toggleCheckboxSuper(event) {
    if (!event.target.checked) {
        // Si deshabilitan el checkbox, lo volvemos a habilitar
        event.target.checked = true;
    }
  }
  cancel() {
    this._router.navigate(['/usuarios']);
  }

  get first_nameNoValido() {
    return this.form.get('first_name').invalid && this.form.get('first_name').touched;
  }
  get last_nameNoValido() {
    return this.form.get('last_name').invalid && this.form.get('last_name').touched;
  }
  get passwordNoValido() {
    return this.form.get('password').invalid && this.form.get('password').touched;
  }
  get password_confirmNoValido() {
    const pass1 = this.form.get('password').value;
    const pass2 = this.form.get('password_confirm').value;
    return (pass1 === pass2) ? false : true;
  }
  get usernameNoValido() {
    return this.form.get('username').invalid &&
    this.form.get('username').touched &&
    !this.form.get('username').hasError('existe') &&
    !this.form.get('username').hasError('whitespace');
  }
  get phoneNoValido() {
    return (this.form.get('userinfo') as FormGroup).get('phone').invalid && (this.form.get('userinfo') as FormGroup).get('phone').touched;
  }
  get birthdateNoValido() {
    let invalidob = false
    // tslint:disable-next-line: max-line-length
    // console.log('this.form.get(',(this.form.get('userinfo') as FormGroup).get('birthdate'))
    try{
      let cadena =(this.form.get('userinfo') as FormGroup).get('birthdate').value
      if((cadena.substr(0,2) =='00' || cadena.substr(0,1) ==0) || cadena.length >10){
        // console.log('XXXX',cadena.substr(0,2))
        let invalidob = true
        if(invalidob){
          return true
        }else{
          return (this.form.get('userinfo') as FormGroup).get('birthdate').invalid && (this.form.get('userinfo') as FormGroup).get('birthdate').touched;
        }
      }
    }catch(y){}
    // console.log((this.form.get('userinfo') as FormGroup).get('birthdate').invalid,(this.form.get('userinfo') as FormGroup).get('birthdate').touched,invalidob)
  }
  get rfcNoValido() {
    return (this.form.get('userinfo') as FormGroup).get('rfc').invalid && (this.form.get('userinfo') as FormGroup).get('rfc').touched;
  }

  get genderNoValido() {
    return (this.form.get('userinfo') as FormGroup).get('gender').invalid && (this.form.get('userinfo') as FormGroup).get('gender').touched;
  }

  get TipoNoValido() {
    return (this.form.get('userinfo') as FormGroup).get('manage_profile').invalid && (this.form.get('userinfo') as FormGroup).get('manage_profile').touched;
  }

  get emailNoValido() {
    return this.form.get('email').invalid && this.form.get('email');
  }

  get profileNoValido() {
    return this.form.get('profile').invalid && this.form.get('profile').touched;
  }

  get roleNoValido() {
    return this.form.get('rol').invalid && this.form.get('rol').touched;
  }

  save() {
    const isSuperuser = this._settings.ajustes.user.is_superuser;

    try{    
      (this.form.get('username').value).toLowerCase()
    }catch(e){}
    if (this.form.get('profile').value <= 0 && !isSuperuser) {
      this._toaster.error('Seleccione un perfil');
      return;  
    }

 
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      return;
    }
    if (!this._settings.ajustes.org  || !this._settings.ajustes.org.id) {
      this.form.disable();
      this.rest_api.actualizar_usuario(this.user_url, this.form.value).then(response => {
        this._router.navigate(['/usuarios']);
        this.form.enable();
        this._toaster.success('Usuario editado existosamente');
      }, error => {
        this.form.enable();
      }).catch(error => {
        this.form.enable();
      });
    }
    // this.form.get('rol').value === 'is_superuser' ? this.form.value['is_superuser'] = true : this.form.value['is_superuser'] = false; 
    // this.form.get('rol').value === 'is_staff' ? this.form.value['is_staff'] = true : this.form.value['is_staff'] = false; 
    this.form.value['perfilRestringido'] = this.perfilRestringido;
    let notificarContabilidad = true;

    if(this.form.value['is_active'] && this.form.value['userinfo']['manage_profile'] && this.form.value['userinfo']['manage_profile'] != 0  && this.form.value['userinfo']['manage_profile'] != 3){
      Swal.fire({
        title: '¿Desea notificar al área contable?',
        text:'El usuario reune los requisitos para notificar al área contable',
        showCancelButton: true,
        confirmButtonText: 'Si, notificar',
        cancelButtonText: 'No notificar'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          notificarContabilidad = true;
        } else {
          notificarContabilidad = false;
        }

        this.form.value['userinfo']['notificarContabilidad'] = notificarContabilidad;
        if (this.user) {
          this.form.value['id'] = this.user;
    
          let userinfo = this.form.value['userinfo'];
          let payload = this.form.value;
    
          userinfo['id'] = this.user_info['id'];
          userinfo['notificarContabilidad'] = notificarContabilidad;
          payload['userinfo'] = userinfo;

          this.form.disable();
          this.rest_api.actualizar_usuario(this.user_url, payload).then(response => {
            this._settings.ajustes.user.is_staff = response['is_staff'];
            this._settings.guardarAjustes();
            this._router.navigate(['/usuarios']);
            this._toaster.success('Usuario editado existosamente');
            this.form.enable();
          }, error => {
            this.form.enable();
          }).catch(error => {
            this.form.enable();
          });
        } else {
          this.form.disable();
          this.rest_api.crear_usuario(this.form.value).then(response => {
            this.form.enable();
            this._router.navigate(['/usuarios']);
            this._toaster.success('Usuario creado existosamente');
          }, error => {
            this.form.enable();
            try {
              this._toaster.error(error.error.detail);
            } catch (err) {}
            console.log('err', error);
          })
          .catch(errr => {
            this.form.enable();
            try {
              this._toaster.error(errr.error.detail);
            } catch (err) {}
            console.log('errr', errr);
          });
        }
      })
    } else {
      if (this.user) {
        this.form.value['id'] = this.user;
  
        let userinfo = this.form.value['userinfo'];
        let payload = this.form.value;
  
        userinfo['id'] = this.user_info['id'];
        userinfo['notificarContabilidad'] = notificarContabilidad;
        payload['userinfo'] = userinfo;

        this.form.disable();
        this.rest_api.actualizar_usuario(this.user_url, payload).then(response => {
          this._settings.ajustes.user.is_staff = response['is_staff'];
          this._settings.guardarAjustes();
          this._router.navigate(['/usuarios']);
          this._toaster.success('Usuario editado existosamente');
          this.form.enable();
        }, error => {
          this.form.enable();
        }).catch(error => {
          this.form.enable();
        });
      } else {
        this.form.disable();
        
        this.rest_api.crear_usuario(this.form.value).then(response => {
          this.form.enable();
          this._router.navigate(['/usuarios']);
          this._toaster.success('Usuario creado existosamente');
        }, error => {
          this.form.enable();
          try {
            this._toaster.error(error.error.detail);
          } catch (err) {}
          console.log('err', error);
        })
        .catch(errr => {
          this.form.enable();
          try {
            this._toaster.error(errr.error.detail);
          } catch (err) {}
          console.log('errr', errr);
        });
      }
    }


    
  }

  AddProfile() {
    const dialogRef = this.dialog.open(AddProfileDialogComponent, {
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result['data'] && (this.form.get('userinfo') as FormGroup).get('manage_profile') && (this.form.get('userinfo') as FormGroup).get('manage_profile').value != 2) {
        this.profiles.push(result['data']);
        this.form.get('profile').setValue(result['data'].id);
      } else {
        this.changeTipo();
      }
    });
  }

  seePermissions() {
    let models: any = [];
    this.profiles.some(profile => {
      if (profile.id === this.form.get('profile').value) {
        models = profile.profile_permissions;

        this.dialog.open(UserPermissionsDialogComponent, {
          data: {
            models,
            perfil: profile.name
          }
        });
      }
    });
  }

  openModalRestringido() {
    // if (this.form.get('is_staff').value) {
    //   this.form.get('is_staff').setValue(false);
    // }
    let dialogRef = null;
    this.rest_api.get_perfiles_usuario_restringido().then(response => {
      this.form.get('is_staff').setValue(true);
      dialogRef = this.dialog.open(PerfilesRestringidosDialogComponent, {
        data: { perfiles: response, perfilRestringido: this.perfilRestringido} 
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        if (result === 'cancel2') {
          this.form.get('is_staff').setValue(false);
          return;
        } else if (result === 'cancel1') {

        } else if (result === '0') {
          this.form.get('is_staff').setValue(false);
          this.perfilRestringido = '0';
        } else {
          this.perfilRestringido = result;
        }
      });
    });
  }

}

export interface DialogData {
  models: any;
  perfil: string;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'users-permissions-dialog',
  templateUrl: 'users.permissions.dialog.component.html',
})
export class UserPermissionsDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<AddProfileDialogComponent>, ) {}
  cancel() {
    this.dialogRef.close();
  }
}


@Component({
  // tslint:disable-next-line: component-selector
  selector: 'assign-restricted-profile',
  templateUrl: 'assing_restricted_profile.html',
})
export class PerfilesRestringidosDialogComponent {
  form: FormGroup;
  perfiles: any = {};
  constructor(@Inject(MAT_DIALOG_DATA) public data,
              public dialogRef: MatDialogRef<PerfilesRestringidosDialogComponent>, ) {
                this.form = new FormGroup({
                  perfil: new FormControl(0, [Validators.required]),
                });
                
                this.perfiles = this.data['perfiles'];
                this.form.get('perfil').setValue(this.data['perfilRestringido']);
                this.perfiles.unshift({nombre: 'Seleccione un perfil', id: 0});
              }
  cancel() {
    this.dialogRef.close('cancel1');
  }
  get applicationNoValido() {
    return this.form.get('application').invalid && this.form.get('application').touched;
  }

  save() {
    console.log(this.form.value);
    
    if (this.form.get('perfil').value === 0) {
      this.dialogRef.close('cancel2');
      return;
    } else {
      this.dialogRef.close(this.form.get('perfil').value);
    }

  }

}



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'add-new-profile',
  templateUrl: 'add.profile.dialog.component.html',
})
export class AddProfileDialogComponent {
  aplications: any;
  app: any;
  form: FormGroup;
  inactive_permissions = [
    'Campañas',
    'Conciliar recibos',
    'Despagar recibos',
    'Administrar notificaciones',
    'Eliminar recibos',
    'Cambiar refrenciador en pólizas',
    'Pagar y prorrogar',
    'Registrar endosos',
    'Administrar OTs',
    'Desconciliación de recibos',
    'Desliquidar recibos',
    'Liquidar recibos',
    'Eliminar OTs',
    'Administrar pólizas',
    'Administrar referenciadores',
    'Eliminar pólizas',
    'Rehabilitar pólizas',
    'Administrar contratantes y grupos',
    'Eliminar endosos',
    'Eliminar fianzas',
    'Pagar a referenciadores',
    'Cancelar OTs',
    'Administrar fianzas',
    'Cancelar pólizas',
    'Eliminar grupos',
    'Referenciador no obligatorio'
  ]
  
  types = [
    {'id': 0, 'name': 'Seleccione una opcion'},
    {'id': 1, 'name': 'Operativo'},
    {'id': 2, 'name': 'Consulta'}
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddProfileDialogComponent>,
    // tslint:disable-next-line: variable-name
    private _validators: ValidadoresService,
    // tslint:disable-next-line: variable-name
    private rest_api: RestApiProvider,
    // tslint:disable-next-line: variable-name
    private _settings: SettingsService,
    // tslint:disable-next-line: variable-name
    private _toastr: ToastrService,
    // tslint:disable-next-line: variable-name
    private _router: Router
    ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required], this._validators.existeProfilename.bind(this)),
      application: new FormControl(0, [Validators.required, this._validators.noSelectedOption]),
      type_profile: new FormControl(0, [Validators.required, this._validators.noSelectedOption]),
      permissions: new FormArray([])
    });

    this.rest_api.obtener_aplicaciones().then(response => {
      this.aplications = response;
      this.aplications.unshift({url: 0, name: 'Seleccione una opcion'});
    });
    this._settings.cargarAjustes();
  }

  changeApp(item: any) {
    // cuando cambiamos de aplicacion creamos los controles dinamicos con los permisos de la aplicacion
    // (this.form.controls.permissions as FormArray) = new FormArray([]);
    this.aplications.some(app => {
      if (app.url === item.target.value) {
        this.app = app.application_models;
        if (this.app.length > 0){
          this.app.forEach(model => {
            const auxArray = new FormArray([]);
            model.permissions.forEach(permission => {
              auxArray.push(new FormGroup({
                    name: new FormControl(permission.name),
                    id: new FormControl(permission.id),
                    checked: new FormControl(true)
                })
              )
            });
            (this.form.controls.permissions as FormArray).push(auxArray);
          });
        } else {
          // (this.form.controls.permissions as FormArray) = new FormArray([]);
          this.form.removeControl('permissions');
          this.form.addControl('permissions',  new FormArray([]));
        }
      } else {
        this.app = {};
      }
    });
  }

  changeType(item:any){
    if (item.target.value == 2) {
      this.form.get('permissions')['controls'].forEach((permision) => {
        permision.controls.forEach(p => {
          if ( this.inactive_permissions.includes(p.value['name']) ){
            p.controls['checked'].setValue(false);
            (p.controls['checked'] as FormControl).disable();
          }
        })
      }); 
    } else {
      this.form.get('permissions')['controls'].forEach((permision) => {
        permision.controls.forEach(p => {
          p.controls['checked'].setValue(true);
            (p.controls['checked'] as FormControl).enable();
        })
      }); 
    }
  }

  get nameNoValido() {
    return this.form.get('name').invalid && this.form.get('name').touched && !this.form.get('name').hasError('exists');
  }

  get applicationNoValido() {
    return this.form.get('application').invalid && this.form.get('application').touched;
  }

  cancel() {
    this.dialogRef.close();
  }
  
  save() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      return;
    }

    if (this._settings.ajustes.org && this._settings.ajustes.org.id) {
      this.form.value['org'] = this._settings.ajustes.org.id;
    } else {
      this._toastr.error('Los perfiles solo pueden crearse desde una organizacion!', 'Error');
      this._router.navigate(['/organizaciones']);
      return;
    }

    this.form.get('permissions')['controls'].forEach((permision) => {
      permision.controls.forEach(p => {
        (p.controls['checked'] as FormControl).enable();
      })
    }); 
    
    this.rest_api.crear_perfil(this.form.value).then(response => {
      this.dialogRef.close({ data: response });
      this._toastr.success('Perfil creado exitosamente');
    }, error => {
      console.error(error);
    })
    .catch(err => {
      console.error(err);
    });
  }


}