import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-clave-detail',
  templateUrl: './clave-detail.component.html',
})
export class ClaveDetailComponent implements OnInit {
  form: FormGroup
  comisiones:any;
  comision:any;
  comision_seleccionada:number = 0;
  clave_id :number;
  aseg_id :number;
  ramos: any;
  subramos: any;
  title: any;

  constructor(
    private router: Router,
    private rest_api: RestApiProvider,
    private _activateRoute: ActivatedRoute,
    private _settings: SettingsService,
    private _toastr: ToastrService,
   ) {
    this._activateRoute.params.subscribe( params => {
      this.clave_id = params['id'];
      this.aseg_id = params['aseg_id'];
      this.rest_api.get_comisiones(params['id']).then(response=>{
        this.comisiones = response;        
      }, error => {
        this._toastr.error('Ha ocurrido un error al cargar las comisiones, recargue la página nuevamente.')
      });
      
      this.rest_api.get_ramos(this.aseg_id).then(response => {
        this.ramos = response;
        this._toastr.success('Ramos cargados');
        this.subramos = [];
      }, error => {
        this._toastr.success('Ocurrió un error al cargar los ramos');
      })
      .catch(err => {
        this._toastr.success('Ocurrió un error al cargar los ramos');
      });
    });
    this.form = new FormGroup({
      comision: new FormGroup({
        nombre : new FormControl(null, [Validators.required] ),
        comission : new FormControl(0, [Validators.required]),
        udi : new FormControl(0, [Validators.required]),
        others : new FormControl(0, [Validators.required]),
        observations : new FormControl(0, [Validators.required]),
        efective_date : new FormControl(new Date(), [Validators.required] ),
        expire_date : new FormControl(new Date(), [Validators.required]),
        periodo_inicial : new FormControl(0, [Validators.required]),
        periodo_subsecuente : new FormControl(0, [Validators.required]),
        rpf_mensual : new FormControl(0, [Validators.required]),
        rpf_bimestral : new FormControl(0, [Validators.required]),
        rpf_trimestral : new FormControl(0, [Validators.required]),
        rpf_semestral : new FormControl(0, [Validators.required]),
        rpf_anual : new FormControl(0, [Validators.required]),
        derecho : new FormControl(0, [Validators.required]),
        comission_rpf : new FormControl(0, [Validators.required]),
        udi_rpf : new FormControl(0, [Validators.required]),
        others_rpf : new FormControl(0, [Validators.required]),
        comission_derecho : new FormControl(0, [Validators.required]),
        udi_derecho : new FormControl(0, [Validators.required]),
        others_derecho : new FormControl(0, [Validators.required]),
        url : new FormControl(null),
        id : new FormControl(null),
        ramo : new FormControl(0),
        subramo : new FormControl(0),
      })
    });

  
  }

  getDate(datetime: string) {
    try {
      datetime = datetime.split('T')[0];
      return datetime;

    } catch (err) {
      console.log(err);
      return datetime;
    }
  }

  ngOnInit() {
    
  }

  newComision(){
    this.form.reset({comision :{
      comission : 0,
      udi : 0,
      others : 0,
      observations : 0,
      periodo_inicial : 0,
      periodo_subsecuente : 0,
      rpf_mensual : 0,
      rpf_bimestral : 0,
      rpf_trimestral : 0,
      rpf_semestral : 0,
      rpf_anual : 0,
      derecho : 0,
      comission_rpf : 0,
      udi_rpf : 0,
      others_rpf : 0,
      comission_derecho : 0,
      udi_derecho : 0,
      others_derecho : 0,
      url : 0,
      ramo : 0,
      subramo : 0,
    }});
    this.comision_seleccionada = 0;
    this.subramos = [];
  }

  save(){
    console.log(this.form.value);
    if ((this.form.get('comision') as FormGroup).get('ramo').value == 0){
      this._toastr.error('Seleccione un ramo');
      return
    }
    if ((this.form.get('comision') as FormGroup).get('ramo').value == 0){
      this._toastr.error('Seleccione un subramo');
      return
    }
    
    this.form.value['clave_id'] = this.clave_id;
    this.rest_api.guardar_comision(this.form.value).then(response => {
      if (response == '{"Error":"Ramos matching query does not exist."}') {
        this._toastr.error('Ha seleccionado un Subramo inválido');
        return
      }
      if (response == '{"Error":"SubRamos matching query does not exist."}') {
        this._toastr.error('Ha seleccionado un Subramo inválido');
        return
      }
      console.log(response);
      
      this._toastr.success('Comisión guardada correctamente, recargue la página para ver los cambios');
    })
  }

  cancel() {
    this.router.navigate(['/claves-agente']);
  }

  changeComision(){
    this.comision = this.comisiones.filter(com => com['id'] == this.comision_seleccionada);
    if (this.comision && this.comision.length > 0){
      this.comision = this.comision[0];
      (this.form.get('comision') as FormGroup).reset(this.comision);
      (this.form.get('comision') as FormGroup).get('efective_date').setValue(this.getDate(this.comision['efective_date']));
      (this.form.get('comision') as FormGroup).get('expire_date').setValue(this.getDate(this.comision['expire_date']));
    }      
    this.changeRamo();  
  }

  changeRamo() {
    console.log(this.form.value);
    
    return new Promise((resolve,reject)=>{
      this.rest_api.get_subramos(this.aseg_id, (this.form.get('comision') as FormGroup).get('ramo').value).then(response => {
        this.subramos = response;
        this._toastr.success('Subamos cargados');
        resolve(true);
      }, error => {
        reject(error)
      })
      .catch(err => {
        reject(err)
      });
    });
    
  }

  get nombreNoValido(){
    return (this.form.get('comision') as FormGroup).get('nombre').invalid && (this.form.get('comision') as FormGroup).get('nombre').touched;
  }
  get comissionNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission').invalid && (this.form.get('comision') as FormGroup).get('comission').touched;
  }
  get udiNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi').invalid && (this.form.get('comision') as FormGroup).get('udi').touched;
  }
  get othersNoValido(){
    return (this.form.get('comision') as FormGroup).get('others').invalid && (this.form.get('comision') as FormGroup).get('others').touched;
  }
  get observationsNoValido(){
    return (this.form.get('comision') as FormGroup).get('observations').invalid && (this.form.get('comision') as FormGroup).get('observations').touched;
  }
  get efective_dateNoValido(){
    return (this.form.get('comision') as FormGroup).get('efective_date').invalid && (this.form.get('comision') as FormGroup).get('efective_date').touched;
  }
  get expire_dateNoValido(){
    return (this.form.get('comision') as FormGroup).get('expire_date').invalid && (this.form.get('comision') as FormGroup).get('expire_date').touched;
  }
  get periodo_inicialNoValido(){
    return (this.form.get('comision') as FormGroup).get('periodo_inicial').invalid && (this.form.get('comision') as FormGroup).get('periodo_inicial').touched;
  }
  get periodo_subsecuenteNoValido(){
    return (this.form.get('comision') as FormGroup).get('periodo_subsecuente').invalid && (this.form.get('comision') as FormGroup).get('periodo_subsecuente').touched;
  }
  get rpf_mensualNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_mensual').invalid && (this.form.get('comision') as FormGroup).get('rpf_mensual').touched;
  }
  get rpf_bimestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_bimestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_bimestral').touched;
  }
  get rpf_trimestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_trimestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_trimestral').touched;
  }
  get rpf_semestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_semestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_semestral').touched;
  }
  get rpf_anualNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_anual').invalid && (this.form.get('comision') as FormGroup).get('rpf_anual').touched;
  }
  get derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('derecho').invalid && (this.form.get('comision') as FormGroup).get('derecho').touched;
  }
  get comission_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission_rpf').invalid && (this.form.get('comision') as FormGroup).get('comission_rpf').touched;
  }
  get udi_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi_rpf').invalid && (this.form.get('comision') as FormGroup).get('udi_rpf').touched;
  }
  get others_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('others_rpf').invalid && (this.form.get('comision') as FormGroup).get('others_rpf').touched;
  }
  get comission_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission_derecho').invalid && (this.form.get('comision') as FormGroup).get('comission_derecho').touched;
  }
  get udi_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi_derecho').invalid && (this.form.get('comision') as FormGroup).get('udi_derecho').touched;
  }
  get others_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('others_derecho').invalid && (this.form.get('comision') as FormGroup).get('others_derecho').touched;
  }
  get subramoNoValido(){
    return (this.form.get('comision') as FormGroup).get('subramo').invalid && (this.form.get('comision') as FormGroup).get('subramo').touched;
  }
  get ramoNoValido(){
    return (this.form.get('comision') as FormGroup).get('ramo').invalid && (this.form.get('comision') as FormGroup).get('ramo').touched;
  }

}



