import { Component } from '@angular/core';
import { SettingsService } from './services/service.index';
import { Router, NavigationEnd } from '@angular/router'
import { RestApiProvider } from './services/shared/api.service';
import { RestApiPaymentProvider } from './services/shared/api.payment.service';
import { DataService } from './services/shared/dataShare.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'adminPro';

  constructor(
      public _ajustes: SettingsService,
      private router: Router,
      private rest_api: RestApiProvider,
      private rest_api_payment : RestApiPaymentProvider,
      private _data_share : DataService
    ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { /* Your code goes here on every router change */
        if (!ev.url.includes('login') && !ev.url.includes('activate') && this._ajustes.ajustes.org.billiable  && !this._ajustes.amISuperInOrg() && !this._ajustes.amISuperOutOrg()){
          this.rest_api_payment.getSuscriptionsByClient(this._ajustes.ajustes.org.urlname).then(response_subs => {

            (response_subs as Array<any>).forEach(subs => {
              if (subs['status'] == 'active'){
                this._ajustes.ajustes.org['suscripcion'] = subs;
                this._ajustes.guardarAjustes();
              }
            })
            
            this._data_share.changeMessage({
              "estatus_suscripcion": response_subs['status']
            })


            if (response_subs && response_subs['status'] == 'cancel'){
              this.router.navigate(['/terminal-digital']);
            }
            
            if ((response_subs as Array<any>).length == 0  ){
              this.router.navigate(['/terminal-digital']);
            } 
          }, error => {
            this.router.navigate(['/terminal-digital']);
          })
          .catch(err => {
            this.router.navigate(['/terminal-digital']);
          });
        }
      }




    });
   }

   changeOfRoutes(){}
}
