import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { SettingsService } from 'src/app/services/service.index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
// import * as XLSX from 'ts-xlsx';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Router, ActivatedRoute } from '@angular/router';

// type AOA = any[][];

@Component({
  selector: 'app-usersApp-detail',
  templateUrl: './usersApp-detail.component.html',
  styleUrls: ['./usersApp-detail.component.css']
})
export class UsersAppDetailComponent implements OnInit {
  title = 'Crear';
  user: any = null;
  user_url;
  form: FormGroup;
  formExcel: FormGroup;
  styleBtnActive;
  styleBtnInactive;
  showUserActive;
  showUserInactive;
  showSmtp;
  emailRemitente;
  arrayBuffer:any;
  file:File;
  excelJson:any = [];
  optionsViews:any = [
    {'value':10, 'label':'10'},
    {'value':25, 'label':'25'},
    {'value':50, 'label':'50'}
  ];
  optionViews = this.optionsViews[0];
  valueInitial = 1;
  valueFinal = 10;
  excelJsonViews = [];
  useSmtp = true;

  constructor(
    private _route: Router,
    private _router: ActivatedRoute,
    private rest_api : RestApiProvider,
    private http:HttpClient,
    private toaster: ToastrService,
    private _settings:SettingsService,
    private _validators: ValidadoresService,
  ) { 
    this.form = new FormGroup({
      checkEmail: new FormControl(false),
      checkSMTP: new FormControl(false),
      remitente: new FormControl(''),
      asunto: new FormControl(''),
      mensaje: new FormControl(''),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl(
        '',
        [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
        ],
        this._validators.existeEmail.bind(this)
      ),
      password: new FormControl('', Validators.required),
      password_confirm: new FormControl(''),
      identifier: new FormControl('', Validators.required,)
    }, {
      validators: this._validators.passwordsIguales('password', 'password_confirm').bind(this.form)
    });

    this.formExcel = new FormGroup({
      checkEmail: new FormControl(false),
      checkSMTP: new FormControl(false),
      remitente: new FormControl(''),
      asunto: new FormControl(''),
      mensaje: new FormControl(''),
      data: new FormControl('')
    });

    this.showSmtp = false;
    this.emailRemitente = false;
    this.showUsers(1);

    this._router.params.subscribe( params => {
      if (params['id'] !== 'nuevo') {
        this.title = "Editar";
        this.form.reset(this._settings.ajustes.tema);
      }
    });
  }

  // get emailNoValido(){
  //   return (this.form.get('email') as FormGroup).get('email').invalid && (this.form.get('email') as FormGroup).get('email').touched;
  // }
  // get last_nameNoValido(){
  //   return (this.form.get('last_name') as FormGroup).get('last_name').invalid && (this.form.get('last_name') as FormGroup).get('last_name').touched;
  // }
  // get first_nameNoValido(){
  //   return (this.form.get('first_name') as FormGroup).get('first_name').invalid && (this.form.get('first_name') as FormGroup).get('first_name').touched;
  // }
  // get passwordNoValido(){
  //   return (this.form.get('password') as FormGroup).get('password').invalid && (this.form.get('password') as FormGroup).get('password').touched;
  // }
  // get password_confirmNoValido(){
  //   return (this.form.get('password_confirm') as FormGroup).get('password_confirm').invalid && (this.form.get('password_confirm') as FormGroup).get('password_confirm').touched;
  // }
  // get identifier_NoValido(){
  //   return (this.form.get('identifier') as FormGroup).get('identifier').invalid && (this.form.get('identifier') as FormGroup).get('identifier').touched;
  // }
  ngOnInit() {
  }

  showUsers(value){
    if (value == 1){
      this.styleBtnActive = {
        'background-color': '#e2e2e2',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      };
      this.styleBtnInactive = {
        'background-color': '#ffffff',
        border: 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        cursor: 'pointer',
        'font-weight': '700',
        padding: '6px 24px'
      };
      this.showUserActive = true;
      this.showUserInactive = false;
      this.ngOnInit();
    } else {
      this.styleBtnInactive = {
        'background-color': '#e2e2e2',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      };
      this.styleBtnActive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      };
      this.showUserActive = false;
      this.showUserInactive = true;
    }
  }

  showEmailSMTP() {
    this.showSmtp = !this.showSmtp;
    this.rest_api.check_smtp().then(response =>{
      this.form.controls['checkSMTP'].enable();
      this.useSmtp = true;
    }, error => {
      this.form.controls['checkSMTP'].disable();
      this.useSmtp = false;
    }).catch(err =>{
      this.form.controls['checkSMTP'].disable();
      this.useSmtp = false;
    });
  }

  showEmailRemitente(){
    this.emailRemitente = !this.emailRemitente;
    this.form.value.remitente = '';
    console.log(this.emailRemitente);
    console.log(this.form.value);
  }

  save() {
    if (this.title === 'Crear') {
      if (this.form.value.checkSMTP) {
        this.form.value.remitente = '';
      }
      if(this.form.value && !this.form.value['identifier']){
        return  (Swal.fire({
          title: "Identificador",
          text: "Agregue el Identificador"
        }))
      }
      if(this.form.value && !this.form.value['email']){
        return  (Swal.fire({
          title: "Correo Electrónico",
          text: "Agregue el Correo Electrónico"
        }))      
      }else{
        if(this.validateEmail(this.form.value['email']) ==false){
          return  (Swal.fire({
            title: "Correo Electrónico",
            text: "Agregue una dirección válida en el Correo Electrónico"
          }))           
        }
      }
      if(this.form.value && !this.form.value['first_name']){
        return  (Swal.fire({
          title: "Nombre",
          text: "Agregue el Nombre del Usuario"
        }))     
      }
      if(this.form.value && !this.form.value['last_name']){
        return  (Swal.fire({
          title: "Apellido",
          text: "Agregue el Apellido del Usuario"
        }))      
      }      
      if(this.form.value && !this.form.value['password']){
        return  (Swal.fire({
          title: "Contraseña",
          text: "Agregue la Contraseña del Usuario"
        }))      
      }    
      if(this.form.value && !this.form.value['password_confirm']){
        return  (Swal.fire({
          title: "Contraseña",
          text: "Reescriba la Contraseña del Usuario"
        }))      
      }
      if(this.form.value && this.form.value['password'] && this.form.value['password_confirm'] && (String(this.form.value['password']) != String(this.form.value['password_confirm']))){
        return  (Swal.fire({
          title: "Confirmación contraseña",
          text: "Las contraseñas deben coincidir"
        }))      
      }
      this.rest_api.saveUserApp(this.form.value).then(response => {
          if (response['status'] === 400){
            this.toaster.error(response['error']);
          } else {
            this.toaster.success('Usuario creado exitosamente');
          }
          this.cancel();
      }, error => {
        this.form.enable();
      }).catch(error => {
        this.form.enable();
      });
    } else {
      if (this.form.value.checkSMTP){
        this.form.value.remitente = '';
      }
      if (this.form.value.checkSMTP) {
        this.form.value.remitente = '';
      }
      if(this.form.value && !this.form.value['identifier']){
        return  (Swal.fire({
          title: "Identificador",
          text: "Agregue el Identificador"
        }))
      }
      if(this.form.value && !this.form.value['email']){
        return  (Swal.fire({
          title: "Correo Electrónico",
          text: "Agregue el Correo Electrónico"
        }))      
      }else{
        if(this.validateEmail(this.form.value['email']) ==false){
          return  (Swal.fire({
            title: "Correo Electrónico",
            text: "Agregue una dirección válida en el Correo Electrónico"
          }))           
        }
      }
      if(this.form.value && !this.form.value['first_name']){
        return  (Swal.fire({
          title: "Nombre",
          text: "Agregue el Nombre del Usuario"
        }))     
      }
      if(this.form.value && !this.form.value['last_name']){
        return  (Swal.fire({
          title: "Apellido",
          text: "Agregue el Apellido del Usuario"
        }))      
      }      
      if(this.form.value && !this.form.value['password']){
        return  (Swal.fire({
          title: "Contraseña",
          text: "Agregue la Contraseña del Usuario"
        }))      
      }    
      if(this.form.value && !this.form.value['password_confirm']){
        return  (Swal.fire({
          title: "Contraseña",
          text: "Reescriba la Contraseña del Usuario"
        }))      
      }
      if(this.form.value && this.form.value['password'] && this.form.value['password_confirm'] && (String(this.form.value['password']) != String(this.form.value['password_confirm']))){
        return  (Swal.fire({
          title: "Confirmación contraseña",
          text: "Las contraseñas deben coincidir"
        }))      
      }
      this.form.value.userinfo = {
        id: this._settings.ajustes.tema['userinfo']['id'],
        identifier: this.form.value.identifier
      }
      this.rest_api.actualizar_usuario(this._settings.ajustes.tema['url'], this.form.value).then(response => {
        if (response['status'] == 400){
          this.toaster.error(response['error']);
        } else {
          this.toaster.success('Usuario creado exitosamente');
          this._route.navigate(['/usuarios']);
          }
        this.cancel();
      }, error => {
        this.form.enable();
      }).catch(error => {
        this.form.enable();
      });
    }
  }
  validateEmail(correoel){           
    var emailField = correoel;    
    var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    if( validEmail.test(emailField) ){
      return true;
    }else{
      return false;
    }
  } 
  incomingfile(event){
    this.file = event.target.files[0]; 
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for(let i = 0; i != data.length; ++i){
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, {type:"binary"});
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.jsonExcel(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
    }
    fileReader.readAsArrayBuffer(this.file);
  }
  
  jsonExcel(param){
    this.excelJson = param;
    this.addViews(0);
  }

  addViews = function(value){
    if (value == 0){
      this.valueInitial = 1;
      this.valueFinal = this.optionViews.value;
      if (this.valueFinal > this.excelJson.length){
        this.valueFinal = this.excelJson.length;
      }
    }else if (value == 1){
      this.valueInitial = this.valueInitial + this.optionViews.value;
      this.valueFinal = this.valueInitial + (this.optionViews.value - 1);
      if (this.valueFinal > this.excelJson.length){
        this.valueFinal = this.excelJson.length;
      }
    } else {
      this.valueInitial = this.valueInitial - this.optionViews.value;
      this.valueFinal = this.valueInitial + (this.optionViews.value - 1);
    }
    this.changeViews();
  };

  changeViews = function(){
    this.excelJsonViews = [];
    this.excelJson.forEach((item, index) => {
      if ((index + 1) >= this.valueInitial && index < this.valueFinal){
        this.excelJsonViews.push(item);
      }
    });
  };

  saveExcel(){
    this.formExcel.value.data = this.excelJson;
    if (this.form.value.checkSMTP){
      this.form.value.remitente = '';
    }
    this.rest_api.saveUserAppExcel(this.formExcel.value).then(response => {
      if (response['status'] == 400){
        this.toaster.error(response['error']);
      } else {
          this.toaster.success('Usuarios creados exitosamente');
      }
      this.cancel();
    }, error => {
      this.form.enable();
    }).catch(error => {
      this.form.enable();
    });
  }

  cancel(){
    this._settings.ajustes.tema = "";
    this._settings.guardarAjustes();
    this._route.navigate(['/usuarios-app']);
  }

}