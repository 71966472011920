import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usersreport',
  templateUrl: './usersreport.component.html',
  styleUrls: ['./usersreport.component.css']
})
export class UsersReportComponent implements OnInit {
  page = 0;
  total = 0;
  form: FormGroup;
  usuarios: any;
  beAbleLimitUsers = true;
  myControl = new FormControl();
  previous = null;
  turnOffPagination = false;
  next = null;
  orgSelected = 0;
  usuarios_del_plan = 0;
  button_active = 1;  
  orgs: any = [];
  styleBtnActive;
  styleBtnInactive;
  showUserActive;
  showUserInactive;
  constructor(
    private router: Router,
    private restApi: RestApiProvider,
    public settings: SettingsService,
    private _rest_api_payment : RestApiPaymentProvider,
    private toastr: ToastrService
  ) {
   }

  ngOnInit() {
    this.turnOffPagination = false;
    this.myControl.setValue('');
    this.settings.cargarAjustes();
    this.restApi.getUsersByOrg('',1).then(response => {
      this.usuarios = response['results'];
      this.total =response['count'];
      this.previous = response['previous'];
      this.next = response['next'];
      this.page = 1;
    });
    this.restApi.get_organizaciones_full().then(response => {
      this.orgs = response;
      this.orgs.unshift({
        name:'Seleccione una opción',
        id : 0
      })
    });
    this.styleBtnActive = {
      'background-color': '#e2e2e2',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.styleBtnInactive = {
      'background-color': '#ffffff',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.showUserActive = true;
    this.showUserInactive = false;
  }

  getUsersByOrg(org){
    this.restApi.getUsersByOrg(org,1).then(response=>{
      this.usuarios = response['results'];
      this.total =response['count'];
      this.previous = response['previous'];
      this.next = response['next'];
      this.page = 1;
    }, error => console.log('usuario no econtrado'))
    .catch( error =>console.log('usuario no econtrado'));     
  }

  changeUserStatus(index, user){
    this.restApi.parchar_url(user.url, {'is_active': !user.is_active}).then(response =>{
      this.toastr.success('Usuario actualizado correctamente');
    }, error => {
      console.log(error);
      this.toastr.error('Ha ocurrido un error con la actualización del usuario, intente nuevamente');
      this.usuarios[index].is_active = user.is_active;
    }).catch(error => {
      console.log(error);
      this.toastr.error('Ha ocurrido un error con la actualización del usuario, intente nuevamente');
      this.usuarios[index].is_active = user.is_active;
    })
    
  }

  nextPage(){
    if (this.next){
      this.restApi.get_url(this.next).then(responseNext => {
        this.usuarios = responseNext['results'];
        this.next = responseNext['next'];
        this.previous = responseNext['previous'];
      })
    }
  }

  generarReporte(){
    this.restApi.generateReport(this.orgSelected).then((response:any) =>{
      
      let filename = 'Reporte_Usuarios.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

  addUserPlan(){
    this.router.navigate(['/terminal-digital']);
  }

  previousPage(){
    if (this.previous){
      this.restApi.get_url(this.previous).then(responsePrevious => {
        this.usuarios = responsePrevious['results'];
        this.next = responsePrevious['next'];
        this.previous = responsePrevious['previous'];
      })
    }
  }

  detalleUsuario(user: any) {
    if (user) {
      this.router.navigate(['/usuarios-detalle/', user.id]);
    } else {
      this.router.navigate(['/usuarios-detalle', 'nuevo']);
    }
  }

  loadPage(event){
    if (this.button_active == 2){
      this.restApi.getUsersByOrg(this.orgSelected,event).then(response=>{
        this.usuarios = response['results'];
        this.total =response['count'];
        this.page = event;
        console.log(event)
      });
    } else {
      this.restApi.getUsersByOrg(this.orgSelected,event).then(response=>{
        this.usuarios = response['results'];
        this.total =response['count'];
        this.page = event;
        console.log(event)
      });
    }
  }

  SearchkeyUp(value){
    if (this.myControl.value.length <= 3){ return }
    this.restApi.get_usuario_by_name(this.myControl.value).then(response=>{
      this.usuarios = response as Array<[]>;
      this.turnOffPagination = true;
    }, error => console.log('usuario no econtrado'))
    .catch( error =>console.log('usuario no econtrado'));    
  }

  showUsers(value){
    if(value == 1){
      this.button_active = value;
      this.styleBtnActive = {
        'background-color': '#e2e2e2',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnInactive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = true;
      this.showUserInactive = false;
      this.ngOnInit();


    }else{
      this.button_active = value;
      this.styleBtnInactive = {
        'background-color': '#e2e2e2',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnActive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = false;
      this.showUserInactive = true;


      this.turnOffPagination = false;
      this.myControl.setValue('');
      this.settings.cargarAjustes();
      this.restApi.get_usuarios_inactivos().then(response => {
        this.usuarios = response['results'];
        this.total =response['count'];
        this.previous = response['previous'];
        this.next = response['next'];
        this.page = 1;
        if (this.settings.ajustes.org && this.settings.ajustes.org['id']) {
          this.restApi.isOrgDemo(this.settings.ajustes.org['id']).then(responseOrg => {
            if (responseOrg && response['count'] >= 3 ) {
              this.beAbleLimitUsers = false;
              return
            }
          });

          if (this.settings.ajustes.org.billiable){
            this._rest_api_payment.getSuscriptionsByClient(this.settings.ajustes.org.urlname).then(response_subs => {
              if ((response_subs as Array<any>).length > 0  ){
                response_subs = response_subs[0];
              }
              this.usuarios_del_plan = response_subs['plan']['users_number'] - this.usuarios.length;
              if (this.usuarios.length >= response_subs['plan']['users_number']){
                this.beAbleLimitUsers = false;
              }
            });
          } else {
            this.beAbleLimitUsers = true;
          }
        }
      });


    }
  }

}
