import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { ValidadoresService } from 'src/app/services/shared/validators';
@Component({
  selector: 'app-mc-details',
  templateUrl: './mc-details.component.html',
  styleUrls: ['./mc-details.component.css']
})
export class McDetailsComponent implements OnInit {
  public title = 'Editar';
  public form: FormGroup;
  public mc: McStructure = {};
  public services: any = [];
  public packages: any = [];
  public nuevoMc = false;
  public loading = false;
  constructor(
    private router: Router,
    private navParams: ActivatedRoute,
    private rest_api: RestApiProvider,
    private toaster: ToastrService,
    public dialog: MatDialog,
    private validators: ValidadoresService,
  ) {
    this.navParams.params.subscribe( params => {
      if (params['mcName'] && params['mcName'] === 'nuevo') {
        this.nuevoMc = true;
        this.mc.schema_name = params['mcName'];
      } else if (params && params['mcName'] && params['mcName'] !== 'nuevo') {
        this.mc.schema_name = params['mcName'];
      } else {
        this.toaster.error('No se incluyo el nombre del multicotizador');
        this.cancel();
      }
    });
    this.form = new FormGroup({
        aseguradora : new FormControl(null),
        nombre : new FormControl(null, [Validators.required]),
        razon : new FormControl(null, [Validators.required]),
        telefono : new FormControl(null, [Validators.required, this.validators.noDiezDigitosPhone]),
        direccion : new FormControl(null, [Validators.required, Validators.minLength(10)]),
        email : new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
        schema_name : new FormControl(null, Validators.required, this.validators.existeSchemaName.bind(this)),
        credenciales : new FormGroup({
          cve_agente : new FormControl(null),
          usuario : new FormControl(null),
          password : new FormControl(null),
          tarifa : new FormControl(null),
          conducto : new FormControl(null),
          no_negocio : new FormControl(null),
          id_oficina : new FormControl(null),
          programa_comercial : new FormControl(null),
          id_agrupacion : new FormControl(null),
          descuento : new FormControl(null),
          is_active : new FormControl(false),
          is_produccion : new FormControl(false),
          extra : new FormControl(false),
        }),
        paquete : new FormGroup({
          nombre_paquete: new FormControl(null)
        })
      });
  }

  save() {
    console.log(this.form);
    this.loading = true;
    // Save general info
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      window.scrollTo(0, 0);
      this.loading = false;
      return;
    }

    if (this.nuevoMc) {
      this.rest_api.create_mc(this.form.value).then(() => {
        this.loading = false;
        this.toaster.success('Multicotizador creado exitosamente');
        this.router.navigate(['/mc']);
      });
    } else {  
        this.rest_api.save_general_info(this.mc.schema_name, this.form.value).then(response => {
          this.toaster.success('Informacion general guardada correctamente');
          this.loading = false;
          
        });
        // Save credentials
        if (this.form.controls['aseguradora'].value !== 'null' && this.form.controls['aseguradora'].value !== null) {
        this.form.value['service'] = this.form.controls['aseguradora'].value;
        this.form.value['multicotizador'] = this.mc.schema_name;
        this.rest_api.save_credentials(
          this.form.controls['aseguradora'].value, 
          this.mc.schema_name,
          this.form.value['credenciales'])
          .then(response => {
            this.loading = false;
          this.toaster.success('Credenciales guardadas exitosamente');
        }, error => {
          this.loading = false;
          this.toaster.error('Ocurrio un error al guardar las credenciales');
        })
        .catch(err => {
          this.loading = false;
          this.toaster.error('Ocurrio un error al guardar las credenciales');
        });    
        }
    }
  }

  verCoberturas() {
    if (!(this.form.controls['paquete'] as FormGroup).get('nombre_paquete').value) { 
      this.toaster.warning('Debe seleccionar un paquete');  
      return; 
    }
    const nombrePaquete = (this.form.controls['paquete'] as FormGroup).get('nombre_paquete').value;
    this.rest_api.get_coverages_package(nombrePaquete, this.form.controls['aseguradora'].value, this.mc.schema_name).then(response => {
      let dialogRef;
      if (JSON.parse((response).toString()).length > 0) {
        dialogRef = this.dialog.open(CoveragesDialogComponent, {
          data: {
            data: JSON.parse((response).toString())
          }
        });
      } else {
        this.toaster.error('No existen coberturas en este paquete');
      }
      dialogRef.afterClosed().subscribe(result => {
        if (result && result['data']) {
          result['data']['package'] = nombrePaquete;
          this.rest_api.save_coverages_package(
            nombrePaquete, 
            this.form.controls['aseguradora'].value, 
            this.mc.schema_name, 
            result['data'])
            .then(responseCoverages => {
              console.log(responseCoverages);
              this.toaster.success('Coberturas actualizadas correctamente');
          }, error => {
            this.toaster.error('Ocurrio un error al guardar las coberturas');
          })
          .catch(err => {
            this.toaster.error('Ocurrio un error al guardar las coberturas');
          });
        }
      });
    });
  }

  changeAseguradora() {
    if (!this.form.controls['aseguradora'].value) { return; }
    this.packages = [];
    this.rest_api.get_mc_credentials(this.form.controls['aseguradora'].value, this.mc.schema_name).then(response => {
      response = JSON.parse((response).toString());
      (this.form.get('credenciales') as FormGroup).reset(response[0]);
      this.toaster.success('Credenciales cargadas');
      this.rest_api.get_mc_packages().then(responsePackages => {
        this.packages = JSON.parse(responsePackages.toString());
        
      });
    });
  }

  cancel() {
    this.router.navigate(['/mc']);
  }

  ngOnInit() {
    if (this.mc.schema_name !== 'nuevo') {
      this.rest_api.get_mc_info(this.mc.schema_name).then(response => {
        response = JSON.parse(response.toString());
        response['schema_name'] = response['name']['schema_name'];
        console.log(response);
        this.form.reset(response);
        this.form.get('schema_name').clearValidators();
        this.form.get('schema_name').clearAsyncValidators();
        this.form.get('schema_name').updateValueAndValidity();
        this.form.get('schema_name').disable();
      });
      this.rest_api.get_mc_services().then(response => {
        this.services = JSON.parse(response.toString());
      });
      (this.form.controls['paquete'] as FormGroup).get('nombre_paquete').setValue(null);
    } else {
      this.title = 'Crear'
    }
  }


  get nombreNoValido() {
    return this.form.get('nombre').invalid && this.form.get('nombre').touched;
  }
  get razonNoValido() {
    return this.form.get('razon').invalid && this.form.get('razon').touched;
  }
  get phoneNoValido() {
    return this.form.get('telefono').invalid && this.form.get('telefono').touched;
  }
  get direccionNoValido() {
    return this.form.get('direccion').invalid && this.form.get('direccion').touched;
  }
  get emailNoValido() {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }
  get schema_nameNoValido() {
    return this.form.get('schema_name').invalid && this.form.get('schema_name').touched && !this.form.get('schema_name').hasError('existe');
  }

}


export interface McStructure {
  schema_name?: any;
  id?: string;
  url?: string;
}


export interface DialogData {
  data: any;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'coverages-dialog',
  templateUrl: 'coverages.dialog.component.html',
})
export class CoveragesDialogComponent {
  form: FormGroup;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public dialogRef: MatDialogRef<CoveragesDialogComponent>, ) {
                this.form = new FormGroup({});
                console.log(this.data.data);
                this.data.data.forEach(coverage => {
                  this.form.addControl(coverage.id,
                    new FormGroup({
                      is_active: new FormControl(coverage.is_active),
                      id: new FormControl(coverage.id),
                      suma_asegurada: new FormControl(coverage.suma_asegurada, [Validators.required]),
                      deducible: new FormControl(coverage.deducible, [Validators.required]),
                      per_occ : new FormControl(false)
                    })
                  );
                });
              }
  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close({data : this.form.value});
  }
}
