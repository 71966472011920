import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiProvider } from 'src/app/services/shared/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private restApi: RestApiProvider,
    public _settings: SettingsService,
    private toaster: ToastrService
  ) { }

  ngOnInit() {
  }

  gotoClients(){
    this.router.navigate(['/organizaciones']);
  }

  gotoPlanes(){
    this.router.navigate(['/planes']);
  }

  gotoCobranza(){
    this.router.navigate(['/suscripciones/todas']);
  }

  gotoUsers(){
    this.router.navigate(['/usuarios']);
  }

  gotoSuscriptions(){
    this.router.navigate(['/suscripciones/todas']);
  }
  
  gotoClavesAgente(){
    this.restApi.get_aseguradoras().then(response => {
      if (response && (response as Array<any>).length === 0) {
        this.toaster.warning('Para administrar claves de agente, debe iniciar sesión por primera vez en SAAM');
      } else {
        this.router.navigate(['/claves-agente']); 
      }
    });
  }

  gotoSaam(){
    // window.open('http://' + this._settings.ajustes.org.urlname + '.localhost:3000', "_blank");
    window.open('https://' + this._settings.ajustes.org.urlname + '.miurabox.com', "_blank"); //Para produccion
  }

}
