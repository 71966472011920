import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../services/service.index';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styles: []
})
export class AccountSettingsComponent implements OnInit {
  form: FormGroup;
  showImageProfile = true;
  showImageProfileMini = true;
  org: any;
  imageChangedEvent: any = '';
  imageChangedEventMini: any = '';
  croppedImage: any = '';
  croppedImageMini: any = '';
  beAble = true;
  showEdit = false;
  constructor(
    public _ajustes: SettingsService,
    private rest_api: RestApiProvider,
    private _validators: ValidadoresService,
    private _toaster : ToastrService
    ) {
      this.form = new FormGroup({
        name : new FormControl(null, [Validators.required]),
        alias : new FormControl(null, [Validators.required]),
        phone : new FormControl(null, [Validators.required, this._validators.noDiezDigitosPhone]),
        email : new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
        address : new FormControl(null, [Validators.required]),
        webpage : new FormControl(null, [Validators.required])
      });
      this._ajustes.cargarAjustes();
      this.rest_api.get_organizacion_info(this._ajustes.ajustes.org['id']).then(response => {
        this.form.reset(response);
        this.org = response;
      }, error => {
        console.log(error);
        this._toaster.error('Hubo un error al cargar la informacion');
        this.beAble = false;
        this.form.disable();
      })
      .catch(errr => {
        console.log(errr);
        this.beAble = false;
        this.form.disable();
        this._toaster.error('Hubo un error al cargar la informacion');
      });
     }

  ngOnInit() {
    this.colocarCambio();
  }

  edit(param: boolean) {
    this.showEdit = param;
  }
  save() {
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      });
      return;
    }
    this.form.value['id'] = this.org.id;
    this.rest_api.parchar_url(
      this.org.url,
      this.form.value).then(response => {
      this._ajustes.ajustes.org = response;
      this._ajustes.guardarAjustes();
      this._toaster.success('Registro actualizado correctamente');
    }, error => {
      console.error(error);
      this._toaster.error('Ocurrio un error, intentelo mas tarde');
    })
    .catch(err => {
      console.error(err);
      this._toaster.error('Ocurrio un error, intentelo mas tarde');
    });
  }

  cambiarColor(tema: string, link: any){
    this.aplicarCambio(link);
    this._ajustes.aplicarTema(tema);
  }

  aplicarCambio(link: any){
    const selectores: any = document.getElementsByClassName('selector');

    for (const ref of selectores) {
      ref.classList.remove('working');
    }

    link.classList.add('working');
  }

  colocarCambio(){
    const selectores: any = document.getElementsByClassName('selector');

    for (const ref of selectores){
      if (ref.getAttribute('data-theme') === this._ajustes.ajustes.tema) {
        ref.classList.add('working');
        break;
      }
    }

  }
  get nameNoValido(){
    return this.form.get('name').invalid && this.form.get('name').touched;
  }
  get aliasNoValido(){
    return this.form.get('alias').invalid && this.form.get('alias').touched;
  }
  get phoneNoValido(){
    return this.form.get('phone').invalid && this.form.get('phone').touched;
  }
  get emailNoValido(){
    return this.form.get('email').invalid && this.form.get('email').touched;
  }
  get addressNoValido(){
    return this.form.get('address').invalid && this.form.get('address').touched;
  }
  get webpageNoValido(){
    return this.form.get('webpage').invalid && this.form.get('webpage').touched;
  }

  imageLoaded(){
    this._toaster.success('Imagen cargada');
  }

  loadImageFailed(){
    this._toaster.error('Ha ocurrido un error con la imagen cargada');
  }

  cropperReady(){
    this._toaster.success('Imagen cortada');
  }

  check(val){
    console.log(val);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showImageProfile = false;
  }

  fileChangeEventMini(event: any): void {
    this.imageChangedEventMini = event;
    this.showImageProfileMini = false;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageCroppedMini(event: ImageCroppedEvent) {
    this.croppedImageMini = event.base64;
  }

  change_imagen_logo(event){
    const payload = {
      logo: this.croppedImage
    };

    this.rest_api.parchar_url_media(
        this.org.url.replace('organizations', 'organization-logo') ,
        payload
      )
      .then(response => {
      this.showImageProfile = true;
      this.org['logo'] = response['logo'];
      this._toaster.success('Imagen guardada correctamente');
    });

  }

  change_imagen_logo_mini(event){
    const payload = {
      logo_mini: this.croppedImageMini
    };

    this.rest_api.parchar_url_media(
        this.org.url.replace('organizations', 'organization-logo-mini') ,
        payload
      )
      .then(response => {
      this.showImageProfileMini = true;
      this.org['logo_mini'] = response['logo_mini'];
      this._toaster.success('Imagen guardada correctamente');
    });

  }




}
