import { Component, Inject, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { resolve } from 'url';




@Component({
  selector: 'app-claves-agente',
  templateUrl: './claves-agente.component.html',
  styleUrls: ['./claves-agente.component.css']
})



export class ClavesAgenteComponent implements OnInit {
  agregarEditar = 'Agregar';
  aseguradoras: any;
  claves: any;
  clave: any = {};
  form: FormGroup;
  constructor(
    private restApi: RestApiProvider,
    private ajustes: SettingsService,
    private router: Router,
    private toaster: ToastrService,
    private validators: ValidadoresService,
    public dialog: MatDialog,
  ) {
      ajustes.cargarAjustes();
      this.form = new FormGroup({
        aseguradora: new FormControl(0, [Validators.required, this.validators.noSelectedOption] ),
        ramo: new FormControl(0, [Validators.required, this.validators.noSelectedOption] ),
        subramo: new FormControl(0, [Validators.required, this.validators.noSelectedOption] ),
      });
  }

  cargarAseguradoras(){
    this.ngOnInit();
  }

  detalleClave(clave: any) {
    this.form.get('aseguradora').setValue(clave.aseguradora_code);
    const dialogRef = this.dialog.open(AddClaveDialogComponent, {
      disableClose: true,
      data: {
        clave: clave
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      
      if(result && result['data']){
        result['data']['aseguradora'] = this.form.get('aseguradora').value;
        result['data']['ramo'] = this.form.get('ramo').value;
        result['data']['subramo'] = this.form.get('subramo').value;
        if (clave && clave['id']) {
          result['data']['id'] = clave.id;
        }
        this.restApi.crear_clave(result['data']).then(response => {
          this.claves.some((clave,index) => {
            if (clave['id'] === response['id']) {
              this.claves[index] = response;
            }
          });
        });
      }
    });
  }

  save() {

  }

  ngOnInit() {
    if (this.ajustes.ajustes.org && this.ajustes.ajustes.org.id){
      this.restApi.get_aseguradoras().then(response => {
        
        if (response && (response as Array<any>).length === 0) {
          this.toaster.warning('Para administrar claves de agente, debe iniciar sesión por primera vez en SAAM');
          return
        }

        this.aseguradoras = response;
        if (this.aseguradoras && this.aseguradoras.length === 0) {
          this.toaster.warning('No se encontraron aseguradoras');
        } else {
          this.toaster.success('Aseguradoras cargadas');
        }
        
        this.button();
      });
    } else {
      this.toaster.warning('Esta petición debe de hacerse desde una organización');
      return;
    }
  }

  nuevaClave(){
    if ( this.form.get('aseguradora').value == 0){
      this.toaster.error('Para crear una clave debes seleccionar Aseguradora');
      return;
    }
    // if ( this.form.get('ramo').value == 0){
    //   this.toaster.error('Para crear una clave debes seleccionar Ramo');
    //   return;
    // }

    // if ( this.form.get('subramo').value == 0){
    //   this.toaster.error('Para crear una clave debes seleccionar Subramo');
    //   return;
    // }
    
    const dialogRef = this.dialog.open(AddClaveDialogComponent, {
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if(result && result['data']){
        result['data']['aseguradora'] = this.form.get('aseguradora').value;
        // result['data']['ramo'] = this.form.get('ramo').value;
        // result['data']['subramo'] = this.form.get('subramo').value;
        if (this.clave && this.clave['id']) {
          this.form.value['id'] = this.clave.id;
        }
        this.restApi.crear_clave(result['data']).then(response => {
          if (this.clave && !this.clave['id']) {
            this.claves.push(response);
          } else {
            this.claves.map(clave => {
              if (clave['id'] === response['id']) {
                clave = response;
              }
            });
            this.button();
          }
        });
      }
    });
  }

  verComisiones(clave){
    console.log(clave);
    
    this.router.navigate(['/clave-detalle', clave.id, clave.aseguradora_code]);
  }

  button() {
    const payload = {
      aseguradora : this.form.value['aseguradora'],
      // ramo : this.form.value['ramo'],
      // subramo : this.form.value['subramo'],
    }
    this.restApi.get_claves_general(payload).then(response => {
      this.claves = response;
      if (this.claves && this.claves.length === 0) {
        this.toaster.warning('No se encontraron claves');
      } else {
        this.toaster.success('Claves cargadas');
      }
    }, error => {
      this.toaster.error('Occurio un error al cargar las claves');
    })
    .catch(err => {
      this.toaster.error('Occurio un error al cargar las claves');
    });
  }


  get aseguradoraNoValido(){
    return this.form.get('aseguradora').invalid && this.form.get('aseguradora').touched;
  }
  get ramoNoValido(){
    return this.form.get('ramo').invalid && this.form.get('ramo').touched;
  }
  get subramoNoValido(){
    return this.form.get('subramo').invalid && this.form.get('subramo').touched;
  }
}


export interface DialogData {
  aseguradoras:any
  ramos: any;
  subramos:any;
}


@Component({
  selector: 'add-new-clave',
  templateUrl: 'add.clave.dialog.component.html',
})
export class AddClaveDialogComponent {
  aplications:any;
  app: any;
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddClaveDialogComponent>,
    private _validators:ValidadoresService,
    private rest_api: RestApiProvider,
    private _settings: SettingsService,
    private _toastr: ToastrService,
    ) {
    this.form = new FormGroup({
      clave: new FormGroup({
        name : new FormControl(null, [Validators.required] ),
        clave : new FormControl(null, [Validators.required] )
      })
      // comision: new FormGroup({
      //   nombre : new FormControl(null, [Validators.required] ),
      //   comission : new FormControl(0, [Validators.required]),
      //   udi : new FormControl(0, [Validators.required]),
      //   others : new FormControl(0, [Validators.required]),
      //   observations : new FormControl(0, [Validators.required]),
      //   efective_date : new FormControl(new Date(), [Validators.required] ),
      //   expire_date : new FormControl(new Date(), [Validators.required]),
      //   periodo_inicial : new FormControl(0, [Validators.required]),
      //   periodo_subsecuente : new FormControl(0, [Validators.required]),
      //   rpf_mensual : new FormControl(0, [Validators.required]),
      //   rpf_bimestral : new FormControl(0, [Validators.required]),
      //   rpf_trimestral : new FormControl(0, [Validators.required]),
      //   rpf_semestral : new FormControl(0, [Validators.required]),
      //   rpf_anual : new FormControl(0, [Validators.required]),
      //   derecho : new FormControl(0, [Validators.required]),
      //   comission_rpf : new FormControl(0, [Validators.required]),
      //   udi_rpf : new FormControl(0, [Validators.required]),
      //   others_rpf : new FormControl(0, [Validators.required]),
      //   comission_derecho : new FormControl(0, [Validators.required]),
      //   udi_derecho : new FormControl(0, [Validators.required]),
      //   others_derecho : new FormControl(0, [Validators.required]),
      // })
    });

    
    if (this.data && this.data['clave']){
      (this.form.get('clave') as FormGroup).reset(this.data['clave']);
      // (this.form.get('comision') as FormGroup).reset(this.data['clave']['clave_comision'][0]);
      // (this.form.get('comision') as FormGroup).get('efective_date').setValue(this.getDate(this.data['clave']['clave_comision'][0]['efective_date']));
      // (this.form.get('comision') as FormGroup).get('expire_date').setValue(this.getDate(this.data['clave']['clave_comision'][0]['expire_date']));
    }
    this._settings.cargarAjustes();
  }

  


  cancel() {
    this.dialogRef.close();
  }
  
  save() {
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      });
      this._toastr.error('Revise que todos los campos sean validos', 'Error');
    } else {
      this.dialogRef.close({data: this.form.value});
    }
  }

  get nameNoValido(){
    return (this.form.get('clave') as FormGroup).get('name').invalid && (this.form.get('clave') as FormGroup).get('name').touched;
  }
  get claveNoValido(){
    return (this.form.get('clave') as FormGroup).get('clave').invalid && (this.form.get('clave') as FormGroup).get('clave').touched;
  }
}




@Component({
  selector: 'add-new-clave',
  templateUrl: 'add.clave.dialog.component.html',
})
export class AddComisionDialogComponent {
  aplications:any;
  app: any;
  form: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<AddComisionDialogComponent>,
    private _validators:ValidadoresService,
    private rest_api: RestApiProvider,
    private _settings: SettingsService,
    private _toastr: ToastrService,
    ) {
    this.form = new FormGroup({
      comision: new FormGroup({
        nombre : new FormControl(null, [Validators.required] ),
        comission : new FormControl(0, [Validators.required]),
        udi : new FormControl(0, [Validators.required]),
        others : new FormControl(0, [Validators.required]),
        observations : new FormControl(0, [Validators.required]),
        efective_date : new FormControl(new Date(), [Validators.required] ),
        expire_date : new FormControl(new Date(), [Validators.required]),
        periodo_inicial : new FormControl(0, [Validators.required]),
        periodo_subsecuente : new FormControl(0, [Validators.required]),
        rpf_mensual : new FormControl(0, [Validators.required]),
        rpf_bimestral : new FormControl(0, [Validators.required]),
        rpf_trimestral : new FormControl(0, [Validators.required]),
        rpf_semestral : new FormControl(0, [Validators.required]),
        rpf_anual : new FormControl(0, [Validators.required]),
        derecho : new FormControl(0, [Validators.required]),
        comission_rpf : new FormControl(0, [Validators.required]),
        udi_rpf : new FormControl(0, [Validators.required]),
        others_rpf : new FormControl(0, [Validators.required]),
        comission_derecho : new FormControl(0, [Validators.required]),
        udi_derecho : new FormControl(0, [Validators.required]),
        others_derecho : new FormControl(0, [Validators.required]),
      })
    });

    
    if (this.data && this.data['clave']){
      (this.form.get('comision') as FormGroup).get('efective_date').setValue(this.getDate(this.data['clave']['clave_comision'][0]['efective_date']));
      (this.form.get('comision') as FormGroup).get('expire_date').setValue(this.getDate(this.data['clave']['clave_comision'][0]['expire_date']));
    }
    this._settings.cargarAjustes();
  }

  get nameNoValido(){
    return (this.form.get('clave') as FormGroup).get('name').invalid && (this.form.get('clave') as FormGroup).get('name').touched;
  }
  get claveNoValido(){
    return (this.form.get('clave') as FormGroup).get('clave').invalid && (this.form.get('clave') as FormGroup).get('clave').touched;
  }
  getDate(datetime: string) {
    try {
      datetime = datetime.split('T')[0];
      return datetime;

    } catch (err) {
      console.log(err);
      return datetime;
    }
  }


  cancel() {
    this.dialogRef.close();
  }
  
  save() {
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      });
      this._toastr.error('Revise que todos los campos sean validos', 'Error');
    } else {
      this.dialogRef.close({data: this.form.value});
    }
  }

  get nombreNoValido(){
    return (this.form.get('comision') as FormGroup).get('nombre').invalid && (this.form.get('comision') as FormGroup).get('nombre').touched;
  }
  get comissionNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission').invalid && (this.form.get('comision') as FormGroup).get('comission').touched;
  }
  get udiNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi').invalid && (this.form.get('comision') as FormGroup).get('udi').touched;
  }
  get othersNoValido(){
    return (this.form.get('comision') as FormGroup).get('others').invalid && (this.form.get('comision') as FormGroup).get('others').touched;
  }
  get observationsNoValido(){
    return (this.form.get('comision') as FormGroup).get('observations').invalid && (this.form.get('comision') as FormGroup).get('observations').touched;
  }
  get efective_dateNoValido(){
    return (this.form.get('comision') as FormGroup).get('efective_date').invalid && (this.form.get('comision') as FormGroup).get('efective_date').touched;
  }
  get expire_dateNoValido(){
    return (this.form.get('comision') as FormGroup).get('expire_date').invalid && (this.form.get('comision') as FormGroup).get('expire_date').touched;
  }
  get periodo_inicialNoValido(){
    return (this.form.get('comision') as FormGroup).get('periodo_inicial').invalid && (this.form.get('comision') as FormGroup).get('periodo_inicial').touched;
  }
  get periodo_subsecuenteNoValido(){
    return (this.form.get('comision') as FormGroup).get('periodo_subsecuente').invalid && (this.form.get('comision') as FormGroup).get('periodo_subsecuente').touched;
  }
  get rpf_mensualNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_mensual').invalid && (this.form.get('comision') as FormGroup).get('rpf_mensual').touched;
  }
  get rpf_bimestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_bimestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_bimestral').touched;
  }
  get rpf_trimestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_trimestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_trimestral').touched;
  }
  get rpf_semestralNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_semestral').invalid && (this.form.get('comision') as FormGroup).get('rpf_semestral').touched;
  }
  get rpf_anualNoValido(){
    return (this.form.get('comision') as FormGroup).get('rpf_anual').invalid && (this.form.get('comision') as FormGroup).get('rpf_anual').touched;
  }
  get derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('derecho').invalid && (this.form.get('comision') as FormGroup).get('derecho').touched;
  }
  get comission_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission_rpf').invalid && (this.form.get('comision') as FormGroup).get('comission_rpf').touched;
  }
  get udi_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi_rpf').invalid && (this.form.get('comision') as FormGroup).get('udi_rpf').touched;
  }
  get others_rpfNoValido(){
    return (this.form.get('comision') as FormGroup).get('others_rpf').invalid && (this.form.get('comision') as FormGroup).get('others_rpf').touched;
  }
  get comission_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('comission_derecho').invalid && (this.form.get('comision') as FormGroup).get('comission_derecho').touched;
  }
  get udi_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('udi_derecho').invalid && (this.form.get('comision') as FormGroup).get('udi_derecho').touched;
  }
  get others_derechoNoValido(){
    return (this.form.get('comision') as FormGroup).get('others_derecho').invalid && (this.form.get('comision') as FormGroup).get('others_derecho').touched;
  }


}
