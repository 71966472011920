import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiProvider } from 'src/app/services/shared/api.service';

@Component({
  selector: 'app-perfiles',
  templateUrl: './perfiles.component.html',
  styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {
  perfiles:any = [];
  canCreate = true;
  constructor(
    private _router:Router,
    private rest_api:RestApiProvider,
    private settings: SettingsService,
    private toaster: ToastrService
  ) {
    if (this.settings.ajustes.org && this.settings.ajustes.org.id){
      this.rest_api.obtener_perfiles('0').then(response => {
        this.perfiles = response;
        // console.log(this.perfiles);
        
      });
    } else {
      this.toaster.warning('Esta petición debe de hacerse desde una organización');
      this.canCreate = false;
      return;
    }
   }

  ngOnInit() {
  }

  detallePerfil(perfil:any){
    if (perfil){
      this._router.navigate(['/perfiles-detalle/',perfil.id]);
    }
    else{
      this._router.navigate(['/perfiles-detalle','nuevo']);
    }
    
  }

  generar_reporte(){
    this.rest_api.reporte_perfiles().then((response:any) =>{
      
      let filename = 'Reporte_Perfiles.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

}
