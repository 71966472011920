import { Component, Inject, OnInit } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { Toast, ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare let Conekta: any; //declare coneckta


@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientesComponent implements OnInit {
  clienteList:Array<Cliente> = []; 
  
  editField: string;
  orgs:any=[];
  constructor(
    private rest_api: RestApiPaymentProvider,
    private toaster: ToastrService,
    private rest_api_gral : RestApiProvider,
    public dialog: MatDialog,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  gotoSubs(org:number){
    this.router.navigate(['/suscripciones/', org]);
  }

  ngOnInit() {
    this.spinner.show();
    this.rest_api.get_clientes().then(response => {
      this.clienteList  = <Cliente[]>response;
      this.clienteList.map(cliente => {
        let actual_subs;
        if (cliente['cliente_suscripciones'] && cliente['cliente_suscripciones'].length > 0){
          cliente['cliente_suscripciones'] = cliente['cliente_suscripciones'].filter(cs => cs['status'] == 'active');
        }
        return cliente
      });
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    })
    .catch(err => {
      this.spinner.hide();
    });
    this.rest_api_gral.get_organizaciones_full().then(response => {
      this.orgs = response;
      console.log(this.orgs);
      this.orgs = this.orgs.map(org => {
        let urlname = org.urlname;
        let obj = {}
        obj[urlname] = urlname;
        return obj;
      });
      
    });
  }

  addCard(id:number){
    const dialogRef = this.dialog.open(AddCardDialogComponent, {
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result && result['id']){
        let payload = {
          'payment_sources' : {
            type: 'card',
            token_id: result['id']
          }
        }
        this.rest_api.update_cliente(this.clienteList[id]['id'], payload).then(response => {
          this.toaster.success('Tarjeta agregada exitosamente');
        }, error => this.toaster.error('Ah ocurrido un error'))
        .catch(err => this.toaster.error('Ah ocurrido un error') )
      }
    });
  }

  updatePlan(id: number, property: string, event: any) {
    let editField;
    try {
      editField = event.target.textContent;    
    }
    catch(err){
      editField = event;    
    }
    let cliente = this.clienteList[id];

    const dialogRef = this.dialog.open(AddSuscriptionDialogComponent, {
      disableClose: true,
      data: {
        'cliente_id':cliente.id,
        'cards':cliente.payment_sources
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result){
        let payload = {
          'card': result['card'],
          'cliente': cliente.id,
          'plan': result['plan_id'],
          'plan_id': result['plan'],
          'init_date': new Date(),
        };
        this.rest_api.createSuscription(payload).then(response_create_subs => {
          this.clienteList[id].cliente_suscripciones = [];
          this.clienteList[id].cliente_suscripciones.push(response_create_subs);
          this.toaster.success('Suscripción exitosa');
        }, error => {
          this.toaster.error('Error al crear suscripción');
        }).catch(err => {
          this.toaster.error('Error al crear suscripción');
        });
      }
    });
  }


  updateList(id: number, property: string, event: any) {
    let editField;
    try {
      editField = event.target.textContent;    
    }
    catch(err){
      console.error(err);
      editField = event;    
    }
    let payload = {};
    payload[property] = editField;
    this.rest_api.update_cliente(this.clienteList[id].id, payload).then(response => {
      this.clienteList[id] = <Cliente>response;
      this.toaster.success('Campo actualizado correctamente');
    }, error => {
      console.log(error);
      this.toaster.error('Ocurrió un error al actualizar el campo');
    })
    .catch(error => {
      console.log(error);
      this.toaster.error('Ocurrió un error al actualizar el campo');
    });
  }

  disable(id: any) {
    
  }

  add() {
    Swal.fire({
      title: 'Seleccione una organización a la que pertenece el cliente',
      input: 'select',
      inputOptions: this.orgs,
      inputPlaceholder: 'Seleccione una organización',
      showCancelButton: true,
      cancelButtonColor:'#d33'
    }).then(result =>{
      if (result && result.value){
        let cliente:Cliente = {
          name: 'NUEVO CLIENTE',
          phone: '4421233333',
          email: 'NUEVOCLIENTE@NUEVO.COM',
          org: result.value.toString(),
        }
        this.rest_api.create_init_cliente(cliente).then(response => {
          this.clienteList.unshift(<Cliente>response);
          this.toaster.success('Cliente agregado exitosamente');
        }, error => {
          console.log(error);
          this.toaster.error('Ocurrió un error al agregar el cliente');
        })
        .catch(error => {
          console.log(error);
          this.toaster.error('Ocurrió un error al agregar el cliente');
        });
        
      }
    });     
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent;
  }

}


export interface Cliente  {
  name:string;
  phone:string;
  email:string;
  org:string;
  plan?:string;
  suscription?:string;
  url?:string;
  id?:number;
  cliente_suscripciones?:any;
  payment_sources?:any
}



export interface DialogData {
  cliente_id:number;
}

@Component({
  selector: 'add-suscription-dialog',
  templateUrl: 'add.suscription.dialog.component.html',
})
export class AddSuscriptionDialogComponent {
  planesList:Array<any> = []; 
  selectedPlan:any;
  form:FormGroup;
  plan_name = '';
  isLinear = '';
  plan_id = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<AddSuscriptionDialogComponent>,
  private rest_api: RestApiPaymentProvider,
  private toaster: ToastrService
  ) {
    this.form = new FormGroup({
      plan : new FormControl(null, [Validators.required]),
      card : new FormControl(null, [Validators.required]),
    });

    this.rest_api.get_planes().then(response => {
      this.planesList = <any[]>response;
      this.planesList = this.planesList.map(plan => {
        return {
          name: plan.name,
          value: plan.clave,
          active: plan.active,
          id: plan.id,
          users_number: plan.users_number,
          amount: plan.amount
        }
      });
    });

  }
  cancel() {
    this.dialogRef.close();
  }

  changePlan(){
    this.planesList.forEach(plan => {
      if (this.form.get('plan').value == plan.value){
        this.plan_name = plan.name;
        this.plan_id = plan.id;
      }
    });
    this.rest_api.checkSuscription(this.data['cliente_id']).then(response => {
      if (Array.isArray(response) && response.length == 0){}
      else{
        Swal.fire(
          'Ya existe una suscripción activa para este cliente con el plan "' + response[0]['plan_clave'] + '"',
          "Si continúa, la nueva suscripción tendrá efecto después del último pago de la suscripción actual",
          'warning'
        );
      }
    });
    
  }


  save(){
    if (this.form.invalid){
      this.toaster.error('Llene todos los campos');
      return;
    }
    else{
      this.form.value['plan_name'] = this.plan_name;
      this.form.value['plan_id'] = this.plan_id;
      this.dialogRef.close(this.form.value);
    }
  }
}




export interface DialogData {
  models: any;
  perfil: string;
}

@Component({
  selector: 'terminal-dialog',
  templateUrl: '../terminal.dialog.component.html',
})
export class AddCardDialogComponent {
  form: FormGroup;
  months = [  
    {
      "name": "01",
      "value": "01"
    },{
      "name": "02",
      "value": "02"
    },{
      "name": "03",
      "value": "03"
    },{
      "name": "04",
      "value": "04"
    },{
      "name": "05",
      "value": "05"
    },{
      "name": "06",
      "value": "06"
    },{
      "name": "07",
      "value": "07"
    },{
      "name": "08",
      "value": "08"
    },{
      "name": "09",
      "value": "09"
    },{
      "name": "10",
      "value": "10"
    },{
      "name": "11",
      "value": "11"
    },{
      "name": "12",
      "value": "12"
    }
  ];
  years = [
    {
      "name": "2021",
      "value": "2021"
    },{
      "name": "2022",
      "value": "2022"
    },{
      "name": "2023",
      "value": "2023"
    },{
      "name": "2024",
      "value": "2024"
    },{
      "name": "2025",
      "value": "2025"
    },{
      "name": "2026",
      "value": "2026"
    },{
      "name": "2027",
      "value": "2027"
    },{
      "name": "2028",
      "value": "2028"
    },
  ]
  public numberNoValido = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<AddCardDialogComponent>,
  private toaster: ToastrService
  ) {
    //Conekta.setPublicKey("key_C86YJU76JB9SBYaxsae3JiA"); // Pruebas
    Conekta.setPublicKey("key_eWnXionD24EGMryFFCUr3rw"); // Produccion
    Conekta.setLanguage("es"); 
    this.form = new FormGroup({
      number: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
    });
  }

  save(){
    console.log(this.form.value);
    if (this.form.invalid){
      this.toaster.error('Llene todos los campos');
      return
    }
    
    if (!Conekta.card.validateExpirationDate(this.form.get('month').value, this.form.get('year').value)){
      this.toaster.error('La fecha de expiración de la tarjeta es inválida')
      return;
    }

    if (!Conekta.card.validateNumber(this.form.get('number').value)){
      this.toaster.error('El número de tarjeta es inválido')
      return;
    }
    if (!Conekta.card.validateExpirationDate(this.form.get('month').value, this.form.get('year').value)){
      this.toaster.error('La fecha de vigencia de tarjeta es inválida')
      return;
    }
    if (!Conekta.card.validateCVC(this.form.get('cvv').value)){
      this.toaster.error('El código cvc de tarjeta es inválido')
      return;
    }
    var tokenParams = {
      "card": {
          "number": this.form.get('number').value,
          "name": this.form.get('name').value,
          "exp_year": this.form.get('year').value,
          "exp_month": this.form.get('month').value,
          "cvc": this.form.get('cvv').value,
      }
    };
    Conekta.Token.create(tokenParams, response => {
      this.saveCard(response);
    }, error => {
      console.log(error);
      try{
        this.toaster.error(error['message_to_purchaser']);  
      } catch(err){
        this.toaster.error('Ah ocurrido un error');
      }      
    });
  }

  saveCard(card){
    this.dialogRef.close(card);
  }

  cancel() {
    this.dialogRef.close();
  }
}
