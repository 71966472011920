import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { SettingsService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-perfiles-detail',
  templateUrl: './perfiles-detail.component.html',
  styles: []
})
export class PerfilesDetailComponent implements OnInit {
  form:FormGroup;
  title:string = 'Crear';
  aplications:any =[];
  types:any =[];
  app:any = [];
  profile:any = {};
  perfil_usado = false;

  inactive_permissions = [
    'Campañas',
    'Conciliar recibos',
    'Despagar recibos',
    'Administrar notificaciones',
    'Eliminar recibos',
    'Cambiar refrenciador en pólizas',
    'Pagar y prorrogar',
    'Registrar endosos',
    'Administrar OTs',
    'Desconciliación de recibos',
    'Desliquidar recibos',
    'Liquidar recibos',
    'Eliminar OTs',
    'Administrar pólizas',
    'Administrar referenciadores',
    'Eliminar pólizas',
    'Rehabilitar pólizas',
    'Administrar contratantes y grupos',
    'Eliminar endosos',
    'Eliminar fianzas',
    'Pagar a referenciadores',
    'Cancelar OTs',
    'Administrar fianzas',
    'Cancelar pólizas',
    'Eliminar grupos',
    'Referenciador no obligatorio'
  ]

  constructor(
    private _router:Router,
    private rest_api: RestApiProvider,
    private _validators:ValidadoresService,
    private _settings: SettingsService,
    private _toastr: ToastrService,
    private _activateRoute: ActivatedRoute
  ) { 
    // creamos el form con los campos fijos y sus validadores correspondientes el primer argumento es el valor por defecto, 
    // el segundo el validador sincrono y el tercero el validador asincrono
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required], this._validators.existeProfilename.bind(this)),
      application: new FormControl(0, [Validators.required, this._validators.noSelectedOption]),
      type_profile: new FormControl(0, [Validators.required, this._validators.noSelectedOption]),
      permissions: new FormArray([])
    });
    this.rest_api.obtener_aplicaciones().then(response=>{
      this.aplications = response;
      this.aplications.unshift({'url':0, 'name': 'Seleccione una opcion'})
      this._activateRoute.params.subscribe( params => {
        if (params['id'] != 'nuevo'){
          this.rest_api.obtener_perfil(params['id']).then(response => {
            this.profile = response;
            // fijamos el response al form para llenar de info
            this.form.reset(response);
            this.form.get('application').disable();


            this.rest_api.check_profile(this.profile.id).then(response => {
              if (response){
                this.form.get('type_profile').disable();
              }
            })

            

            this.aplications.some(app => {
              // lenamos los controles del form con los permisos del perfil
              if (app.url === this.profile.application) {
                this.app = app.application_models;
                this.app.forEach(model => {
                  let auxArray = new FormArray([]);
                  model.permissions.forEach(permission => {
                    // llenamos los controles de los permisos con los valores de los permisos guardados
                    this.profile.profile_permissions.some(permiso_del_perfil => {
                      if(permiso_del_perfil.permission == permission.url){
                        auxArray.push(new FormGroup({
                          name: new FormControl(permission.name),
                          id: new FormControl(permiso_del_perfil.url),
                          checked: new FormControl(permiso_del_perfil.checked)
                      })
                    )
                      }
                    });

                  });
                  // metemos los controles de los permisos en el form
                  (this.form.controls.permissions as FormArray).push(auxArray);
                });
              }
              else {
                this.app = {};
              };
            });

            if (response['type_profile'] == 2) {
              this.form.get('permissions')['controls'].forEach((permision) => {
                permision.controls.forEach(p => {
                  if ( this.inactive_permissions.includes(p.value['name']) ){
                    p.controls['checked'].setValue(false);
                    (p.controls['checked'] as FormControl).disable();
                  }
                })
              }); 
            }




          });
        }
      });
    });
    this._settings.cargarAjustes();

    this.types = [
      {'id': 0, 'name': 'Seleccione una opcion', 'active':true},
      {'id': 1, 'name': 'Operativo', 'active':true},
      {'id': 2, 'name': 'Consulta', 'active':true}
    ];
  }

  ngOnInit() {
  }


  changeApp(item:any){
    // cuando cambiamos de aplicacion creamos los controles dinamicos con los permisos de la aplicacion
    // (this.form.controls.permissions as FormArray) = new FormArray([]);
    this.aplications.some(app => {
      if (app.url === item.target.value) {
        this.app = app.application_models;
        if (this.app.length > 0){
          this.app.forEach(model => {
            let auxArray = new FormArray([]);
            model.permissions.forEach(permission => {
              auxArray.push(new FormGroup({
                    name: new FormControl(permission.name),
                    id: new FormControl(permission.id),
                    checked: new FormControl(true)
                })
              )
            });
            (this.form.controls.permissions as FormArray).push(auxArray);
          });
        } else {
          // (this.form.controls.permissions as FormArray) = new FormArray([]);
          this.form.removeControl('permissions');
          this.form.addControl('permissions',  new FormArray([]));
        }
      }
      else {
        this.app = {};
      };
    });
  }

  changeType(item:any){
    if (item.target.value == 2) {
      this.form.get('permissions')['controls'].forEach((permision) => {
        permision.controls.forEach(p => {
          if ( this.inactive_permissions.includes(p.value['name']) ){
            p.controls['checked'].setValue(false);
            (p.controls['checked'] as FormControl).disable();
          }
        });
      }); 
    } else {
      this.form.get('permissions')['controls'].forEach((permision) => {
        permision.controls.forEach(p => {
          p.controls['checked'].setValue(true);
            (p.controls['checked'] as FormControl).enable();
        })
      }); 
    }
  }

  save() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      return;
    }
    if (this._settings.ajustes.org && this._settings.ajustes.org.id){
      this.form.value['org'] = this._settings.ajustes.org.id;
    } else {
      this._toastr.error('Los perfiles solo pueden crearse desde una organizacion!', 'Error');
      this._router.navigate(['/organizaciones']);
      return;
    }
    

    if (this.profile && this.profile.id) {
        Swal.fire({
          title: '¿Esta seguro?',
          text: "Al editar un perfil todos los usuarios que tengan este perfil se veran afectados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '¡Si, actualizar!',
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {

            this.form.get('permissions')['controls'].forEach((permision) => {
              permision.controls.forEach(p => {
                (p.controls['checked'] as FormControl).enable();
              })
            }); 


            let promises = [];
            this.rest_api.parchar_url(this.profile.url, this.form.value).then(() => {
              this.form.value.permissions.forEach(model => {
                model.forEach(permission_to_patch => {
                  promises.push(
                    this.rest_api.parchar_url(permission_to_patch.id, {'checked': permission_to_patch.checked})
                  );
                });
                Promise.all(promises).then(()=>{
                  this._toastr.success('Perfil actualizado exitosamente');
                  this._router.navigate(['/perfiles']);
                });
              });
            }, error => {
              console.error(error);
            })
            .catch(err => {
              console.error(err);
            });
          }
        });
    }
    else{
      this.form.get('permissions')['controls'].forEach((permision) => {
        permision.controls.forEach(p => {
          (p.controls['checked'] as FormControl).enable();
        })
      }); 
      this.rest_api.crear_perfil(this.form.value).then(response => {
        this._toastr.success('Perfil creado exitosamente');
        this._router.navigate(['/perfiles']);
      }, error => {
        console.error(error);
      })
      .catch(err => {
        console.error(err);
      });
    }
  }
  
  cancel(){
    this._router.navigate(['/perfiles']);
  }

  // validaciones para los controles del form

  get nameNoValido(){
    return this.form.get('name').invalid && this.form.get('name').touched && !this.form.get('name').hasError('exists');
  }

  get applicationNoValido(){
    return this.form.get('application').invalid && this.form.get('application').touched;
  }

}
