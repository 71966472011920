import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { SettingsService } from 'src/app/services/service.index';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mc',
  templateUrl: './mc.component.html',
  styleUrls: ['./mc.component.css']
})
export class McComponent implements OnInit {
  multicotizadores: any = [];
  public form: FormGroup;
  relations: any = [];
  add_relation: boolean = false;
  canCreate = true;
  loading = false;
  title = '';
  constructor(
    private restApi: RestApiProvider,
    private restPaymentApi: RestApiPaymentProvider,
    private router: Router,
    private settings: SettingsService,
    private toaster: ToastrService
  ) {
    this.form = new FormGroup({
      caratula: new FormControl(null, Validators.required),
      username: new FormControl(null, Validators.required),
      schema_desc: new FormControl(null,Validators.required),

   })
  }

  ngOnInit() {
    this.settings.cargarAjustes();
    let org = this.settings.ajustes.org;
    if (this.settings.ajustes.org && this.settings.ajustes.org.id){
      if(this.settings.ajustes.org.billiable){
        this.restPaymentApi.getSuscriptionsByClient(this.settings.ajustes.org.id).then(response => {
            let permission = false;
            try{
                let apps = response['plan']['apps'];
                apps.some(app => {
                    if (app['name'] === "Multicotizador"){
                      permission = true;
                    }
                });
            } catch (err){}
            if (!permission){
                this.toaster.warning('Tu suscripción no incluye esta aplicación');
                this.canCreate = false;
                this.router.navigate(['/usuarios']);
            } else {
              this.restApi.get_mcs().then(response => {
                this.multicotizadores = JSON.parse(response.toString());
              });
              if (this.settings.amIUser()){
                this.canCreate = false;
              }
            }
        }, () => {
            this.toaster.warning('Tu suscripción no incluye esta aplicación');
            this.canCreate = false;
        });
      } else {
        this.canCreate = false;
        org.info_org.forEach(app => {
          if (app.name == 'Multicotizador' && app.is_active == true){
            console.log('TIene la aplicacion activada');
            
            this.canCreate = true;
          }
        });
        this.restApi.get_mcs().then(response => {
          this.multicotizadores = JSON.parse(response.toString());
        });
        this.restApi.get_usermc().then(response => {
          this.relations = response;
        });
        if (this.settings.amIUser()){
          this.canCreate = false;
        }
      }
    } else {
      this.canCreate = false;
    }
  }

  get usernameNoValido() {
    return this.form.get('username').invalid && this.form.get('username').touched;
  }
  get caratulaNoValido() {
    return this.form.get('caratula').invalid && this.form.get('caratula').touched;
  }
  get schemaNoValido() {
    return this.form.get('schema_desc').invalid && this.form.get('schema_desc').touched;
  }
  usarMc(mc: any) {
    this.router.navigate(['/mc-details/', mc.schema_name]);
  }

  detalleMc(mc:any) {    
    this.router.navigate(['/mc-details/', mc.schema_name]);
  }
  nuevoMc() {    
    this.router.navigate(['/mc-details/nuevo']);
  }

  save(){
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      window.scrollTo(0, 0);
      return;
    } else { 
      this.restApi.create_usermc(this.form.value).then(() => {
        this.toaster.success('Relacion Creada exitosamente');
        this.add_relation = false;
        this.restApi.get_usermc().then(response => {
          this.relations = response;
        });
        this.router.navigate(['/mc']);
      }).catch(err => {
        this.toaster.error('Error al crear la relacion');
      });
    }
  }

  delete(id:any){
    this.restApi.delete_usermc(id).then(() => {
      this.toaster.success('Relacion Eliminada');
      this.restApi.get_usermc().then(response => {
        this.relations = response;
      });
    }).catch(err => {
      this.toaster.error('Error al eliminar', err);
    });
    

  }

}
