import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
      public router: Router, 
      private settings: SettingsService,
      private toaster: ToastrService
    ){
    settings.cargarAjustes();

  }
  canActivate(){
    if (this.settings.ajustes.token == null){
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
