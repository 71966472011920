import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsService, SharedService, SidebarService } from './service.index';
import { RestApiProvider } from './shared/api.service';
import { LoginGuard } from './guards/admin.guard';
import { DataService } from './shared/dataShare.service';
import { RestApiPaymentProvider } from './shared/api.payment.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RestApiProvider,
    RestApiPaymentProvider,
    SettingsService,
    SharedService,
    SidebarService,
    LoginGuard,
    DataService
  ]
})
export class ServicesModule { }
