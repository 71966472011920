import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RestApiProvider } from '../services/shared/api.service';
import { SettingsService } from '../services/service.index';
import { DataService } from '../services/shared/dataShare.service';
import { ToastrService } from 'ngx-toastr';


declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';

  constructor(
      public router: Router, 
      private api_rest: RestApiProvider, 
      private setings_service: SettingsService,
      private _dataShare:DataService,
      private toaster: ToastrService
    ) { }

  ngOnInit() {
    init_plugins();
  }

  forgotPassword(){
    this.router.navigate(['/forgot-password/', 0]);
  }

  ingresar(){
    this.username = this.username.toLowerCase()
    this.api_rest.login(this.username, this.password).then(response => {
      if (response['token']){
        this.setings_service.ajustes.token = response['token'];
        if (!response['is_superuser']){
          this.setings_service.ajustes.org['billiable'] = response['org']['billiable'];
          this.setings_service.ajustes.org['alias'] = response['org']['alias'];
          this.setings_service.ajustes.org['id'] = response['org']['id'];
          this.setings_service.ajustes.org['orgname'] = response['org']['alias'];
          this.setings_service.ajustes.org['crear_usuarios_app'] = response['org']['crear_usuarios_app'];
        }
        this.setings_service.ajustes.user.first_name = response['first_name'];
        this.setings_service.ajustes.user.last_name = response['last_name'];
        this.setings_service.ajustes.user.email = response['email'];
        this.setings_service.ajustes.user.is_superuser = response['is_superuser'];
        this.setings_service.ajustes.user.role = response['role'];
        this.setings_service.ajustes.user.is_staff = response['is_staff'];
        this.setings_service.ajustes.user.id = response['user_id'];
        this.setings_service.ajustes.user.userinfo = response['userinfo'];
        this.setings_service.ajustes.user.tutorial = response['tutorial'] ? response['tutorial'] : false;
        this.setings_service.ajustes.suscripcion = response['suscripcion'];
        
        if(response['avatar']){
          this.setings_service.ajustes.user.avatar = response['avatar']['avatar'];
        }
        else{
          this.setings_service.ajustes.user.avatar = null;
        }
        this.setings_service.guardarAjustes();
        this._dataShare.changeMessage({'message':'loged_in'});
        this.router.navigate(['/dashboard']);
      }
    }, error => {
      try{
        this.toaster.error('Ha ocurrido un error, revise bien su información');
      } catch(err){
        this.toaster.error('Ha ocurrido un error, revise bien su información');
      }
    })
    .catch(err => {
      try{
        this.toaster.error('Ha ocurrido un error, revise bien su información');
      } catch(err){
        this.toaster.error('Ha ocurrido un error, revise bien su información');
      }
    });
  }
}
