import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { DataService } from 'src/app/services/shared/dataShare.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-organizaciones',
  templateUrl: './organizaciones.component.html',
  styleUrls: ['./organizaciones.component.css']
})
export class OrganizacionesComponent implements OnInit {
  organizaciones = [];
  page = 0;
  total = 0;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  turnOffPagination = false;
  styleBtnActive;
  styleBtnInactive;
  showUserInactive;
  button_active;
  showUserActive;

  constructor(
    private rest_api:RestApiProvider,
    private _router:Router,
    private _settings: SettingsService,
    private _dataShare: DataService
    
  ) { 
  }

  ngOnInit() {
    this.turnOffPagination = false;
    this.button_active = 1;
    this.myControl.setValue('');
    this.rest_api.get_organizaciones_active(1,true).then(response=>{
      this.organizaciones = response['results'];
      this.total =response['count'];
      this.page = 1;

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    });
    this.styleBtnActive = {
      'background-color': '#E2E3E3',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
    this.styleBtnInactive = {
      'background-color': '#ffffff',
      'border': 'none',
      'border-top-right-radius': '4px',
      'border-top-left-radius': '4px',
      'cursor': 'pointer',
      'font-weight': '700',
      'padding': '6px 24px'
    }
  }
  showOrgs(value){
    if(value == 1){
      this.button_active = value;
      this.styleBtnActive = {
        'background-color': '#E2E3E3',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnInactive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      // Agregar llamda de solo activos
      this.rest_api.get_organizaciones_active(1,true).then(response=>{
        this.organizaciones = response['results'];
        // this.options = this.organizaciones.map(org => org.urlname);
        this.total =response['count'];
        this.page = 1;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }); 
    }else if(value==2){
      this.button_active = value;
      this.styleBtnInactive = {
        'background-color': '#E2E3E3',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.styleBtnActive = {
        'background-color': '#ffffff',
        'border': 'none',
        'border-top-right-radius': '4px',
        'border-top-left-radius': '4px',
        'cursor': 'pointer',
        'font-weight': '700',
        'padding': '6px 24px'
      }
      this.showUserActive = false;
      this.showUserInactive = true;      
      this.rest_api.get_organizaciones_active(1,false).then(response=>{
        this.organizaciones = response['results'];
        // this.options = this.organizaciones.map(org => org.urlname);
        this.total =response['count'];
        this.page = 1;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      }); 
    }
  }
  SearchkeyUp(value){
    this.rest_api.get_organizacion_by_urlname(this.myControl.value).then(response=>{
      this.organizaciones = response as Array<[]>;
      this.turnOffPagination = true;
    }, error => console.log('urlname no econtrada'))
    .catch( error =>console.log('urlname no econtrada'));    
  }
  
  onSelected(value:any) {
    console.log(value);
    this.rest_api.get_organizacion_by_urlname(value).then(response=>{
      this.organizaciones = response as Array<[]>;
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  
  usarOrganizacion(org:any){
    this._settings.ajustes.org = org;
    this._settings.guardarAjustes();
    this._dataShare.changeMessage(org);
    this._router.navigate(['/dashboard']);
    window.location.reload();
  }


  detalleOrganizacion(org:any){
    if (org){
      this._router.navigate(['/organizaciones-detalle/', org.id]);   
    }
    else{
      this._router.navigate(['/organizaciones-detalle', 'nuevo']);
    }

    
  }

  loadPage(event){
    if((parseInt(this.button_active) !=1) && (parseInt(this.button_active) !=2)){
      this.rest_api.get_organizaciones(event).then(response=>{
        this.organizaciones = response['results'];
        this.total =response['count'];
        this.page = event;
      });      
    }else{
      if(parseInt(this.button_active) ==1){        
        let acti = true
        this.rest_api.get_organizaciones_active(event,acti).then(response=>{
          this.organizaciones = response['results'];
          this.total =response['count'];
          this.page = 1;

          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        }); 
      }else{
        let acti =false
        this.rest_api.get_organizaciones_active(event,acti).then(response=>{
          this.organizaciones = response['results'];
          // this.options = this.organizaciones.map(org => org.urlname);
          this.total =response['count'];
          this.page = 1;

          this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        }); 

      }
      // Agregar llamda de solo activos
    }
  }

}
