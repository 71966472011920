import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';
import { RegisterComponent } from './login/register.component';

const appRoutes: Routes = [
    { path: 'activate/:id', component: RegisterComponent},
    { path: 'activate-user/:code', component: RegisterComponent},
    { path: 'forgot-password/:frgtpsswd', component: RegisterComponent},
    { path: 'login', component: LoginComponent },
    { path: '**', component: NopagefoundComponent }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, {useHash: true});
