import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  ajustes: Ajustes = {
    temaUrl: 'assets/css/colors/defaults.css',
    tema: 'default',
    token:'',
    org: {},
    role:1,
    user:{
      first_name : '',
      last_name : '',
      email : '',
      is_superuser : '',
      is_staff : '',
      id:0,
      avatar: '',
      role:1,
      userinfo:{},
      tutorial: false
    },
    suscripcion: {},
  }

  constructor(@Inject(DOCUMENT) private _document,) { 
    this.cargarAjustes();
  }

  guardarAjustes() {
    localStorage.setItem('ajustes', JSON.stringify(this.ajustes));
  }

  cargarAjustes() {
    return new Promise((resolve,reject)=> {
      if (localStorage.getItem('ajustes')) {
        this.ajustes = JSON.parse(localStorage.getItem('ajustes'));
        this.aplicarTema(this.ajustes.tema);
      } else{
        this.aplicarTema(this.ajustes.tema);
      }
      resolve(true);
    });
  }

  getId() {
    return this.ajustes.user.id;
  }

  amIUser() {
    //return this.ajustes.user && !this.ajustes.user.is_staff && !this.ajustes.user.is_superuser;
    return this.ajustes.user && !this.ajustes.user.is_superuser && this.ajustes.user.role!=0;
  }

  amIStaff() {
    return this.ajustes.user && this.ajustes.user.role===0 && !this.ajustes.user.is_superuser;
  }

  amISuperInOrg() {
    return this.ajustes.user && this.ajustes.user.is_superuser && this.ajustes.org && this.ajustes.org.id;
  }

  amISuperOutOrg() {
    return this.ajustes.user && this.ajustes.user.is_superuser && !(this.ajustes.org && this.ajustes.org.id);
  }

  amIRoleAdmin() {
   return this.ajustes.user && this.ajustes.user.userinfo && this.ajustes.user.userinfo['role']===0;
  }

  amIRoleSubAdmin() {
    return this.ajustes.user && this.ajustes.user.userinfo && this.ajustes.user.userinfo['role']===1;
  }

  CanICrearUsuariosApp() {
    return this.ajustes.org && this.ajustes.org.crear_usuarios_app ;
  }

  borrarOrg() {
    this.ajustes.org = {};
    this.guardarAjustes();
  }

  borrarAjustes() {
    this.ajustes.token = null;
    this.ajustes.org = {};
    this.ajustes.user = {};
    this.ajustes.suscripcion = {};
    this.guardarAjustes();
  }

  aplicarTema(tema: string){
    let url = `assets/css/colors/${ tema }.css`;
    this._document.getElementById('tema').setAttribute('href', url);

    this.ajustes.temaUrl = url;
    this.ajustes.tema = tema;
    this.guardarAjustes();
  }
}

interface Ajustes {
  temaUrl: string,
  tema: string,
  token:string,
  org:any,
  suscripcion?:any,
  role:number, 
  user:{
    first_name?: string;
    last_name?: string;
    email?: string;
    is_superuser?: string;
    is_staff?: string;
    id?:number;
    avatar?:string;
    role?:number;
    userinfo?:{};
    tutorial?:boolean;
  }
}