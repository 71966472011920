import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray, FormArrayName } from '@angular/forms';
import { SettingsService } from 'src/app/services/service.index';
import { ValidadoresService } from 'src/app/services/shared/validators';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-org-detail',
  templateUrl: './org-detail.component.html',
})
export class OrgDetailComponent implements OnInit {
  form: FormGroup;
  form3: FormGroup;//Edicion por recibo
  org: any = null;
  org_url: any = null;
  showReceiptEdit=false;
  rec_edit = {};
  index_edit = '';
  title = 'Agregar';
  apps:any =[];
  addMData = false
  addCData= false
  event:any = [];
  org_files: any = [];
  receipts_org: any=[]
  conducto_de_pago_list=[]
  archivos:any = [
    "INE",
    "Comprobante de domicilio",
    "Constancia",
    "Acta Constitutiva",
  ]
    
  info_org_base = new FormArray([
    new FormGroup({
      name: new FormControl(''),
      is_active: new FormControl(false)
    })
  ]);

  sort(colName, registros) {
    registros.sort((a, b) => a[colName] > b[colName] ? 1 : a[colName] < b[colName] ? -1 : 0)
  }
  constructor(
    private rest_api:RestApiProvider,
    private _router:Router,
    private _settings: SettingsService,
    private _validators: ValidadoresService,
    private _route: ActivatedRoute
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      // tslint:disable-next-line: max-line-length
      urlname: new FormControl(null, [Validators.required, Validators.minLength(3), this._validators.noWhitespaceValidator], this._validators.existeUrlname.bind(this)),
      alias: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required, this._validators.noDiezDigitosPhone]),
      phone_mensajeria: new FormControl(null),
      auth_token: new FormControl(null),
      account_sid: new FormControl(null),
      email: new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
      address: new FormControl(null, [Validators.required]),
      billiable: new  FormControl(0), 
      cobranza_pendiente: new  FormControl(0), 
      crear_usuarios_app: new  FormControl(0), 
      dataMensajeria: new  FormControl(false), 
      dataCobranza: new  FormControl(0), 
      observations: new  FormControl(0), 
      info_org:  new FormArray([]),
      phone_gestor: new FormControl(null),
      email_gestor: new FormControl(null),
      users_payed_op: new FormControl(null),
      users_payed_con: new FormControl(null),
      gestor_cobranza: new FormControl(null),
      price_users_op: new FormControl(null),
      price_users_con: new FormControl(null),
      rfc_gestor: new FormControl(null),
      num_client: new FormControl(null),
      conducto_de_pago: new FormControl(0),
    });   
    this.form3 = new FormGroup({
      recibo_numero: new FormControl(null, Validators.required),
      monto: new FormControl(null, Validators.required),
      monto_total: new FormControl(null, Validators.required),
      forma_pago: new FormControl(null, Validators.required),
      fecha_inicio: new FormControl(null, Validators.required),
      fecha_fin: new FormControl(null, Validators.required),
      vencimiento: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required)
    }); 
    this.conducto_de_pago_list = [
      {id: 0, name: "No domiciliado"},
      {id: 1, name: "Domiciliado"},
    ]
    this._route.params.subscribe( params => {
      if (params && params['id'] && params['id'] !== 'nuevo' && typeof Number(params['id']) === 'number') {
        let array_ = new FormArray([]); 
        this.rest_api.get_organizacion(params['id']).then(response => {
          this.org = params['id'];
          this.title = 'Editar';
          this.org_url = response['url'];
          this.form.reset(response);
          this.form.get('urlname').clearValidators();
          this.form.get('urlname').clearAsyncValidators();
          this.form.get('urlname').updateValueAndValidity();
          this.form.get('urlname').disable();
          // recibos-org     
          this.rest_api.getReceiptsOrg(params['id']).then(response=>{
            this.receipts_org = response['data']
            if(this.receipts_org){
              this.sort('recibo_numero',this.receipts_org)
            }
          }); 
          if(response['gestor_cobranza']){
            this.form.get('dataCobranza').setValue(true);
            this.addCData=true
          }
          if(response['phone_mensajeria']){
            this.form.get('dataMensajeria').setValue(true);
            this.addMData=true
          }
          this.org_files = response['org_files'] ? response['org_files'] : []
          try{
            this.org_files.forEach(arco => {  
              this.archivos.forEach(archivoe => {         
                if (arco.nombre == archivoe){
                  // this.archivos.splice(archivoe, 1);
                  this.archivos.splice(this.archivos.indexOf(archivoe), 1);
                }
              })
            });
          }
          catch (err){console.log('err',err)}

          this.rest_api.obtener_aplicaciones().then(aplicaciones => {
            this.apps= aplicaciones;
            this.apps.forEach(element => { 
              let is_active = false;
              response['info_org'].forEach(app_asigned => {                
                if (app_asigned['name'] == element.name){
                  is_active = app_asigned.is_active;
                }                
              });
              array_.push(new FormGroup({
                id: new FormControl(element.id), 
                name: new FormControl(element.name),
                is_active: new FormControl(is_active),
              }))
            });
            this.form.controls.info_org = array_;
          });
        })
      } else {
        let array_ = new FormArray([]); 

        this.rest_api.obtener_aplicaciones().then(
          response => {
            this.apps=response
            this.apps.forEach(element => { 
              array_.push(new FormGroup({
                id: new FormControl(element.id), 
                name: new FormControl(element.name),
                is_active: new FormControl(false),
               }))
            });
            this.form.controls.info_org =array_;
          }
        )
      }
    })
  }
  ngOnInit() {
    this.archivos.map(archivo => {
      this.form.addControl(archivo,new FormGroup({
        file: new FormControl()
      }))
    })
  }

  detalleRecibos(i,recibo) {
    if (recibo) {
      this.form3.enable();
      this.rec_edit = recibo
      this.index_edit = i
      this.form3.get('monto').setValue(recibo.monto);
      this.form3.get('monto_total').setValue(recibo.monto_total);
      this.form3.get('fecha_inicio').setValue(recibo.fecha_inicio);
      this.form3.get('fecha_fin').setValue(recibo.fecha_fin);
      this.form3.get('vencimiento').setValue(recibo.vencimiento);
      this.form3.get('forma_pago').setValue(recibo.forma_pago_display);
      this.form3.get('status').setValue(recibo.status);
      this.form3.get('recibo_numero').setValue(recibo.recibo_numero);
      this.showReceiptEdit=true
    } else {
      this.showReceiptEdit=false
    }
  }
  addMensajeria(){
    this.addMData = this.form.value['dataMensajeria'] ? this.form.value['dataMensajeria'] : false    
  }
  addCobranza(){
    this.addCData = this.form.value['dataCobranza'] ? this.form.value['dataCobranza'] : false    
  }
  deleteFileOrg(file,container) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta operación es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar.',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rest_api.eliminar_org_file(file['url']).then(response => {
          Swal.fire(
            'Eliminado!',
            'El archivo ha sido eliminado exitosamente',
            'success'
          );
          this.org_files.splice(this.org_files.indexOf(file), 1);
          this.archivos.push(file.nombre)
        });
      }
    })
  }
  onFileChange(nombre, event, index){
    let a = {}
    a[nombre] = nombre
    let reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        (this.form.get(nombre) as FormGroup).get('file').setValue(file);
      };
    }
    if(event.target.files[0].name){
      this.event[index] = event.target.files[0].name;
    }
  }
  uploadFiles(org){  
    let totalfiles = this.archivos ? this.archivos.length : 0
    let countfiles = 0
    Object.keys(this.form.controls).forEach(key => {
      const formControl = (this.form.controls[key] as FormGroup).get('file');
      if (formControl && (this.form.controls[key] as FormGroup).get('file').value ){
        let formData:FormData = new FormData();
        formData.append('arch', (this.form.controls[key] as FormGroup).get('file').value);
        formData.append('nombre', key);
        formData.append('owner', org['url']);
        this.rest_api.uploadFile(org['id'], formData,org['urlname']).then(response => {
          countfiles = countfiles+1       
          this.org_files.push(response);          
          try{
            this.org_files.forEach(archivoe => {
              if (this.archivos.includes(archivoe.nombre)){
                const index = this.archivos.indexOf(archivoe.nombre);
                if (index !== -1){
                  this.archivos.splice(index, 1);
                }
              }
            });
          }
          catch (err){console.log('eror',err)}   
        });
      }else{
        this._router.navigate(['/organizaciones']);
      }
    });
    if(totalfiles == countfiles){
      this._router.navigate(['/organizaciones']);
    }
  }

  save() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      });
      return;
    }
    else{
      if (this.org) {
        this.form.value['info_org'] = (this.form.get('info_org') as FormArray).value;
        if (this.form.value['billiable'] == true){
          this._settings.ajustes.org['billiable'] = true;
          this._settings.guardarAjustes();
        }
        this.rest_api.actualizar_organizacion(this.org_url, this.form.value).then(response => {
          if(this.archivos.length >=0){
            this.uploadFiles(response);
          }else{
            this._router.navigate(['/organizaciones']);
          }
        });
      } else {
        this.form.get('urlname').setValue(this.form.get('urlname').value.toLowerCase());
        this.rest_api.crear_organizacion(this.form.value).then(response=>{
          if(this.archivos.length>=0){
            this.uploadFiles(response);
          }else{
            this._router.navigate(['/organizaciones']);
          }
        });
      }
    }
  }

  cancel() {
    this._router.navigate(['/organizaciones']);
  }


  get nameNoValido() {
    return this.form.get('name').invalid && this.form.get('name').touched;
  }
  get urlnameNoValido() {
    // tslint:disable-next-line: max-line-length
    return this.form.get('urlname').invalid && this.form.get('urlname').touched && !this.form.get('urlname').hasError('whitespace') && !this.form.get('urlname').hasError('existe');
  }
  get aliasNoValido() {
    return this.form.get('alias').invalid && this.form.get('alias').touched;
  }
  get phoneNoValido() {
    return this.form.get('phone').invalid && this.form.get('phone').touched;
  }
  get phoneNoValidoSMS() {
    return this.form.get('phone_mensajeria').invalid && this.form.get('phone_mensajeria').touched;
  }
  get emailNoValido() {
    return this.form.get('email').invalid && this.form.get('email').touched;
  }
  get addressNoValido() {
    return this.form.get('address').invalid && this.form.get('address').touched;
  }
}


