import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ExcelService } from 'src/app/services/shared/excelService';
import {MatTableDataSource} from '@angular/material/table';
import * as XLSX from 'xlsx';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { filter, map } from 'rxjs/operators';
import { isNumber } from 'util';
import Swal from 'sweetalert2';
import { SettingsService } from 'src/app/services/service.index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


type AOA = any[][];


@Component({
  selector: 'app-smtp',
  templateUrl: './smtp.component.html',
  styleUrls: ['./smtp.component.css']
})
export class SMTPComponent implements OnInit {
  form:FormGroup;
  form_organizational: FormGroup;
  data:any;
  aux_array:any;
  files:any;
  generalFiles: any;
  
  
  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'},
        {class: 'century-gothic', name: 'Century Gothic'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
};

  constructor(
    private rest_api : RestApiProvider,
    private http:HttpClient,
    private toaster: ToastrService,
    public _settings:SettingsService,
  ) { 
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      port: new FormControl(null, Validators.required),
      host: new FormControl(null, Validators.required),
      username: new FormControl(
          null,
          [
              Validators.required,
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
          ],
        ),

    });
    this.form_organizational = new FormGroup({
      name: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      port: new FormControl(null, Validators.required),
      host: new FormControl(null, Validators.required),
      signature: new FormControl(null),
      username: new FormControl(
          null,
          [
              Validators.required,
              Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
          ],
        ),

    });
    this._settings.cargarAjustes();
    if (this._settings.ajustes.org  && this._settings.ajustes.org.id && (this._settings.amIRoleAdmin() || this._settings.amIRoleSubAdmin() || this._settings.amISuperInOrg())) {
        this.rest_api.obtener_smtp_organizacional_config(this._settings.ajustes.org.urlname).then(response => {

          this.form_organizational.get('username').clearAsyncValidators();
          this.form_organizational.get('username').clearValidators();
          this.form_organizational.get('password').clearValidators();
          this.form_organizational.get('password').updateValueAndValidity();
          this.form_organizational.get('username').updateValueAndValidity();

        this.form_organizational.reset(response);

        if ( this._settings.amIUser() && !this._settings.amIRoleAdmin() && !this._settings.amIRoleSubAdmin() ) {
          this.form_organizational.disable();
        }

        
      });

    }

    if (this._settings.ajustes.org  && this._settings.ajustes.org.id && !this._settings.amISuperInOrg() && !this._settings.amISuperOutOrg()) {
      this.rest_api.obtener_smtp_config().then(response => {

        this.form.get('username').clearAsyncValidators();
        this.form.get('username').clearValidators();
        this.form.get('username').disable()
        this.form.get('password').clearValidators();
        this.form.get('password').updateValueAndValidity();
        this.form.get('username').updateValueAndValidity();

      this.form.reset(response);

      if ( this._settings.amISuperInOrg() || this._settings.amISuperOutOrg() ) {
        this.form.disable();
      }

    });

  }
    
  }

  ngOnInit() {
  }

  save() {

    
      this.rest_api.update_smtp_config(this.form.value).then(response => {
        console.log(response);
      });
  }

  save_organizational() {

    
    this.rest_api.update_smtp_organizacional_config(this._settings.ajustes.org.urlname, this.form_organizational.value).then(response => {
      console.log(response);
    });
}

  get nameNoValido() {
    return this.form.get('name').invalid && this.form.get('name');
  }

  get passwordNoValido() {
    return this.form.get('password').invalid && this.form.get('password').touched;
  }

  get portNoValido() {
    return this.form.get('port').invalid && this.form.get('port').touched;
  }
  get hostNoValido() {
    return this.form.get('host').invalid && this.form.get('host').touched;
  }
  get usernameNoValido() {
    return this.form.get('username').invalid && this.form.get('username').touched;
  }


  get nameNoValidoOrg() {
    return this.form_organizational.get('name').invalid && this.form_organizational.get('name');
  }

  get passwordNoValidoOrg() {
    return this.form_organizational.get('password').invalid && this.form_organizational.get('password').touched;
  }

  get portNoValidoOrg() {
    return this.form_organizational.get('port').invalid && this.form_organizational.get('port').touched;
  }
  get hostNoValidoOrg() {
    return this.form_organizational.get('host').invalid && this.form_organizational.get('host').touched;
  }
  get usernameNoValidoOrg() {
    return this.form_organizational.get('username').invalid && this.form_organizational.get('username').touched;
  }

}






  