import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
// import { ToastrService } from 'ngx-toastr';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, timeoutWith } from 'rxjs/operators';
import { SettingsService } from '../settings/settings.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    private requests: HttpRequest<any>[] = [];

    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

    constructor(
        private _router:Router,
        private _ajustes:SettingsService,
        private loaderService: LoaderService

        // public toaster: ToastrService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const org = this._ajustes.ajustes.org;
        if (org && org.id && !request.url.includes('organizations') && !request.url.includes('?org')){
            if(request.url.indexOf('?')!==-1){
                request = request.clone({
                    url : request.url + '&org=' + org.id
                });
            } else{
                request = request.clone({
                    url : request.url + '?org=' + org.id
                });
            }
        } 

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                // console.log('Http error', error);
                if (error.status == 401 || error.status == 403 || error.status == 405){
                    // this.toaster.error('Error de autenticación, vuelva a iniciar sesión por favor','Error');
                    this._router.navigate(['/login']);
                    this._ajustes.borrarAjustes();
                }
                return throwError(error);
            }));
    }
}



@Injectable({
    providedIn: 'root'
  })
  export class LoaderService {
   
    public isLoading = new BehaviorSubject(false);
    constructor() { }
  }