import { Component, OnInit } from '@angular/core';
import { SidebarService, SettingsService } from '../../services/service.index';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/shared/dataShare.service';
import { RestApiProvider } from 'src/app/services/shared/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit {
  organizacion: any = {};
  usuario: any = {};
  user: any = {};
  saam_url:string = '';

  constructor(
    public _sidebar: SidebarService, 
    public _settings: SettingsService, 
    private _router: Router,
    private restApi: RestApiProvider,
    private _dataShare: DataService

    ) {
   }

  ngOnInit() {
    this._dataShare.currentMessage.subscribe(message => {
      try {
        this.organizacion['alias'] = message['alias'];
        this.organizacion['id'] = message['id'];
      } catch (err) {}

      if (message && message['message'] === 'loged_in') {
        this._sidebar.loadSideMenu();
      }
      this._settings.cargarAjustes().then(() => {
        this.usuario = this._settings.ajustes.user;
      });
    });

    this.vaciarOrganizacion();

    this._settings.cargarAjustes().then(() => {
      if (this._settings.ajustes.org && this._settings.ajustes.org.id) {
        this.organizacion = this._settings.ajustes.org;
      } else {
        this.vaciarOrganizacion();
      }
      this.usuario = this._settings.ajustes.user;
    });
  }

  gotoSaam(){
    // window.open('http://' + this._settings.ajustes.org.urlname + '.localhost:3000', "_blank");
    window.open('https://' + this._settings.ajustes.org.urlname + '.miurabox.com', "_blank"); //Para produccion
  }

  vaciarOrganizacion() {
    this.organizacion = {
      'alias': 'Manager'
    }
  }

  dejarOrganizacion(){
    this._settings.borrarOrg();
    this.vaciarOrganizacion();
    this._dataShare.changeMessage({});
    this._router.navigate(['/organizaciones']);
  }

  closeSession(){
    this._settings.borrarAjustes();
  }

}
