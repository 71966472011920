import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {
  subject: string;
  imageURL: string;
  imageURL2: string;
  imageURL3: string;
  imageURL4: string;
  title:string = '';
  message:string = '';
  incluir_tabla = false;
  incluir_nombre_asegurado = false;
  table_footer_text:string = '';
  uploadForm: FormGroup;
  org_url:string = undefined;

  constructor(
    private router: Router,
    public fb: FormBuilder,
    private rest_api: RestApiProvider,
    private toaster: ToastrService,
    private settings : SettingsService,
    private navParams: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.settings.cargarAjustes();
    this.uploadForm = this.fb.group({
      avatar: [null],
      avatar2: [null],
      avatar3: [null],
      avatar4: [null],
      title: [],
      message: [],
    });
    this.navParams.params.subscribe(params => {
      if (params['id'] != 'nuevo') {
        this.rest_api.get_template(params['id']).then(response => {
          this.imageURL = response['imageURL'];
          this.imageURL2 = response['imageURL2'];
          this.imageURL3 = response['imageURL3'];
          this.imageURL4 = response['imageURL4'];
          this.title = response['title'];
          this.message = response['message'];
          this.incluir_tabla = response['incluir_tabla'];
          this.table_footer_text = response['table_footer_text'];
          this.subject = response['subject'];
          this.incluir_nombre_asegurado = response['incluir_nombre_asegurado'];
          this.org_url = response['url'];
        }, error => {
          this.router.navigate(['/servicios-masivos']);   
        })
        .catch(err => {
          this.router.navigate(['/servicios-masivos']);   
        });
      }
    })
   }

  delete(){
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esta operación es irreversible",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.rest_api.eliminar_template(this.org_url).then(response => {
          Swal.fire(
            'Eliminado!',
            'El archivo ha sido eliminado exitosamente',
            'success'
          );
          this.router.navigate(['/servicios-masivos']);
        });
      }
    })
  }

  save(){
    this.spinner.show();
    if(this.org_url){
      let obj = {
        'title' : this.title,
        'message' : this.message,
        'incluir_tabla' : this.incluir_tabla,
        'table_footer_text' : this.table_footer_text,
        'subject' : this.subject,
        'org': this.settings.ajustes.org.id,
        'incluir_nombre_asegurado': this.incluir_nombre_asegurado
      }
      this.rest_api.patch_template(this.org_url,obj).then(() => {
        this.spinner.hide();
        this.toaster.success('Cuerpo de correo guardado');
        this.router.navigate(['/servicios-masivos']);   
      }, error => {
        this.spinner.hide();
        this.toaster.error('Ah ocurrido un error');
      })
      .catch(err=> {
        this.spinner.hide(); 
        this.toaster.error('Ah ocurrido un error');
      })
    } else {
      let obj = {
        'imageURL' : this.imageURL,
        'imageURL2' : this.imageURL2,
        'imageURL3' : this.imageURL3,
        'imageURL4' : this.imageURL4,
        'title' : this.title,
        'message' : this.message,
        'incluir_tabla' : this.incluir_tabla,
        'table_footer_text' : this.table_footer_text,
        'subject' : this.subject,
        'org': this.settings.ajustes.org.id,
        'incluir_nombre_asegurado': this.incluir_nombre_asegurado
      }
      this.rest_api.guardar_template(obj).then(() => {
        this.spinner.hide();
        this.toaster.success('Cuerpo de correo guardado');
        this.router.navigate(['/servicios-masivos']);   
      }, error => {
        this.spinner.hide();
        this.toaster.error('Ah ocurrido un error');
      })
      .catch(err => {
        this.spinner.hide();
        this.toaster.error('Ah ocurrido un error');
      });
    }    
  }

  cancel(){
    this.router.navigate(['/servicios-masivos']);
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar: file
    });
    this.uploadForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  showPreview2(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar2: file
    });
    this.uploadForm.get('avatar2').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL2 = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  showPreview3(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar3: file
    });
    this.uploadForm.get('avatar3').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL3 = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  showPreview4(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadForm.patchValue({
      avatar4: file
    });
    this.uploadForm.get('avatar4').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL4 = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  ngOnInit() {
  }

}
