import { Component, OnInit } from '@angular/core';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  carouselItems = [
    { image: 'https://via.placeholder.com/800x400?text=Slide+1', title: 'Inicio SAAM' },
    { image: 'https://via.placeholder.com/800x400?text=Slide+2', title: 'Novedades' },
    { image: 'https://via.placeholder.com/800x400?text=Slide+3', title: 'Contacto' }
  ];
  images: any[] = []; 
  todasImages: any[] = []; 
  newDescription: string = '';
  currentIndex = 0;
  fileImage:any;
  beforeUpload:any;
  formData: FormData;
  anotherImage: boolean= false;
  addNew:boolean=false;

  constructor(

    private rest_api: RestApiProvider,
  ) {}

  ngOnInit() {
    this.formData = new FormData();
    this.getCarouselItems();
  }

  // this.images.push({
  //   imagen: e.target.result,
  //   description: this.newDescription,
  //   visible: true, 
  // });
  // this.newDescription = ''; 
  onImageUpload(event: any) {
    this.anotherImage=true;
    this.formData = new FormData();
    const file = event.target.files[0];  
    if (file) {
      const reader = new FileReader();  
      reader.onload = (e: any) => {
        this.beforeUpload = e.target.result;
        // this.formData.append('archivo', file);  
        // this.formData.append('description', this.newDescription);  
        // this.formData.append('visible', 'true'); 
      };  
      reader.readAsDataURL(file);  
    }
  }
  cancelUploading(value: boolean){
    this.anotherImage=value;
    this.beforeUpload = ''
    this.newDescription=''
  }
  addImage(event:any) {
    // if (!this.newDescription) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Añade una descripción a la imagen',
    //     text: 'Escriba la descripción',
    //   });
    //   return;
    // }
    this.images.push({
      url:'',
      imagen:this.beforeUpload,
      description: this.newDescription,
      nombre: this.newDescription,
      visible: true, 
    });
    console.log('Ok:', this.images);
    this.cancelUploading(false);
    this.todasImages.push(this.fileImage)
    this.addNew=false;
  }
  toggleVisibility(index: number) {
    this.images[index].visible = !this.images[index].visible;2
  }
  saveImages() {
    let total_files = this.images.length;
    let countfiles = 0
    for(var i = 0; i < this.images.length; i++){
      if(this.images[i]['url'] ==''){
        this.rest_api.saveImages(this.images[i],this.images[i]['imagen']).then(response => {
          countfiles = countfiles+1   
          if(total_files == countfiles){
            this.getCarouselItems();
            Swal.fire('Las imágenes han sido cargadas');
          }
        });

      }else{
        if (this.images[i].hasOwnProperty('imagen') && typeof this.images[i]['imagen'] === 'string') {
          if (this.images[i]['imagen'].includes('miurabox')) {
            delete this.images[i]['imagen']; 
          }
        }
        // delete this.images[i].assignments['image'];
        this.rest_api.updateImages(this.images[i]['url'],this.images[i]).then(response => {
          countfiles = countfiles+1   
          if(total_files == countfiles){
            Swal.fire('Las imágenes han sido actualizadas');
            this.getCarouselItems();
          }
        });        
      }
    }
  }

  prevSlide() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.images.length - 1;
    } else {
      this.currentIndex--;
    }
  }
  nextSlide() {
    if (this.currentIndex === this.images.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
  }
  onChangeImage(event: any, index: number) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.images[index].imagen = e.target.result;  
      };
      reader.readAsDataURL(file);
    }
  }
  addImagenes(){
    this.addNew = true;
  }
  deleteImage(index: number) {
    this.images[index].visible = false;  
    this.images=this.images;
    // this.images.splice(index, 1);
  }
  getFirstVisibleIndex(): number {
    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].visible) {
        return i;
      }
    }
    return -1;  // If no visible images, return -1 (though this case shouldn't happen)
  }
  getCarouselItems() {
    this.carouselItems = [
      { image: 'https://miurabox-public.s3.amazonaws.com/cumpleanios/pruebas/image_1.png', title: 'Dynamic Slide 1' },
      { image: 'https://miurabox-public.s3.amazonaws.com/cumpleanios/pruebas/image_1.png', title: 'Dynamic Slide 2' },
      { image: 'https://miurabox-public.s3.amazonaws.com/cumpleanios/pruebas/image_1.png', title: 'Dynamic Slide 3' }
    ];
    this.rest_api.getCarousel().then(response => {
      this.images = response['results'];
    });
  }
}
