import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { SettingsService } from 'src/app/services/service.index';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidadoresService } from 'src/app/services/shared/validators';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/shared/dataShare.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: []
})
export class ProfileComponent implements OnInit {
  user: any ;
  form: FormGroup;
  showEdit = false;

  showImageProfile: boolean = true;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  constructor(
    private rest_api: RestApiProvider,
    private _settings: SettingsService,
    private _validators: ValidadoresService,
    private _toaster : ToastrService,
    private _dataShare : DataService
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      first_name:  new FormControl(null, [Validators.required]),
      last_name : new FormControl(null, [Validators.required]),
      username : new FormControl(null),
      email : new FormControl(null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")], this._validators.existeEmail.bind(this) ),
      userinfo : new FormGroup({
        phone : new FormControl(null,[Validators.required, this._validators.noDiezDigitosPhone]),
        birthdate: new FormControl(null),
        rfc : new FormControl(null)
      })
    })

    this._settings.cargarAjustes();
    this.rest_api.get_usuario(this._settings.ajustes.user.id).then(response=>{
      this.user = response;
      console.log(response['is_superuser']);
      
      if (response['is_superuser'] == true ) {
        (this.form.get('userinfo') as FormGroup).disable();
      }
      else {
        (this.form.get('userinfo') as FormGroup).reset(response['userinfo']);
      }
      this.form.reset(response);
      this.form.get('username').disable();
      this.form.updateValueAndValidity();    
    });
  }

  edit(param: boolean) {
    this.showEdit = param;
  }

  imageLoaded(){
    this._toaster.success('Imagen cargada');    
  }
  get usernameNoValido() {
    return this.form.get('username').invalid && this.form.get('username').touched;
  }

  cropperReady(){
    this._toaster.success('Imagen cortada');
  }
  loadImageFailed(){
    this._toaster.error('Ha ocurrido un error con la imagen cargada');
  }

  get first_nameNoValido(){
    return this.form.get('first_name').invalid && this.form.get('first_name').touched;
  }
  get last_nameNoValido(){
    return this.form.get('last_name').invalid && this.form.get('last_name').touched;
  }
  get emailNoValido(){
    return this.form.get('email').invalid && this.form.get('email').touched && !this.form.get('email').hasError('exists');;
  }
  get phoneNoValido(){
    return (this.form.get('userinfo') as FormGroup).get('phone').invalid && (this.form.get('userinfo') as FormGroup).get('phone').touched;
  }

  get birthdateNoValido(){
    return (this.form.get('userinfo') as FormGroup).get('birthdate').invalid && (this.form.get('userinfo') as FormGroup).get('birthdate').touched;
  }
  get rfcNoValido(){
    return (this.form.get('userinfo') as FormGroup).get('rfc').invalid && (this.form.get('userinfo') as FormGroup).get('rfc').touched;
  }

  get get_phone(){
    return (this.form.get('userinfo') as FormGroup).get('phone').value;
  }


  save(){
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      })
      return;
    }
    console.log(this.form.value);
    console.log(this.user);
    if(this.user && this.user.userinfo && this.user.userinfo['id'] &&  this.form.value['userinfo']){
      this.form.value['userinfo']['id'] = this.user.userinfo['id'];
    }    
    this.rest_api.parchar_url(this.user['url'], this.form.value).then(response=>{
      console.log(response);
      this._toaster.success('Registro actualizado correctamente');
    },error=>{
      console.error(error);
      this._toaster.error('Ocurrio un error, intentelo mas tarde');
    })
    .catch(err=>{
      console.error(err);
      this._toaster.error('Ocurrio un error, intentelo mas tarde');
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showImageProfile = false;
  }

  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
    
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  change_imagen(event){

    var ImageURL = this.croppedImage;
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];

    // Convert it to a blob to upload
    var blob = this.b64toBlob(realData, contentType);
    var file = new File([blob],this.imageChangedEvent.target.name, {
      type: contentType
    });

    // Create a FormData and append the file with "image" as parameter name
    var formDataToUpload = new FormData();
    formDataToUpload.append("avatar", file);
    let payload = {  
      "avatar": this.croppedImage
    }
    
    this.rest_api.parchar_url_media( this.user.userinfo.url.replace('user-info','user-avatar-info') , payload).then(response=>{
      this.user.userinfo['avatar'] = response['avatar'];
      this.showImageProfile = true;
      this._toaster.success('Imagen guardada correctamente');
      this._settings.ajustes.user.avatar =  this.user.userinfo.avatar;
      this._settings.guardarAjustes();    
      this._dataShare.changeMessage({});
      
    });
  }

}
