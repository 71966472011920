import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/shared/dataShare.service';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  showCounter = false;
  text_counter = '';
  org: any = {
    logo: ''
  };

  constructor(
    public _ajustes: SettingsService,
    private rest_api: RestApiProvider,
    private _toaster : ToastrService,
    private router: Router,
    private dataShare: DataService,
    private rest_api_payment : RestApiPaymentProvider
  ) { 
    this.showCounter = false;
    if(this._ajustes.ajustes.org && this._ajustes.ajustes.org['billiable']){
      this.rest_api_payment.getSuscriptionsByClient(this._ajustes.ajustes.org.urlname).then(response_subs => {
        if (response_subs['status'] == 'cancel'){
          this.showCounter = true;
          this.text_counter = 'La suscripción de esta organización está cancelada'
        }
        else if (response_subs['status'] == 'active'){

          let init_date = new Date(response_subs['init_date']);
          // console.log('init_date', init_date);
          let dias_de_prueba = response_subs['plan']['trial_period_days'];
          // console.log('trial_period_days', response_subs['trial_period_days']);
          
          let total_date = new Date(init_date.setDate(init_date.getDate() + dias_de_prueba));
          // console.log('total_date', total_date);
          // console.log('new Date()', new Date());
          let actual_date = new Date();
          var diff = Math.abs(actual_date.getTime() - total_date.getTime());

          var diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
          console.log('diffDays', diffDays);

          if (total_date > actual_date){
            this.showCounter = true;
            this.text_counter = 'Quedan ' + diffDays + ' días de tu periodo de prueba'
          }
        }
          
      }, error => {
        this.showCounter = false;
      })
      .catch(err => {
        this.showCounter = false;
      });
    }

    // if(this._ajustes.ajustes.org && this._ajustes.ajustes.org['billiable']){
    //   this.rest_api.isOrgDemo(this._ajustes.ajustes.org['id']).then(responseOrg => {
    //     if (responseOrg ) {
    //       this.showCounter = true;
    //     }
    //   });

    //   if (this._ajustes.ajustes.org.billiable){
    //     this.rest_api_payment.getSuscriptionsByClient(this._ajustes.ajustes.org.urlname).then(response_subs => {
    //       if ((response_subs as Array<any>).length == 0  ){
    //         this.showCounter = true;
    //       }      
    //     }, error => {
    //       this.showCounter = true;
    //     })
    //     .catch(err => {
    //       this.showCounter = true;
    //     });
    //   }
    // }

    // this.dataShare.currentMessage.subscribe(() => {
    //   this.showCounter = false;
    //   if(this._ajustes.ajustes.org && this._ajustes.ajustes.org['billiable']){
    //     if(this._ajustes.ajustes.org && this._ajustes.ajustes.org['id']){
    //       this.rest_api.isOrgDemo(this._ajustes.ajustes.org['id']).then(responseOrg => {
    //         if (responseOrg ) {
    //           this.showCounter = true;
    //         }
    //       });
    //     }
    //     if (this._ajustes.ajustes.org.billiable){
    //       this.rest_api_payment.getSuscriptionsByClient(this._ajustes.ajustes.org.urlname).then(response_subs => {
    //         if ((response_subs as Array<any>).length == 0  ){
    //           this.showCounter = true;
    //         }      
    //       }, error => {
    //         this.showCounter = true;
    //       })
    //       .catch(err => {
    //         this.showCounter = true;
    //       });
    //     }
    //   }
    // });


  
    

    
  }

  ngOnInit() {
    if (this._ajustes.ajustes.org['id']) {
      this.rest_api.get_organizacion_info(this._ajustes.ajustes.org['id']).then(response => {
        // console.log(response)
        this.org = response;
        this._ajustes.ajustes.org = response;
        this._ajustes.guardarAjustes();
      }, error => {
        console.log(error);
        this._toaster.error('Hubo un error al cargar el logo para el menú');
      })
      .catch(errr => {
        console.log(errr);
      });
    } else {
      this.org['logo'] = '/../../assets/images/logo_mbox.png';
      this.org['logo_mini'] = '/../../assets/images/logo.png';
    }
  }

  buy_license(){
    // this.router.navigate(['/terminal-digital']);
  }

}
