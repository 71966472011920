import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {
  organizaciones: any = [];
  form: FormGroup;
  constructor(
    private rest_api:RestApiProvider,
    private spinner: NgxSpinnerService
  ) { 
    this.form = new FormGroup({
      enviar_a_todos: new FormControl(true),
      org: new FormControl(null),
      title: new FormControl('', [Validators.maxLength(100), Validators.required]),
      body: new FormControl('', [Validators.maxLength(499), Validators.required])
    });
  }

  ngOnInit() {
    this.rest_api.get_organizaciones().then(response=>{
      this.organizaciones = response['results'];
    })
  }

  send(){
    if (this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      });
      return;
    }
    Swal.fire({
      title: '¿Está seguro de enviar la notificación?',
      text: "Esta operación es irreversible.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, enviar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.rest_api.enviar_notificacion(this.form.value).then(response => {
          this.spinner.hide();
          Swal.fire(
            'Enviada!',
            'La notificación ha sido enviada.',
            'success'
          )
        }, error => {
          this.spinner.hide();
        })
        .catch(err => {
          this.spinner.hide();
        });
      }
    })
  }

  get titleNoValido(){
    return this.form.get('title').invalid && this.form.get('title').touched;
  }

  get bodyNoValido(){
    return this.form.get('body').invalid && this.form.get('body').touched;
  }


}
