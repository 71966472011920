import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators,ReactiveFormsModule } from '@angular/forms';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import Swal from 'sweetalert2';
import { SettingsService } from 'src/app/services/service.index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { Router } from '@angular/router';

type AOA = any[][];

@Component({
  selector: 'app-usersApp',
  templateUrl: './usersApp.component.html',
  styleUrls: ['./usersApp.component.css']
})
export class UsersAppComponent implements OnInit {
  form: FormGroup;
  page = 0;
  total = 0;
  usuarios: any;
  beAbleLimitUsers = true;
  previous = null;
  next = null;
  orgSelected: 0;
  turnOffPagination = false;

  constructor(
    private router: Router,
    private restApi: RestApiProvider,
    private http:HttpClient,
    private toaster: ToastrService,
    private settings:SettingsService,
  ) { 
    this.form = new FormGroup({
      identifier: new FormControl(),
      since: new FormControl(),
      until: new FormControl()
    });
  }

  ngOnInit() {
    let data = {
      orgname: this.settings.ajustes.org.id,
      identifier: '',
      since: '',
      until: '',
      page: 1
    }
    this.restApi.getUsersApp(data).then(response => {
      this.usuarios = response['results'];
      this.total =response['count'];
      this.previous = response['previous'];
      this.next = response['next'];
      this.page = 1;
    });
  }

  filter(){
    let dia;
    let mes;
    let anio;
    let diaF;
    let mesF;
    let anioF;
    if(this.form.value.since){
      dia = this.form.value.since.substr(8, 2);
      mes = this.form.value.since.substr(5, 2);
      anio = this.form.value.since.substr(0, 4);
    }
    if(this.form.value.until){
      diaF = this.form.value.until.substr(8, 2);
      mesF = this.form.value.until.substr(5, 2);
      anioF = this.form.value.until.substr(0, 4);
    }

    let data = {
      orgname: this.settings.ajustes.org.id,
      identifier:  this.form.value.identifier ? this.form.value.identifier : '',
      since: dia ? dia + '/' + mes + '/' + anio + ' 00:00:00' : '',
      until: diaF ? diaF + '/' + mesF + '/' + anioF + ' 23:59:59' : '',
      page: 1
    }

    this.restApi.getUsersApp(data).then(response => {
      this.usuarios = response['results'];
      this.total =response['count'];
      this.previous = response['previous'];
      this.next = response['next'];
      this.page = 1;
    });
  }

  detalleUsuario(user:any){
    if (user) {
      this.settings.ajustes.tema = user;
      this.settings.ajustes.tema['identifier'] = user.userinfo.identifier;
      this.settings.guardarAjustes();
      this.router.navigate(['/usuarios-app-detalle/', user.id]);
    } else {
      this.router.navigate(['/usuarios-app-detalle', 'nuevo']);
    }
  }

  nextPage(){
    if (this.next){
      this.restApi.get_url(this.next).then(responseNext => {
        this.usuarios = responseNext['results'];
        this.next = responseNext['next'];
        this.previous = responseNext['previous'];
      })
    }
  }

  previousPage(){
    if (this.previous){
      this.restApi.get_url(this.previous).then(responsePrevious => {
        this.usuarios = responsePrevious['results'];
        this.next = responsePrevious['next'];
        this.previous = responsePrevious['previous'];
      })
    }
  }

  loadPage(event){
    let dia;
    let mes;
    let anio;
    let diaF;
    let mesF;
    let anioF;
    if(this.form.value.since){
      dia = this.form.value.since.substr(8, 2);
      mes = this.form.value.since.substr(5, 2);
      anio = this.form.value.since.substr(0, 4);
    }
    if(this.form.value.until){
      diaF = this.form.value.until.substr(8, 2);
      mesF = this.form.value.until.substr(5, 2);
      anioF = this.form.value.until.substr(0, 4);
    }

    let data = {
      orgname: this.settings.ajustes.org.id,
      identifier:  this.form.value.identifier ? this.form.value.identifier : '',
      since: dia ? dia + '/' + mes + '/' + anio + ' 00:00:00' : '',
      until: diaF ? diaF + '/' + mesF + '/' + anioF + ' 23:59:59' : '',
      page: event
    }
    this.restApi.getUsersApp(data).then(response=>{
      this.usuarios = response['results'];
      this.total =response['count'];
      this.page = event;
    });
  }

  // get ridentifierNoValido(){
  //   return (this.form.get('identifier') as FormGroup).get('identifier').invalid && (this.form.get('identifier') as FormGroup).get('name').touched;
  // }
  generarReporte(){
    let dia;
    let mes;
    let anio;
    let diaF;
    let mesF;
    let anioF;
    if(this.form.value.since){
      dia = this.form.value.since.substr(8, 2);
      mes = this.form.value.since.substr(5, 2);
      anio = this.form.value.since.substr(0, 4);
    }
    if(this.form.value.until){
      diaF = this.form.value.until.substr(8, 2);
      mesF = this.form.value.until.substr(5, 2);
      anioF = this.form.value.until.substr(0, 4);
    }

    let data = {
      orgname: this.settings.ajustes.org.id,
      identifier:  this.form.value.identifier ? this.form.value.identifier : '',
      since: dia ? dia + '/' + mes + '/' + anio + ' 00:00:00' : '',
      until: diaF ? diaF + '/' + mesF + '/' + anioF + ' 23:59:59' : ''
    }
    this.restApi.generateReportApp(data).then((response:any) =>{
      let filename = 'Reporte_Usuarios_App.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    })
  }

}






  