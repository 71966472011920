import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import Swal from 'sweetalert2';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';



@Component({
  selector: 'app-suscripciones-detail',
  templateUrl: './suscripciones-detail.component.html',
  styleUrls: ['./suscripciones-detail.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SuscripcionesDetailComponent implements OnInit {
  displayedColumns = ['cargo', 'fecha', 'estatus'];
  dataSource:any;

  isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  expandedElement: any;
  
  
  suscripcion:any = {}
  cardsList:any = [];
  ordenes:any = [];
  constructor(
    private activateRoute: ActivatedRoute,
    private rest_api : RestApiPaymentProvider,
    private toaster: ToastrService
  ) { 
    this.activateRoute.params.subscribe(params => {
      if (params && params['subs_id']){
        this.rest_api.getSuscriptionDetail(params['subs_id']).then(response => {
          this.suscripcion = response;
          this.rest_api.getOrdersClient(response['payment_service_object']['customer_id']).then(response_orders => {
            data = (response_orders['data'] as Array<any[]>).map(orden => {
              let obj:Element = {
                cargo : orden['amount'],
                fecha : new Date(orden['charges']['data'][0]['created_at']*1000),
                estatus : orden['payment_status'],
                detalles : orden['charges']['data'].map(charge=>{
                  let a = [];
                  a.push(charge['failure_message']);
                  charge['payment_method']['fraud_indicators'].forEach(indicator => {
                    a.push(indicator['description']);
                  });
                  return a;
                }),
              }
              return obj
            });
            this.dataSource = new ExampleDataSource();
          });
          try{
            this.suscripcion['payment_service_object']['trial_end'] = new Date(this.suscripcion['payment_service_object']['trial_end']*1000).toLocaleDateString();
          }
          catch(err){}
          this.cardsList = this.suscripcion.cliente.payment_sources.data;
          
          this.cardsList.forEach(payment_source => {
            if (this.suscripcion.payment_service_object.card_id == payment_source.id){
              this.suscripcion['selected_card'] = payment_source['name'] + ' - ' + payment_source['brand'] + ' ************' + payment_source['last4'];
            }
            if (payment_source.default == true){
              this.suscripcion['default_card'] = payment_source['name'] + ' - ' + payment_source['brand'] + ' ************' + payment_source['last4'];
            }
            
          });
          this.cardsList = this.cardsList.map(card => {
            response = {};
            let title = card['name'] + ' - ' + card['brand'] + ' - ' + card['last4']
            response[card['id']] = title;
            return response;
            
          });
        })
      }
      
    });
  }

  ngOnInit() {
  }

  changePaymentMethod(){
    Swal.fire({
      title: 'Seleccione una tarjeta para actualizar la forma de pago',
      input: 'select',
      inputOptions: this.cardsList,
      inputPlaceholder: 'Seleccione una opción',
      showCancelButton: true,
      cancelButtonColor:'#d33'
    }).then(result =>{
      if (result && result.value){
        let payload = {
          'default_payment_source_id': result.value
        }
        this.rest_api.update_suscripcion(this.suscripcion.id, payload).then(response => this.toaster.success('Método de pago actualizado'),
        error => this.toaster.error('Ocurrió un error al actualizar el método de pago'))
        .catch(err => this.toaster.error('Ocurrió un error al actualizar el método de pago'));
      }
    });  
  }

}


export interface Element {
  cargo: number;
  fecha: Date;
  estatus: string;
  detalles: string;
}

let data: Element[] = [
];


export class ExampleDataSource extends DataSource<any> {
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Element[]> {
    const rows = [];
    data.forEach(element => rows.push(element, { detailRow: true, element }));
    console.log(rows);
    return of(rows);
  }

  disconnect() { }
}