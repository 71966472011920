import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class RoleUserGuard implements CanActivate {

  constructor(
      public router: Router, 
      private settings: SettingsService,
      private toaster: ToastrService
    ){
    settings.cargarAjustes();

  }
  canActivate(route: ActivatedRouteSnapshot) {
    if (!this.settings.ajustes.user.is_superuser) {
      return true;
    } else {
      // this.toaster.warning('No puedes accesar a esta seccion');
      return false;
    }
  }
  
}
