import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { interval } from 'rxjs';


@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css']
})
export class PlanesComponent implements OnInit {
  editField: string;
  plan:Plan;
  planList: Array<Plan> = [];
  apps:any = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};

  constructor(
    private rest_api_payment: RestApiPaymentProvider,
    private toaster: ToastrService,
    private rest_api_gral: RestApiProvider,
    public dialog: MatDialog,
  ) {
   }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getIntervalos(interval){
    let intrvls = {
      "month":"Mensual",
      "year":"Anual",
      "week":"Semanal",
      "half_month":"Quincenal"
    }
    return intrvls[interval];
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'name',
      textField: 'name',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      enableCheckAll : false
    };    
    this.rest_api_payment.get_planes().then(response => {
      this.planList = <Plan[]>response;
      this.planList = this.planList.map(plan => {
        plan['interval'] = this.getIntervalos(plan['interval']);
        return plan;
      });
      this.toaster.success('Planes cargados correctamente');
    }, error => {
      console.log(error);
      this.toaster.error('Ha ocurrido un error, recargue nuevamente la página','Error');
    })
    .catch(err => {
      console.log(err);
      this.toaster.error('Ha ocurrido un error, recargue nuevamente la página','Error');
    });
    this.rest_api_gral.obtener_aplicaciones().then(response => {
      this.apps = response;
      this.apps = this.apps.map(app => {
        return {
          id:app.id,
          name:app.name,
          url:app.url
        }
      });
    });
  }

  toTimestamp(){
    var datum = new Date();
    return datum.getTime()/1000;
   }

  updateList(id: number, property: string, event: any) {
    let editField
    try {
      editField = event.target.textContent;    
    }
    catch(err){
      editField = event;    
    }
    let payload = {};
    if (property == 'init_date' || property == 'end_date'){
      editField = new Date(editField);
    }
    if (property == 'amount') {
      editField = parseFloat(editField.toString().replace('$',''));
    }
    payload[property] = editField;    
    this.rest_api_payment.update_plan(this.planList[id].id, payload).then(response=>{
      this.toaster.success('Plan actualizado correctamente');
      this.planList[id] = <Plan>response;
    }, error => {
      this.planList[id][property] = '';
      try{
        Array<any>(Object.entries(error.error)[0][1]).forEach(msg => {
          this.toaster.error(Object.entries(error.error)[0][1][0],Object.entries(error.error)[0][0]);
        });
      }
      catch(err){
        this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
      }
    })
    .catch(error => {
      this.planList[id][property] = '';
      try{
        Array<any>(Object.entries(error.error)[0][1]).forEach(msg => {
          this.toaster.error(Object.entries(error.error)[0][1][0],Object.entries(error.error)[0][0]);
        });
      }
      catch(err){
        this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
      }
    });
  }

  disable(id: any) {
    
  }

  add() {

    const dialogRef = this.dialog.open(AddPlanDialogComponent, {
      disableClose: true,
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(result);
      if(result){
        let plan:Plan = {
          clave: this.toTimestamp().toString().split('.')[0],
          name: result['name'],
          amount: result['amount'] ,
          currency: result['currency'] ,
          interval: result['interval'] ,
          frequency: result['frequency'] ,
          trial_period_days: result['trial_period_days'],
          expiry_count: result['expiry_count'] ,
          descripcion:'breve descripción...',
          apps: [],
          init_date: new Date(),
          end_date: new Date(new Date().setMonth(new Date().getMonth() + 1))
        }
        this.rest_api_payment.create_init_plan(plan).then(response => {
          this.planList.push(<Plan>response);
          this.toaster.success('Plan agregado correctamente');
        }, error => {
          this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
        })
        .catch(err => {
          this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
        });
      }
    });
  }

    changeValue(id: number, property: string, event: any) {
      this.editField = event.target.textContent;
    }

}

export interface Plan  {
  clave:string;
  name:string;
  amount:number;
  currency:string;
  interval:string;
  frequency:number;
  trial_period_days:number;
  expiry_count:number;
  descripcion?:string;
  url?:string;
  id?:number;
  init_date:Date;
  end_date:Date;
  apps?:any;
}



export interface DialogData {
  cliente_id:number;
}

@Component({
  selector: 'add-suscription-dialog',
  templateUrl: 'add.plan.dialog.component.html',
})
export class AddPlanDialogComponent {
  planesList:Array<any> = []; 
  selectedPlan:any;
  form:FormGroup;
  plan_name = '';
  isLinear = '';
  plan_id = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<AddPlanDialogComponent>,
  private rest_api: RestApiPaymentProvider,
  private toaster: ToastrService
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      amount : new FormControl(null, [Validators.required]),
      currency : new FormControl(null, [Validators.required]),
      interval : new FormControl(null, [Validators.required]),
      frequency : new FormControl(null, [Validators.required]),
      trial_period_days : new FormControl(null, [Validators.required]),
      expiry_count : new FormControl(null, [Validators.required]),
    });
  }
  cancel() {
    this.dialogRef.close();
  }

  changePlan(){
    this.planesList.forEach(plan => {
      if (this.form.get('plan').value == plan.value){
        this.plan_name = plan.name;
        this.plan_id = plan.id;
      }
    });
    this.rest_api.checkSuscription(this.data['cliente_id']).then(response => {
      if (Array.isArray(response) && response.length == 0){}
      else{
        Swal.fire(
          'Ya existe una suscripción activa para este cliente con el plan "' + response[0]['plan_clave'] + '"',
          "Si continúa, la suscripción actual se cancelará para generar una nueva",
          'warning'
        );
      }
    });
  }


  save(){
    if (this.form.invalid){
      this.toaster.error('Llene todos los campos');
      return;
    }
    else{
      this.dialogRef.close(this.form.value);
    }
  }
}