import { Injectable } from '@angular/core';
import { SettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any ;
  constructor(
    private settings: SettingsService
  ) {
   this.loadSideMenu();
   }

   loadSideMenu(){
    this.settings.cargarAjustes();
    this.menu = [
      {
        titulo: 'Principal',
        icon: 'mdi mdi-view-dashboard',
        submenu: [
          { titulo: 'Usuarios', url: '/usuarios' },
        ]
      },
      {
        titulo: 'SAAM',
        icon: 'mdi mdi-book-open',
        submenu: [
          { titulo: 'Claves de agente', url: '/claves-agente' },
          { titulo: 'Perfil de usuario restringido', url: '/usuario-restringido' }
        ]
      },
      {
        titulo: 'Multicotizador',
        icon: 'mdi mdi-account-card-details',
        submenu: [
          { titulo: 'Mis cotizadores', url: '/mc' },
        ]
      }
    ];

    if(this.settings.amISuperInOrg() || this.settings.amISuperOutOrg() || this.settings.amIStaff) {
      if (this.settings.amISuperInOrg() || this.settings.amISuperOutOrg()){
        this.menu[0].submenu.push({ titulo:'Organizaciones', url: '/organizaciones' });
        this.menu[0].submenu.push({ titulo:'Reporte de Usuarios', url: '/usersreport' });
        this.menu[0].submenu.push({ titulo:'Control de Usuarios', url: '/controlusers' });
        this.menu[0].submenu.push({ titulo: 'Carrusel SAAM', url: '/carousel' });
      }
      this.menu[0].submenu.push({ titulo:'Perfiles', url: '/perfiles' });
      this.menu[0].submenu.push({ titulo: 'Dashboard', url: '/dashboard' });

      if(this.settings.amISuperInOrg() || this.settings.amISuperOutOrg() ){
        this.menu[2].submenu.push({ titulo: 'Homologar', url: '/homologacion-mc' });        
        this.menu.push({
          titulo: 'Administración',
          icon: 'mdi mdi-folder',
          submenu:  [
            { titulo: 'Clientes', url: '/clientes' },
            { titulo: 'Planes', url: '/planes' },
            { titulo: 'Suscripciones', url: '/suscripciones/todas' }
          ]
        });

        this.menu[1].submenu.push({ titulo: 'Notificaciones', url: '/notificaciones' });

        this.menu.push({
          titulo: 'Servicios',
          icon: 'mdi mdi-assistant',
          submenu: [
            { titulo: 'Enviar masivos', url: '/servicios-masivos' },
            { titulo: 'SMTP', url: '/smtp' }
          ]
        });
      
      } else {
        this.menu.push({
          titulo: 'Administración',
          icon: 'mdi mdi-folder',
          submenu:  [
            { titulo: 'Suscripciones', url: '/suscripciones/todas' }
          ]
        });
      }

    if((this.settings.amIRoleSubAdmin() || this.settings.amIRoleAdmin() || this.settings.amIUser()) && !(this.settings.amISuperInOrg() || this.settings.amISuperOutOrg()) && !(this.settings.amIStaff)) {

      this.menu.push({
        titulo: 'Servicios',
        icon: 'mdi mdi-assistant',
        submenu: [
          { titulo: 'SMTP', url: '/smtp' }
        ]
      });

    }

    if(this.settings.CanICrearUsuariosApp()){

      if (this.menu.length == 5 && this.menu[4].titulo == 'Servicios'){
        this.menu[4].submenu.push({ titulo: 'Usuarios App', url: '/usuarios-app' });
      } else {
        this.menu.push({
          titulo: 'Servicios',
          icon: 'mdi mdi-assistant',
          submenu: [
            { titulo: 'Usuarios App',url: '/usuarios-app' }
          ]
        });
      }

    }
   }
  }
}
