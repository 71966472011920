import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment  } from '../../../environments/environment'
import { SettingsService } from '../settings/settings.service';



@Injectable()
export class RestApiPaymentProvider {

  private apiUrl = environment.api_ip;

  constructor(private http: HttpClient, private settings: SettingsService) {
    this.settings.cargarAjustes();
  }

  get_planes() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/planes', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  get_clientes() {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/clientes', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  create_init_plan(payload: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'pagos/plan', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  create_init_cliente(payload: any) {
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'pagos/cliente', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  update_plan(id:number, payload: any) {
    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + 'pagos/plan/'+ id, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  update_cliente(id:number, payload: any) {
    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + 'pagos/cliente/'+ id, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  update_suscripcion(id:number, payload: any) {
    return new Promise((resolve, reject) => {
      this.http.patch(this.apiUrl + 'pagos/suscripciones/'+ id, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  checkSuscription(org:number) {
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/check-suscription-cliente/'+ org, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  createSuscription(payload:any){
    return new Promise((resolve, reject) => {
      this.http.post(this.apiUrl + 'pagos/suscripcion', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  getSuscriptions(){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/suscripciones', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  getSuscriptionDetail(subs_id:number){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/suscripciones/' + subs_id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  getSuscriptionsByClient(org:number){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/suscripciones-cliente/' + org, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  getSuscriptionEvents(cliente_id:number){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/suscripciones-eventos/' + cliente_id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  getOrdersClient(cliente_id:number){
    return new Promise((resolve, reject) => {
      this.http.get(this.apiUrl + 'pagos/ordenes/' + cliente_id, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + this.settings.ajustes.token
        }})
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  

}
