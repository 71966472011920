import { Injectable } from '@angular/core';
import { RestApiProvider } from './api.service';
import { FormControl, FormGroup } from '@angular/forms';


@Injectable({
    providedIn: 'root'
  })
  export class ValidadoresService{
    user:any;
    profile:any;
    constructor(private rest_api: RestApiProvider) {}

    existePerfilRestringido(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{
      
        this.rest_api.existe_perfil_restringido(control.value).then(response=>{
          if(response){
            resolve({existe:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({existe:true})
        })
  
  
      })
    }
    
  
    existeUrlname(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{
      
        this.rest_api.existe_organizacion(control.value.toLowerCase()).then(response=>{
          if(response){
            resolve({existe:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({existe:true})
        })
  
  
      })
    }

    existeSchemaName(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{
        this.rest_api.existe_schema_name(control.value.toLowerCase()).then(response=>{
          if(response == 'true' || response == true){
            resolve({existe:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({existe:true})
        })
  
  
      })
    }


    existeEmail(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{
        let user = this.user && this.user['id'] ? this.user.id : this.user;
        if(user == undefined){
          user = null;
        }
        this.rest_api.existe_email(control.value, user).then(response=>{
          if(response){
            resolve({existe:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({existe:true})
        })
  
  
      })
    }

    existeUsername(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{        
        (control.value).toLowerCase()
        this.rest_api.existe_username(control.value).then(response=>{
          if(response){
            resolve({existe:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({existe:true})
        })
  
  
      })
    }


    existeProfilename(control: FormControl):Promise<ErrorValidate> | null{
      if (!control.value){return Promise.resolve(null)};
  
      return new Promise((resolve, reject)=>{
        this.rest_api.exist_profile_name(control.value, this.profile).then(response=>{
          if(response){
            resolve({exists:true})
          }
          else{
            resolve(null)
          }
        })
        .catch(err=>{
          console.log(err);
          resolve({exists:true})
        })
  
  
      })
    }
  
    noDiezDigitosPhone(control: FormControl): ErrorValidate{
      if (control.value == null){return null;}
      if (control.value.toString().length != 10){
        return {
          noselected: true
        }
      }
      return null;
  
    }


    noSelectedOption(control: FormControl): ErrorValidate{
      if (control.value != null){return null;}
      if (control.value == null){
        return {
          noselected: true
        }
      }
      return null;
  
    }


    noSelectedOptionIncluded0(control: FormControl): ErrorValidate{
      if (control.value != -1){return null;}
      if (control.value == -1){
        return {
          noselected: true
        }
      }
      return null;
  
    }

    noWhitespaceValidator(control: FormControl):ErrorValidate {
        if (!control.value){return null;}
        const isValid = control.value.indexOf(' ')
        return isValid == -1 ? null : { 'whitespace': true };
    }

    passwordsIguales(pass1:string, pass2:string){
      return (formGroup:FormGroup) => {        
        const pass1Control = formGroup.controls[pass1];
        const pass2Control = formGroup.controls[pass2];
        if(pass1Control.value === pass2Control.value){
          pass2Control.setErrors(null);
        }
        else{
          pass2Control.setErrors({noEsIgual:true});
        }
      }
    }


  }
  
  interface ErrorValidate{
    [s:string]: boolean
  }
  
