import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiProvider } from 'src/app/services/shared/api.service';

@Component({
  selector: 'app-usuario-restringido',
  templateUrl: './usuario-restringido.component.html',
  styleUrls: ['./usuario-restringido.component.css']
})
export class UsuarioRestringidoComponent implements OnInit {
  perfiles:any = [];

  constructor(
    private _ajustes: SettingsService,
    private _api_service : RestApiProvider,
    private _router: Router
  ) { }

  ngOnInit() {
    this._api_service.get_perfiles_usuario_restringido().then(response => {
      this.perfiles = response;
      console.log('perfiles', this.perfiles);
    });
  }

  detallePerfil(perfil:any){
    if (perfil){
      this._router.navigate(['/usuario-restringido-detalle/',perfil.id]);
    }
    else{
      this._router.navigate(['/usuario-restringido-detalle','nuevo']);
    }
    
  }

  generar_reporte(){
    this._api_service.reporte_perfiles_restringido().then((response:any) =>{
      
      let filename = 'Reporte_Perfiles.xlsx';
      let dataType = response.type;
      let binaryData = [];
      binaryData.push(response);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
      if (filename)
          downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    })
  }

}
