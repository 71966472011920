import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ExcelService } from 'src/app/services/shared/excelService';
import {MatTableDataSource} from '@angular/material/table';
import * as XLSX from 'xlsx';
import { MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { filter, map } from 'rxjs/operators';
import { isNumber, isUndefined } from 'util';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


type AOA = any[][];


@Component({
  selector: 'app-servicios-masivos',
  templateUrl: './servicios-masivos.component.html',
  styleUrls: ['./servicios-masivos.component.css']
})
export class ServiciosMasivosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  form:FormGroup;
  data:any = [];
  aux_array:any = [];
  templates:any =[];
  files:any;
  generalFiles: any = [];
  displayedColumns: string[] = this.aux_array;
  withFiles = true;
  selected_template:number;
  dataSource = new MatTableDataSource<AseguradoElement>(this.data);
  


  constructor(
    private excelService: ExcelService,
    private _rest_api : RestApiProvider,
    public dialog: MatDialog,
    private http:HttpClient,
    private toaster: ToastrService,
    private _router: Router,
    private ajustes: SettingsService
  ) { 
    this.form = new FormGroup({
      htmlContent: new FormControl()
    });
    this.aux_array = [];
  }

  ngOnInit() {
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this._rest_api.obtener_templates().then(response=>{
      this.templates = response;
    })
  }

  resetGeneralFiles(){
    this.generalFiles = [];
  }

  cancel(){
    window.location.reload();
  }

  save() {
    if (!this.selected_template){
      this.toaster.error('Seleccione un cuerpo para el correo');
      return
    }
    Swal.fire({
      title: '¿Comenzar?',
      text: '¿Está seguro de comenzar con el envío?',
      showCancelButton: true,
      confirmButtonText: '¡Run Forest!',
      cancelButtonText: 'No',
      showLoaderOnConfirm: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.value) {
        this.data.map(asegurado => {
          asegurado['status'] = 'sending';
          return asegurado;
        });
        let emails = this.data.map(item => item.correo);
        console.log('emails1', emails);
        
        emails = emails.filter(function(elem, index, self) {
            return index === self.indexOf(elem);
        })
        console.log('emails2 ', emails);
        let requests = [];
        const apiUrl = environment.api_ip;
        emails.forEach(email => {
          let main_obj = [];
          this.data.forEach(item => {
            if (item.correo === email){
              main_obj.push(item);
            }
          });
          const payload = {
            db:  main_obj,
            generalFiles: this.generalFiles,
            template: this.selected_template
          };
          
          requests.push(this.http.post(apiUrl + 'send-massives/', payload, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Token ' + this.ajustes.ajustes.token
          }}));



          // this._rest_api.send_massives(payload).then(response => {
          //   this.data.map(item_response =>{
          //     if (email == item_response.correo){
          //       item_response['status'] = 'sent';
          //     }
          //     return item_response;
          //   })
          //   this.toaster.success('Correos enviados correctamente')
          // }, error => {
          //   this.data.map(item_response =>{
          //     if (email == item_response.correo){
          //       item_response['status'] = 'notsent';
          //     }
          //     return item_response;
          //   });
          // })
          // .catch(err => {
          //   this.data.map(item_response =>{
          //     if (email == item_response.correo){
          //       item_response['status'] = 'notsent';
          //     }
          //     return item_response;
          //   });
          // });
        });
        concat(...requests).subscribe(result => {
          this.data.map(item_response =>{
            if (result['email'] == item_response.correo){
              if (result['status'] == 'sent'){
                item_response['status'] = 'sent';
              } else {
                item_response['status'] = 'notsent';
              }
            }
            return item_response;
          });          
        }, error => {
          console.log('error', error);
          this.toaster.error('Ha ocurrido un error con el envío, revise el correo y vuelva a intentarlo.')
          // log error
        },()=>{
          this.data.map(item_response =>{
            if (item_response['status'] == 'pending'){
              item_response['status'] = 'notsent';
            }
            return item_response;
          });   
        });
        
      }
    });
  }

  onFileToSendChangeIndividual(evt: any){
    
    Swal.fire({
      title: 'Introduce el campo del nombre del archivo',
      text: 'Es el nombre de la columna en la que se encuentra con el(los) nombre(s) del(de los) archivo(s) seleccionado(s)',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Listo!',
      showLoaderOnConfirm: true,
    }).then((result) => {
      if (result.value) {
        let input_name = result.value;        
        this.files = evt.target.files;
        this.data.map((item, index) => {
          Array.from(this.files).some(file => {
            let filename = file['name'].replace('.pdf', '');
            if (filename === item[input_name.toString()].split(' ').join('')) {
              let reader = new FileReader();
              reader.readAsDataURL(file as File);
              reader.onload = function () {
                item['file'] = reader.result;
                item['filename'] = file['name'];
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            }
          });
          console.log(item);
          if (index === (this.data.length - 1)) {
            this.toaster.success('Archivos cargados');
          }
          return item;
        });
      }
    });
  }

  onFileToSendChangeGeneral(evt: any) {
    const generalFiles = [];
    const files = evt.target.files;
    Array.from(files).some(file => {
      let reader = new FileReader();
      reader.readAsDataURL(file as File);
      reader.onload = function() {
        generalFiles.push({
          'file': reader.result,
          'filename': file['name']
        });
      };
    });
    this.generalFiles = generalFiles;
    this.toaster.success('Archivos cargados');
  }

  viewData(asegurado:any){
    console.log(asegurado);    
  }

  onFileChange(evt: any){
    Swal.fire({
      title: 'Atención',
      text: "Para hacer los envíos el excel debe contener una columna llamada 'correo' en minúsculas y sin espacios",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Entendido',
      cancelButtonText: 'Cancelar',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.aux_array = [];
        /* wire up file reader */
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) throw new Error('Cannot use multiple files');
        const reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          /* read workbook */
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

          /* grab first sheet */
          const wsname: string = wb.SheetNames[0];
          const ws: XLSX.WorkSheet = wb.Sheets[wsname];

          /* save data */
          this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
          let headers = this.data[0];
          headers = headers.map(h => h.toLowerCase().split(' ').join('_').split('-').join('_').split('/').join('_'))

          this.data.splice(0,1);
          let  filtered_items =[];
          this.data.forEach(item => {
            if (filtered_items.indexOf(item[headers.indexOf('correo')]) == -1 ){
              filtered_items.push(item[headers.indexOf('correo')]);
            }
          })
          
          
          const dialogRef = this.dialog.open(SelectToMassiveDialogComponent, {
            disableClose: true,
            data: {
              data : headers 
            }
          });

          dialogRef.afterClosed().subscribe(result => {                      
            if (result){
              result = result['headers'];
              // console.log(result);


              let items = result.filter(item => item['checked'] == true)
              let indices = []
              items.forEach((item) =>{
                indices.push(headers.indexOf(item['name'])); 
              });
              this.data = this.data.map(asegurado => {
              let obj = {};
              obj['status'] = 'pending';
              for(let indice of indices){
                if (!this.aux_array.includes(headers[indice])){
                  this.aux_array.push(headers[indice]);
                }
                if(typeof asegurado[indice] == 'number'){
                  if(asegurado[indice] % 1 == 0){
                    asegurado[indice] = asegurado[indice];
                  }
                  else{
                    asegurado[indice] = asegurado[indice].toFixed(2);
                  }
                }
                obj[headers[indice]] = asegurado[indice];
                }
              return obj;
              });
              
             } else {
              window.location.reload();       
             }
          });
          
        };
        reader.readAsBinaryString(target.files[0]);
      } else {
        window.location.reload();
      }
    });
  }

  createBodyEmail(){
    if (this.data && this.data.length > 0) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Si procedes a crear un nuevo cuerpo de correo tendrás que volver a cargar el layout y los archivos nuevamente.',
        showCancelButton: true,
        confirmButtonText: '¡Adelante!',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this._router.navigate(['/templates', 'nuevo']);
        }
      });
    } else {
      this._router.navigate(['/templates', 'nuevo']);
    }
  }

  showBodyEmail(){
    if (this.data && this.data.length > 0) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Si previsualizas el cuerpo de correo tendrás que volver a cargar el layout y los archivos nuevamente.',
        showCancelButton: true,
        confirmButtonText: '¡Adelante!',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this._router.navigate(['/templates', this.selected_template ]);       
        }
      });
    } else {
      this._router.navigate(['/templates', this.selected_template ]); 
    }
  }

}

export interface AseguradoElement {
  name: string;
  descr: string;
  model: number;
  previous: number;
  actual: number;
  increment: number;
}

export interface checkStructure {
  name?:string;
  checked:Boolean;
}

export interface DialogData {
  data:any;
}


import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { environment } from 'src/environments/environment';
import { SettingsService } from 'src/app/services/service.index';
import { concat } from 'rxjs';


@Component({
  selector: 'add-new-profile',
  templateUrl: 'select-data-to-massive.dialog.component.html',
})
export class SelectToMassiveDialogComponent {
  form:FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<SelectToMassiveDialogComponent>,
    ) {
      this.form = new FormGroup({});
      let auxArray = new FormArray([]);
      this.data.data.forEach(header => {
        let flag = true;
        let control = new FormGroup({
          name: new FormControl(header),
          checked: new FormControl(flag)
        });
        auxArray.push(control);
      });
      this.form.addControl('headers',auxArray);
    }


    cancel(){
      this.dialogRef.close();
    }

    save(){
      this.dialogRef.close(this.form.value);
    }

    
  }
