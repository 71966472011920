import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { DataService } from 'src/app/services/shared/dataShare.service';
import Swal from 'sweetalert2';
import { AddCardDialogComponent } from '../../clientes/clientes.component';
declare let Conekta: any; //declare coneckta


@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {
  months = [  
    {
      "name": "01",
      "value": "01"
    },{
      "name": "02",
      "value": "02"
    },{
      "name": "03",
      "value": "03"
    },{
      "name": "04",
      "value": "04"
    },{
      "name": "05",
      "value": "05"
    },{
      "name": "06",
      "value": "06"
    },{
      "name": "07",
      "value": "07"
    },{
      "name": "08",
      "value": "08"
    },{
      "name": "09",
      "value": "09"
    },{
      "name": "10",
      "value": "10"
    },{
      "name": "11",
      "value": "11"
    },{
      "name": "12",
      "value": "12"
    }
  ];
  years = [{
      "name": "2022",
      "value": "22"
    },{
      "name": "2023",
      "value": "23"
    },{
      "name": "2024",
      "value": "24"
    },{
      "name": "2025",
      "value": "25"
    },{
      "name": "2026",
      "value": "26"
    },{
      "name": "2027",
      "value": "27"
    },{
      "name": "2028",
      "value": "28"
    },{
      "name": "2029",
      "value": "29"
    },{
      "name": "2030",
      "value": "30"
    }
  ]
  form: FormGroup;
  planesList:Array<any> = [];
  planSeleccionado:Plan;
  price_currency;
  no_users = 1;
  no_month = 12;
  suscripcion;
  showPlan;
  showCard;
  showConfirm;
  min_users = 1;

  constructor(
    private rest_api: RestApiPaymentProvider,
    public dialog: MatDialog,
    private ajustes : SettingsService,
    private toaster: ToastrService,
    private router: Router,
    private _dataShare : DataService,
    private spinner: NgxSpinnerService
  ) { 
    this.ajustes.cargarAjustes().then(()=>{
      if ( this.ajustes.ajustes.org && this.ajustes.ajustes.org['suscripcion'] ){
        this.suscripcion = this.ajustes.ajustes.org['suscripcion'];
        if (this.suscripcion['status'] == 'active'){
          this.no_users = this.suscripcion['plan']['users_number'];
          this.min_users = this.no_users;
          if (this.suscripcion['plan']['interval'] == 'month'){
            this.calcularPrice(12, this.suscripcion['plan']['users_number']);
          } else {
            this.calcularPrice(1, this.suscripcion['plan']['users_number']);
          }
        }
      } else {
        this.calcularPrice(this.no_month, this.no_users);
      }
    })
    // Conekta.setPublicKey("key_C86YJU76JB9SBYaxsae3JiA"); // Pruebas
    Conekta.setPublicKey("key_eWnXionD24EGMryFFCUr3rw"); // Produccion
    Conekta.setLanguage("es"); 
    this.form = new FormGroup({
      number: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    // this.rest_api.get_planes().then(response => {
    //   this.planesList = <any[]>response;
    //   this.planesList = this.planesList.filter(plan=>plan.active);
    //   let traducciones = {
    //     'month': 'Mensual',
    //     'year': 'Anual',
    //     'week': 'Semanal',
    //     'half_month': 'Quincenal'
    //   }
    //   this.planesList = this.planesList.map(plan => {
    //     plan['interval'] = traducciones[plan['interval']];
    //     if (plan['apps'] && plan['apps'].length > 0){
    //       plan['apps'].forEach((app,index) => {
    //         plan['apps'][index] = '../assets/images/'+ app.name +'.jpeg';
    //       });
    //     }
    //     return plan;
    //   });
    // });


    this.showPlan = true;
    this.showCard = false;
    this.showConfirm = false;

    
  }

  updatePlan(){
  }

  checkout(){
    console.log(this.planSeleccionado);

    if (this.form.invalid){
      this.toaster.error('Llene todos los campos');
      return
    }
    
    if (!Conekta.card.validateExpirationDate(this.form.get('month').value, this.form.get('year').value)){
      this.toaster.error('La fecha de expiración de la tarjeta es inválida')
      return;
    }

    if (!Conekta.card.validateNumber(this.form.get('number').value)){
      this.toaster.error('El número de tarjeta es inválido')
      return;
    }
    if (!Conekta.card.validateExpirationDate(this.form.get('month').value, this.form.get('year').value)){
      this.toaster.error('La fecha de vigencia de tarjeta es inválida')
      return;
    }
    if (!Conekta.card.validateCVC(this.form.get('cvv').value)){
      this.toaster.error('El código cvc de tarjeta es inválido')
      return;
    }
    var tokenParams = {
      "card": {
          "number": this.form.get('number').value,
          "name": this.form.get('name').value,
          "exp_year": this.form.get('year').value,
          "exp_month": this.form.get('month').value,
          "cvc": this.form.get('cvv').value,
      }
    };
    Conekta.Token.create(tokenParams, response => {
      let cliente = {
        'name': this.ajustes.ajustes.user.first_name,
        'phone': this.ajustes.ajustes.org.phone,
        'email': this.ajustes.ajustes.user.email,
        'org': this.ajustes.ajustes.org.urlname,
        'payment_sources': [{
          'type': 'card',
          'token_id': response['id']
        }]
      }
      this.spinner.show();
      this.rest_api.create_init_cliente(cliente).then(response => {
        console.log('response create cliente',response);
        
        let suscripcion_payload = {
          'card': response['payment_sources'] && response['payment_sources']['data'] ? response['payment_sources']['data'][0]['id']: 0,
          'cliente': response['id'],
          'plan': this.planSeleccionado.id,
          'plan_id': this.planSeleccionado.clave,
          'init_date': new Date(),
        }
        this.rest_api.createSuscription(suscripcion_payload).then(() => {
          this.spinner.hide()
          this._dataShare.changeMessage({'message':'loged_in'});

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: '¡Suscripción exitosa!, es necesario volver a iniciar sesión para que los cambios surtan efecto.',
            showConfirmButton: false,
            timer: 2000
          })
          
          setTimeout(() => {
            this.toaster.success('Cerrando sesión...')
            this.ajustes.borrarAjustes();
            this.router.navigate(['/login']);  
          }, 4000);
          
        }, error => this.spinner.hide()).catch(err => this.spinner.hide());

        
      }, error => {
        console.log(error);
        this.spinner.hide()
      }).catch(err => {
        console.log(err);
        this.spinner.hide()
      });

      this.showPlan = false;
      this.showCard = false;
      this.showConfirm = true;
    }, error => {
      console.log(error);
      try{
        this.toaster.error(error['message_to_purchaser']);  
      } catch(err){
        this.toaster.error('Ah ocurrido un error');
      }      
    }). catch(error=>{
      console.log(error);
      try{
        this.toaster.error(error['message_to_purchaser']);  
      } catch(err){
        this.toaster.error('Ah ocurrido un error');
      }    
    });
  }

  selectionPlan(plan){
    this.planSeleccionado = plan;
  }

  calcularPrice(param, user){

    if(param != 0){
      this.no_month = param;
      if(param == 12){
        this.price_currency = 690 * user;
      }else{
        this.price_currency = 6960 * user;
      }
    }else{
      if( this.no_month == 12){
        this.price_currency = 690 * user;
      }else{
        this.price_currency = 6960 * user;
      }
    }
  }
  save(){
    console.log('saeeeeeeeee')
  }
  toTimestamp(){
    var datum = new Date();
    return datum.getTime()/1000;
   }

  nextPay(){
    let month = 'month';
    if (this.no_month == 1){
      month = 'year';
    }
    if (this.suscripcion && this.suscripcion['status'] == 'active') {
      
      this.suscripcion['plan']['users_number'] =  this.no_users;
      if (this.min_users == this.no_users){
        this.toaster.warning('El número de usuarios tiene que ser mayor al del plan actual');
        return;
      }
      this.suscripcion['plan']['amount'] = this.price_currency;
      this.suscripcion['plan']['interval'] = month;
      this.rest_api.update_plan(this.suscripcion['plan']['id'], this.suscripcion['plan']).then(response => {
        this.toaster.success('Plan actualizado correctamente');
        this.suscripcion['plan'] = response;
        this.ajustes.ajustes.org['suscripcion'] = this.suscripcion;
        this.ajustes.guardarAjustes();
        this.router.navigate(['/usuarios']);
      }, error => {
        console.log(error);
        this.toaster.error('Ocurrió un error al actualizar el plan, intente nuevamente');
        
      }).catch(error => {
        console.log(error);
        this.toaster.error('Ocurrió un error al actualizar el plan, intente nuevamente');

      })
    }else {  
      let clave = this.toTimestamp().toString().split('.')[0];
        let plan:Plan = {
          clave: clave,
          name: 'Plan dinámico ' + (clave).toString(),
          amount: this.price_currency ,
          currency: "MXN" ,
          interval: month ,
          frequency: 1 ,
          users_number: this.no_users,
          trial_period_days: 7,
          expiry_count: 36 ,
          descripcion:'breve descripción...',
          apps: [],
          init_date: new Date(),
          end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 3))
        }
        
        this.rest_api.create_init_plan(plan).then(response => {
          this.toaster.success('Plan creado correctamente');
          this.planSeleccionado = <Plan>response;
          this.showPlan = false;
          this.showCard = true;
          this.showConfirm = false;
        }, error => {
          console.log(error);
          this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
        })
        .catch(error => {
          console.log(error);
          this.toaster.error('Ha ocurrido un error, intente nuevamente','Error');
        });
      }


  }
}


export interface Plan  {
  clave:string;
  name:string;
  amount:number;
  currency:string;
  interval:string;
  frequency:number;
  trial_period_days:number;
  expiry_count:number;
  descripcion?:string;
  url?:string;
  id?:number;
  init_date:Date;
  end_date:Date;
  apps?:any;
  users_number:number;
}