import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from 'src/app/services/service.index';
import { RestApiPaymentProvider } from 'src/app/services/shared/api.payment.service';
import { DataService } from 'src/app/services/shared/dataShare.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-suscripciones',
  templateUrl: './suscripciones.component.html',
  styleUrls: ['./suscripciones.component.css']
})
export class SuscripcionesComponent implements OnInit {
  public suscripcionList:any = [];
  constructor(
    private rest_api: RestApiPaymentProvider,
    private _activateRoute: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    public dialog: MatDialog,
    private ajustes: SettingsService,
    private data_share : DataService
  ) { 
    this.ajustes.cargarAjustes();
    
  }

  gotoSubsDetail(subs_id){
    this.router.navigate(['/suscripciones-detail/', subs_id]);
  }

  ngOnInit() {
    this._activateRoute.params.subscribe(params => {
      let org = Object.assign({}, params);
      console.log();
      
      if (this.ajustes.amIStaff()){ org['org'] = this.ajustes.ajustes.org.urlname; }
      
      if (org && org['org'] != 'todas')  {
        this.rest_api.getSuscriptionsByClient(org['org']).then(response => {
          this.suscripcionList = response;
          console.log(this.suscripcionList);
                   
          // this.suscripcionList = this.suscripcionList.filter(sus => sus.status != 'cancel');
          if (this.suscripcionList.length == 0){
            this.toaster.warning('No se encontraron suscripciones');  
          } else{
            this.toaster.success('Suscripciones cargadas correctamente');
          }
        }, () => {
          this.toaster.error('Ha ocurrido un error intente nuevamente');
        })
        .catch(() => {
          this.toaster.error('Ha ocurrido un error intente nuevamente');
        });
      }
      else {
        this.rest_api.getSuscriptions().then(response => {
          if (response instanceof Array && (response as Array<any>).length > 0){
            this.suscripcionList = response;
          } else {
            this.suscripcionList = this.suscripcionList.push(response);
          }
          console.log(this.suscripcionList);
          // this.suscripcionList = this.suscripcionList.filter(sus => sus.status != 'cancel');
          this.toaster.success('Suscripciones cargadas correctamente');
        });
      }
    });
  }

  updateList(id: number, property: string, event: any) {
    let editField;
    try {
      editField = event.target.textContent;    
    }
    catch(err){
      // console.error(err);
      editField = event;    
    }
    let payload = {};
    if (editField == 'cancel'){
      Swal.fire({
        title: '¿Estás seguro que deseas cancelar esta suscripción?',
        text: "Esta operación es irreversible y tendrá efecto inmediato",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '¡Si, cancelar!',
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          if (property == 'amount') {
            editField = parseFloat(editField.toString().replace('$',''));
          }
          payload[property] = editField;
          this.rest_api.update_suscripcion(this.suscripcionList[id].id, payload).then(response => {
            this.suscripcionList[id] = response;
            this.data_share.changeMessage({});
            this.toaster.success('Campo actualizado correctamente');
            this.ajustes.borrarAjustes();
            this.router.navigate(['/login/']);
          }, error => {
            console.log(error);
            this.toaster.error('Ocurrió un error al actualizar el campo');
          })
          .catch(error => {
            console.log(error);
            this.toaster.error('Ocurrió un error al actualizar el campo');
          });
        }
        else {
          window.location.reload();
        }
      });
    }
    else{
      if (property == 'amount') {
        editField = parseFloat(editField.toString().replace('$',''));
      }
      payload[property] = editField;
      this.rest_api.update_suscripcion(this.suscripcionList[id].id, payload).then(response => {
        this.suscripcionList[id] = response;
        this.toaster.success('Campo actualizado correctamente');
      }, error => {
        console.log(error);
        this.toaster.error('Ocurrió un error al actualizar el campo');
      })
      .catch(error => {
        console.log(error);
        this.toaster.error('Ocurrió un error al actualizar el campo');
      });
    }
    
  }


}
