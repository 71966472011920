import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../services/service.index';

declare function init_plugins();

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  tutorial:boolean;
  stepOne:boolean;
  stepTwo:boolean;
  stepThree:boolean;
  stepFour:boolean;
  stepFive:boolean;

  constructor(
    private router: Router, 
    private _settings: SettingsService
  ) { }

  ngOnInit() {
    init_plugins();
    if(this._settings['ajustes']['user']['tutorial']){
      this.tutorial = true;
      this.stepOne = true;
      this.stepTwo = false;
      this.stepThree = false;
      this.stepFour = false;
      this.stepFive = false;
    }else{
      this.tutorial = false;
      this.stepOne = false;
      this.stepTwo = false;
      this.stepThree = false;
      this.stepFour = false;
      this.stepFive = false;
    }
  }

  closePopup(){
    this.tutorial = false;
  }
  
  step1(){
    this.router.navigate(['/dashboard']);
    this.stepOne = true;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepFour = false;
    this.stepFive = false;
  }
  
  step2(){
    this.router.navigate(['/usuarios']);
    this.stepOne = false;
    this.stepTwo = true;
    this.stepThree = false;
    this.stepFour = false;
    this.stepFive = false;
  }

  step3(){
    this.router.navigate(['/dashboard']);
    this.stepOne = false;
    this.stepTwo = false;
    this.stepThree = true;
    this.stepFour = false;
    this.stepFive = false;
  }
  
  step4(){
    this.router.navigate(['/perfiles']);
    this.stepOne = false;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepFour = true;
    this.stepFive = false;
  }
  
  step5(){
    this.router.navigate(['/dashboard']);
    this.stepOne = false;
    this.stepTwo = false;
    this.stepThree = false;
    this.stepFour = false;
    this.stepFive = true;
  }

}
