import { NONE_TYPE } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RestApiProvider } from 'src/app/services/shared/api.service';
import { ValidadoresService } from 'src/app/services/shared/validators';


@Component({
  selector: 'app-usuario-restringido-detalle',
  templateUrl: './usuario-restringido-detalle.component.html',
  styleUrls: ['./usuario-restringido-detalle.component.css']
})
export class UsuarioRestringidoDetalleComponent implements OnInit {
  form: FormGroup;
  loading = false;
  keyword = '';
  title = '';
  data: any = [];
  // tslint:disable-next-line: variable-name
  perfil_id: number = undefined;
  // tslint:disable-next-line: variable-name
  perfil_url: string = undefined;
  
  // tslint:disable-next-line: variable-name
  is_active = true;
  // tslint:disable-next-line: variable-name
  nombre_perfil = '';
  dropdownListContratante: any = [];
  dropdownListContratanteGrupos: any = [];
  dropdownListContratanteCelulas: any = [];
  dropdownListContratanteReferenciadores: any = [];
  dropdownListContratanteSucursales: any = [];

  selectedItemsContratanteContratante: any = [];
  selectedItemsContratanteGrupos: any = [];
  selectedItemsContratanteCelulas: any = [];
  selectedItemsContratanteReferenciadores: any = [];
  selectedItemsContratanteSucursales: any = [];

  dropdownListPoliza: any = [];
  dropdownListPolizaAgrupacion: any = [];
  dropdownListPolizaAgente: any = [];
  dropdownListPolizaAseguradora: any = [];
  dropdownListPolizaSubramo: any = [];
  dropdownListPolizaEstatus: any = [];

  selectedItemsPoliza: any = [];
  selectedItemsPolizaGrupos: any = [];
  selectedItemsPolizaCelulas: any = [];
  selectedItemsPolizaReferenciadores: any = [];
  selectedItemsPolizaSucursales: any = [];
  selectedItemsPolizaAgrupacion: any = [];
  selectedItemsPolizaAgente: any = [];
  selectedItemsPolizaAseguradora: any = [];
  selectedItemsPolizaSubramo: any = [];
  selectedItemsPolizaEstatus: any = [];

  solo_polizas_visibles: false;

  inputSearch = '';



  dropdownSettings = {};
  dropdownSettingsPoliza = {};

  constructor(
    // tslint:disable-next-line: variable-name
    private rest_api: RestApiProvider,
    // tslint:disable-next-line: variable-name
    private _toaster: ToastrService,
    // tslint:disable-next-line: variable-name
    private _validators: ValidadoresService ,
    // tslint:disable-next-line: variable-name
    private _route: ActivatedRoute,
    // tslint:disable-next-line: variable-name
    private _router: Router

  ) { 
    this.form = new FormGroup({
      nombre: new FormControl(
        '',
        [
            Validators.required
        ],
        this._validators.existePerfilRestringido.bind(this)
      )
    });

    this._route.params.subscribe( params => {
      if (params['id'] !== 'nuevo') {
        this.perfil_id = params['id'];
        this.rest_api.get_perfil_usuario_restringido(params['id']).then(response => {
          this.perfil_url = response['url'];
          this.form.get('nombre').setValue(response['nombre']);
          this.form.get('nombre').clearAsyncValidators();
          this.form.get('nombre').updateValueAndValidity();
          this.form.get('nombre').disable();

          this.is_active = response['is_active'];
          this.solo_polizas_visibles = response['solo_polizas_visibles'];

          interface MyObj {
            item_id: number;
            item_text: string;
          }

          this.loading = true;
          this.selectedItemsContratanteContratante = response['contratante_contratante'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          
          this.selectedItemsContratanteGrupos = response['contratante_grupo'].map((item: any) => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsContratanteCelulas = response['contratante_celula'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsContratanteReferenciadores = response['contratante_referenciador'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsContratanteSucursales = response['contratante_sucursal'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPoliza = response['poliza_poliza'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          if (this.selectedItemsPoliza.length > 0){
            this.dropdownListPoliza = this.selectedItemsPoliza;
          }
          this.selectedItemsPolizaGrupos = response['poliza_grupo'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaCelulas = response['poliza_celula'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaReferenciadores = response['poliza_referenciador'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaSucursales = response['poliza_sucursal'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaAgrupacion = response['poliza_agrupacion'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaAgente = response['poliza_clave_agente'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaAseguradora = response['poliza_aseguradora'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaSubramo = response['poliza_subramo'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.selectedItemsPolizaEstatus = response['poliza_estatus'].map(item => {
            if (item.includes('\'') && item.includes('"')){
              item = item.replaceAll('"', '').replaceAll('\'', '"');
              const aux: MyObj = JSON.parse(item);
              return aux;
            } else {
              const aux: MyObj = JSON.parse(item);
              return aux;
            }
          });
          this.loading = false;
        });
      }
    });
  }


  changeCheck(){
    console.log(this.solo_polizas_visibles);
    if (this.solo_polizas_visibles){
      this.selectedItemsContratanteContratante = [];
      this.selectedItemsContratanteGrupos = [];
      this.selectedItemsContratanteCelulas = [];
      this.selectedItemsContratanteReferenciadores = [];
      this.selectedItemsContratanteSucursales = [];
    }
  }

 
  onSearch(evt: any) {
    console.log(evt.target.value);
    console.log(evt);
  }

  changeInputSearch(val) {
    console.log(val);
    this.rest_api.polizas_listado_saam(this.inputSearch).then(response => {
      console.log(response);
      this.dropdownListPoliza = [...this.selectedItemsPoliza, ...(response as Array<any>)];
    }, err => {
      console.log(err);
      this._toaster.error('ha ocurrido un error');
    }).catch(err => {
      console.log(err);
      this._toaster.error('ha ocurrido un error');
    });
  }

  ngOnInit() {

    this.rest_api.get_grupos_saam().then(responseGrupos => {
      this.dropdownListContratanteGrupos = responseGrupos;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Grupos;');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Grupos;');
    });

    this.rest_api.get_celulas_saam().then(responseCelulas => {
      this.dropdownListContratanteCelulas = responseCelulas;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Celulas;');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Celulas;');
    });

    this.rest_api.get_sucursales_saam().then(responseSucursales => {
      this.dropdownListContratanteSucursales = responseSucursales;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Sucursales;');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Sucursales;');
    });

    this.rest_api.get_referenciadores_saam().then(responseReferenciadores => {
      this.dropdownListContratanteReferenciadores = responseReferenciadores;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Referenciadores');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Referenciadores');
    });


    this.rest_api.get_agrupaciones_saam().then(responseAgrupaciones => {
      this.dropdownListPolizaAgrupacion = responseAgrupaciones;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Agrupaciones');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Agrupaciones');
    });


    this.rest_api.get_agentes_saam().then(responseAgentes => {
      this.dropdownListPolizaAgente = responseAgentes;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Agentes');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Agentes');
    });

    this.rest_api.get_subramos_saam().then(responseSubramos => {
      this.dropdownListPolizaSubramo = responseSubramos;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Subramos');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Subramos');
    });

    this.rest_api.get_aseguradorasperfil().then(responseAseguradoras => {
      this.dropdownListPolizaAseguradora = responseAseguradoras;
      this.dropdownListPolizaAseguradora = this.dropdownListPolizaAseguradora.map(aseg => {
        const a = {
          item_id: aseg.id,
          item_text: aseg.compania
        };
        return a;
      });
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Aseguradoras');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Aseguradoras');
    });

    this.dropdownListPolizaEstatus = [
      { item_id: 1, item_text : 'En trámite'},
      { item_id: 2, item_text : 'OT Cancelada.'},
      { item_id: 10, item_text : 'Por iniciar'},
      { item_id: 14, item_text : 'Vigente'},
      { item_id: 4, item_text : 'Precancelada'},
      { item_id: 11, item_text : 'Cancelada'},
      { item_id: 13, item_text : 'Vencida'},
      { item_id: 12, item_text : 'Renovada'},
      { item_id: 15, item_text : 'No Renovada'},
      { item_id: 16, item_text : 'PreAnulada'},
      { item_id: 17, item_text : 'Anulada'},
      { item_id: 16, item_text : 'PreAnulada'}
    ];

  

    this.dropdownSettingsPoliza = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 5,
      allowSearchFilter: false,
      limitSelection: -1,
      noDataAvailablePlaceholderText: 'No se encontraron resultados'
    };

    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Deseleccionar todo',
      enableCheckAll: true,
      itemsShowLimit: 5,
      allowSearchFilter: true,
      limitSelection: -1,
      searchPlaceholderText: 'Buscar',
      noDataAvailablePlaceholderText: 'No se encontraron resultados'
    };

  }

  // obtener contratantes
  consultarContratantes(){
    this.rest_api.get_contratantes_saam().then(responseContratantes => {
      this.dropdownListContratante = responseContratantes;
    }, error => {
      this._toaster.error('Ha ocurrido un error al cargar Contratantes');
    }).catch(err => {
      this._toaster.error('Ha ocurrido un error al cargar Contratantes');
    });
  }

  guardarPerfil() {
    if (!this.perfil_id) {
      if (!this.form.valid) {
        this._toaster.error('Ingrese un nombre de perfil');
        return;
      }
    }
    const payload = {
      contratante_contratante: this.selectedItemsContratanteContratante,
      contratante_grupo: this.selectedItemsContratanteGrupos,
      contratante_celula: this.selectedItemsContratanteCelulas,
      contratante_referenciador: this.selectedItemsContratanteReferenciadores,
      contratante_sucursal: this.selectedItemsContratanteSucursales,
      poliza_poliza: this.selectedItemsPoliza,
      poliza_grupo: this.selectedItemsPolizaGrupos,
      poliza_celula: this.selectedItemsPolizaCelulas,
      poliza_referenciador: this.selectedItemsPolizaReferenciadores,
      poliza_sucursal: this.selectedItemsPolizaSucursales,
      poliza_agrupacion: this.selectedItemsPolizaAgrupacion,
      poliza_clave_agente: this.selectedItemsPolizaAgente,
      poliza_subramo: this.selectedItemsPolizaSubramo,
      poliza_aseguradora: this.selectedItemsPolizaAseguradora,
      poliza_estatus: this.selectedItemsPolizaEstatus,
      is_active: this.is_active,
      solo_polizas_visibles: this.solo_polizas_visibles,
      nombre: this.form.get('nombre').value,
    };
    console.log(this.perfil_id, this.perfil_url);
    
    if (!this.perfil_id) {
      this.rest_api.guardar_perfil_usuario_restringido(payload).then(response => {
        this._toaster.success('Perfil guardado correctamente');
        this._router.navigate(['/usuario-restringido']);
      }, err => {
        console.log(err);
        this._toaster.error('ha ocurrido un error');
      }).catch(err => {
        console.log(err);
        this._toaster.error('ha ocurrido un error');
      });
    } else {
      payload['url'] = this.perfil_url;
      this.rest_api.actualizar_perfil_usuario_restringido(payload).then(response => {
        this._toaster.success('Perfil guardado correcamente');
        this._router.navigate(['/usuario-restringido']);
      }, err => {
        console.log(err);
        this._toaster.error('ha ocurrido un error');
      }).catch(err => {
        console.log(err);
        this._toaster.error('ha ocurrido un error');
      });
    }


    
  }

  cancel() {
    this._router.navigate(['/usuario-restringido']);
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
