import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RestApiProvider } from '../services/shared/api.service';
import { ToastrService, Toast } from 'ngx-toastr';
import { ValidadoresService } from '../services/shared/validators';
import Swal from 'sweetalert2';
import { SettingsService } from '../services/service.index';
import { TouchSequence } from 'selenium-webdriver';
import { NgxSpinnerService } from 'ngx-spinner';

declare function init_plugins();


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit {
  form: FormGroup;
  formPasword: FormGroup;
  be_able = true;
  pk: number;
  activateUser: number = 0;
  userCode: string;
  public activationStatus = 'Pending';

  constructor(
    private _route: ActivatedRoute,
    private rest_api: RestApiProvider,
    private _toaster:ToastrService,
    private _validators: ValidadoresService,
    private _router:Router,
    private _settings: SettingsService,
    private spinner: NgxSpinnerService
  ) {
    init_plugins();
    this._settings.cargarAjustes();
    this.formPasword = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]),
    });
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required, _validators.noDiezDigitosPhone]),
      org: new FormControl(null, [
        Validators.required, 
        Validators.minLength(3),
        this._validators.noWhitespaceValidator,
        Validators.pattern('^[a-z]+$')
      ], this._validators.existeUrlname.bind(this)),
    },{
      validators: this._validators.passwordsIguales('password', 'confirmPassword').bind(this.form)
    })
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      console.log(params);
      // tslint:disable-next-line: no-string-literal
      if (params && params['id']) {
        this.activateUser = 1;
        this.pk = params.id;
        this.rest_api.getDemoRequest(params.id).then(response=>{
            if (response['email']){
              if (response['status'] !== 'PENDIENTE') {
                this.be_able = false;
                this.form.disable();
                this._toaster.error('Esta cuenta ya ha sido activada', 'Error' , {
                  timeOut: 7000
                });
                return;
              }
              this.form.reset(response);
              this.form.enable();
              this.form.get('email').disable();
              this.form.get('name').disable();
            } else {
              this._toaster.error('El enlace de activacion es inválido, solicite la prueba nuevamente'); 
              this.form.disable();
              this.be_able = false;
            }
          },
          () => {
            this._toaster.error('El enlace de activacion es inválido, solicite la prueba nuevamente');
            this.form.disable();
            this.be_able = false;
        })
        .catch(err=>{
          this._toaster.error('El enlace de activacion es inválido, solicite la prueba nuevamente');
          this.form.disable();
          this.be_able = false;
        });
      } else if (params && params['code']) {
        this.activateUser = 2;
        this.userCode = params['code']
        this.rest_api.activateUser(this.userCode).then((response: any) => {
          const resp = response;
          if (parseInt(resp['status_code']) === 200) {
            Swal.fire(
              'Activacion exitosa',
              'Ahora puede iniciar sesion desde su dispositivo movil',
              'success'
            );
            this.activationStatus = 'Success';
          } else if (parseInt(resp['status']) === 500) {
            this._toaster.error(
              'El enlace de activacion no es valido o el usuario ya ha sido activado',
              '',
              {
                timeOut: 5000
              }
            );
          } else {
            this._toaster.error('Ha ocurrido un error, vuelva a solicitar su correo de activacion.');
          }
        });
      } else if (params && params['frgtpsswd'] && parseInt(params['frgtpsswd']) === 0) {
        this.activateUser = 3;
      } else if (params && params['frgtpsswd'] && parseInt(params['frgtpsswd']) !== 0) {
        this.activateUser = 4;
        this.userCode = params['frgtpsswd'];
      }
    });
  }

  resetPassword(){
    if(this.form.get('password').invalid || this.form.get('confirmPassword').invalid ){
      this.form.get('password').markAllAsTouched();
      this.form.get('confirmPassword').markAllAsTouched();
      return;
    } else{
      console.log(this.form.value);
      this.rest_api.resetPassword(this.form.value['password'], this.userCode).then(response => {
        const resp = response;
        if (parseInt(resp['status']) === 200) {
          Swal.fire(
            'Re-establecimiento exitoso',
            'Ahora puede iniciar sesion con su nueva contraseña',
            'success'
          );
          this.form.disable();
          this.activationStatus = 'Success';
        } else if (parseInt(resp['status']) === 500) {
          console.log(response);
          this._toaster.error(
            'Ha ocurrido un error con su re-establecimiento, intentelo nuevamente',
            'Error',
            {
              timeOut: 5000
            }
          );
        } else {
          console.log(response);
          this._toaster.error('Ha ocurrido un error, vuelva a solicitar su cambio de contraseña.');
        }
      })
    }

  }

  forgotPassword() {
    if(this.formPasword.invalid){
      Object.values(this.form.controls).forEach(control => {
        control.markAllAsTouched();
      });
      return;
    } else{
      this.rest_api.forgotPassword(this.formPasword.value).then(response => {
        if (response && response['error']){
          this._toaster.error(response['error']); 
          return; 
        }
        this._toaster.success('Hemos enviado instrucciones al correo introducido.');
      })
    }

  }

  get emailNoValido() {
    return this.formPasword.get('email').invalid && this.formPasword.get('email').touched;
  }

  get passwordNoValido() {
    return this.form.get('password').invalid && this.form.get('password').touched;
  }

  get phoneNoValido(){
    return this.form.get('phone').invalid && this.form.get('phone').touched;
  }


  get orgNoValido(){
    return this.form.get('org').invalid && this.form.get('org').touched && !this.form.get('org').hasError('exists');
  }

  get password_confirmNoValido(){
    const pass1 = this.form.get('password').value;
    const pass2 = this.form.get('confirmPassword').value;
    return (pass1 === pass2) ? false : true;
  }

  save(){
    if(this.form.invalid){
      Object.values(this.form.controls).forEach(control=>{
        control.markAllAsTouched();
      })
      return;
    } else{
      this.create_org();
    }
  }

  login(){
    this._router.navigate(['/login']);
  }

  create_org(){
    this.spinner.show();
    console.log(this.form.value);
    this.form.value['pk'] = this.pk;
    this.rest_api.crear_organizacion_demo(this.form.value).then(response=>{
      this.spinner.hide();
      if(response['accepted']){
        this._settings.ajustes.token = response['Token'];
        this._settings.ajustes.user = response['user'];
        this._settings.ajustes.org = response['org']; 
        this._settings.ajustes.user.userinfo = response['userinfo'];
        this._settings.guardarAjustes();
        Swal.fire({
          title: '<strong>Ya casi terminamos :)</strong>',
          icon: 'success',
          html:
            // tslint:disable-next-line: max-line-length
            'Ahora procederemos con la configuración inicial para que puedas disfrutar de la experiencia SAAM.',
          showCloseButton: false,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        })
        .then((result) => {
          if (result.value) {
            this._router.navigate(['/usuarios']);
          }
        })
        // this._router.navigate(['/usuarios']);
      }
      
    }, error => {
      this.spinner.hide();
      console.log(error);
      this._toaster.error('Ha ocurrido un error, intente nuevamente');
    })
    .catch(error => {
      this.spinner.hide();
      console.log(error);
      this._toaster.error('Ha ocurrido un error, intente nuevamente');
    });
  }


}
